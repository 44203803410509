export class Base {
  public id: number;
  constructor(data?: any) {
    if (data) {
      Object.assign(this, data);
      for (const key of Object.keys(data)) {
        if (!(key in this)) {
          console.warn(
            `Property "${key}" is not defined in the class "${this.constructor.name}"`
          );
        }
      }
    }
  }
}
