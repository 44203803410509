import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { iif, Observable, of } from 'rxjs';
import { finalize, map, switchMap, tap } from 'rxjs/operators';

import { environment } from '../../../environments/environment';
import { Document, DocumentType } from '../../shared/models';
import { deserialize, serialize } from '../../shared/models/base-helper';
import { SpinnerService } from '../../shared/spinner/spinner.service';

@Injectable({
  providedIn: 'root',
})
export class DocumentService {
  apiUrl = environment.apiUrl + '/documents/';
  docTypesApiUrl = environment.apiUrl + '/document_types/';

  docTypes: Array<DocumentType> = [];

  constructor(
    readonly http: HttpClient,
    readonly spinnerService: SpinnerService
  ) {}

  getDocTypeId(name: string): number {
    const docType: DocumentType[] = this.docTypes.filter(
      dt => dt.name === name
    );
    return docType && docType[0] ? docType[0].id : null;
  }

  getAllDocumentTypes(): Observable<DocumentType[]> {
    return this.http.get<any[]>(this.docTypesApiUrl).pipe(
      map(docTypes =>
        docTypes.map((docType: any) => deserialize(docType, DocumentType))
      ),
      tap(docTypes => {
        this.docTypes = docTypes;
      })
    );
  }

  createDocument(document: Document) {
    return this.http
      .post<any>(`${this.apiUrl}`, serialize(document))
      .pipe(
        map((serializedDocument: any) =>
          deserialize(serializedDocument, Document)
        )
      );
  }

  uploadFile(siteId, file) {
    let fileKey = null;
    return this.http.post<any>(`${this.apiUrl}${siteId}`, null).pipe(
      switchMap(resp => {
        const formData: FormData = new FormData();
        for (const key in resp.fields) {
          formData.append(key, resp.fields[key]);
        }
        formData.append('file', file);
        fileKey = formData.get('key') as string;
        fileKey = fileKey.split('/')[1];

        const options = {
          headers: new HttpHeaders().set('X-FC-Noauth', 'true'),
        };

        return iif(
          () => resp.url === 'MOCK_DO_NOT_UPLOAD',
          of(fileKey),
          this.http.post(resp.url, formData, options).pipe(map(() => fileKey))
        );
      })
    );
  }

  downloadDocument(siteId, documentId, fileName, open) {
    return this.http
      .get<any>(
        `${this.apiUrl}${siteId}/${documentId}?file_name=${fileName}&open=${open}`
      )
      .toPromise();
  }

  downloadKMLData(siteId, documentId) {
    return this.http
      .get<any>(`${this.apiUrl}${siteId}/${documentId}/kml_data/`)
      .pipe(
        map((result: any) => {
          return result;
        })
      );
  }

  getDocuments(siteId: string): Observable<Document[]> {
    return this.http
      .get<any[]>(`${this.apiUrl}?site_identifier=${siteId}`)
      .pipe(
        map(documents =>
          documents.map((serializedDocument: any) =>
            deserialize(serializedDocument, Document)
          )
        )
      );
  }

  saveDocument(newDoc: any, fileKey: string): Observable<Document> {
    return this.http
      .post<any>(this.apiUrl, { ...newDoc, identifier: fileKey })
      .pipe(
        map(serializedDocument => deserialize(serializedDocument, Document))
      );
  }

  deleteDocument(
    siteIdentifier: string,
    documentIdentifier: string
  ): Observable<any> {
    return this.http.delete(
      `${this.apiUrl}${siteIdentifier}/${documentIdentifier}`
    );
  }

  getOrphans(): Observable<number> {
    this.spinnerService.show('app-spinner');
    return this.http.get<any>(`${this.apiUrl}orphans`).pipe(
      map((result: any) => {
        return result['orphan_count'];
      }),
      finalize(() => this.spinnerService.hide('app-spinner'))
    );
  }

  deleteOrphans(): Observable<number> {
    this.spinnerService.show('app-spinner');
    return this.http.delete<any>(`${this.apiUrl}orphans`).pipe(
      map((result: any) => {
        return result['files_deleted'];
      }),
      finalize(() => this.spinnerService.hide('app-spinner'))
    );
  }

  updateDocument(document: any) {
    return this.http
      .patch<any>(`${this.apiUrl}${document.identifier}`, document)
      .pipe(
        map(serializedDocument => deserialize(serializedDocument, Document))
      );
  }
}
