import { Component, DestroyRef, inject, Input, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import moment from 'moment';
import { skipWhile } from 'rxjs/operators';

import { AdjustmentService } from 'src/app/core/api/adjustment.service';

import {
  PaymentService,
  StripeLoginLink,
} from '../../../core/api/payment.service';
import { SiteService } from '../../../core/api/site.service';
import { UserService } from '../../../core/api/user.service';
import { SiteHelperService } from '../../../core/helper/site.service';
import * as fromApp from '../../../store';
import { resetStripeAccountRequiresAction } from '../../../store/notification/notification.actions';
import { setActiveSite } from '../../../store/site/site.actions';
import { selectActiveSite } from '../../../store/site/site.selectors';
import { LegalConstants } from '../../constants/legal.constants';
import { ValueAdjustmentEnum } from '../../constants/value_adjustment.enums';
import { AdjustValueDialogComponent } from '../../dialogs/adjust-value-dialog/adjust-value-dialog.component';
import { DialogConfirmPaymentComponent } from '../../dialogs/dialog-confirm-payment/dialog-confirm-payment.component';
import { DialogEditPaymentPeriodComponent } from '../../dialogs/dialog-edit-payment-period/dialog-edit-payment-period.component';
import { DialogYesNoComponent } from '../../dialogs/dialog-yes-no/dialog-yes-no.component';
import {
  Adjustment,
  AdjustmentType,
  PaymentPeriod,
  Site,
  SitePayment,
} from '../../models';

interface ValueAdjustment {
  type: ValueAdjustmentEnum;
  amount: number;
  date?: Date;
}

@Component({
  selector: 'fc-site-detail-payments',
  templateUrl: './site-detail-payments.component.html',
  styleUrls: ['./site-detail-payments.component.scss'],
})
export class SiteDetailPaymentsComponent implements OnInit {
  destroyRef = inject(DestroyRef);

  @Input()
  isSuperAdmin = false;

  @Input()
  canPaySites = false;

  @Input()
  isLandowner = true;

  totalPayments = null;
  showManualPayment = false;
  hasStripe: boolean = false;
  expressPayment = false;
  newManualPayment: UntypedFormGroup;
  payments: SitePayment[] = null;
  error: string = null;
  maxPayment: number = null;

  allAdjustmentTypes: AdjustmentType[] = [];
  nextPaymentPeriods: PaymentPeriod[] = [];
  initialAdjustment: number = 0;
  stripeDisclaimer = LegalConstants.STRIPE_DISCLAIMER;
  valuation: string;
  totalPaymentPeriods: number = 0;
  numShownPayments: number = 4;
  site: Site;

  showAdjustValue: boolean = false;

  valueAdjustmentForm: UntypedFormGroup;
  adminType: AdjustmentType;

  constructor(
    public dialog: MatDialog,
    readonly paymentService: PaymentService,
    readonly store: Store<fromApp.AppState>,
    readonly userService: UserService,
    readonly siteService: SiteService,
    readonly siteHelper: SiteHelperService,
    private formBuilder: UntypedFormBuilder,
    readonly adjustmentService: AdjustmentService
  ) {
    this.valueAdjustmentForm = this.formBuilder.group({
      amount: [0.0, Validators.required],
    });
  }

  ngOnInit() {
    this.store
      .select(selectActiveSite)
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        skipWhile(site => site === null)
      )
      .subscribe(activeSite => {
        this.site = activeSite;
        this.hasStripe = this.site.account.user.hasStripe(
          this.site.cohort.buyerId
        );
        this.valuation = this.site.valuation
          ? (
              parseFloat(this.site.valuation.split(',')[0]) /
              (12 / this.site.cohort.payPeriodCadence)
            ).toLocaleString(undefined, {
              maximumFractionDigits: 2,
            })
          : '0';
        this.paymentService.getHistory(this.site.id).subscribe(payments => {
          this.payments = payments.sort(
            (a, b) =>
              new Date(a.createDate).getTime() -
              new Date(b.createDate).getTime()
          );
          this.calculateTotalPayments();
        });

        this.adjustmentService
          .getAllAdjustmentTypes()
          .subscribe(adjustmentTypes => {
            this.allAdjustmentTypes = adjustmentTypes;
            this.adminType = adjustmentTypes.find(
              at => at.shortName === 'admin_adjustment'
            );
            this.setNextThreeAdjustments();
          });
      });
  }

  setNextThreeAdjustments(): void {
    this.nextPaymentPeriods = this.site.versions[
      this.site.versions.length - 1
    ].paymentPeriods.filter(p => !p.payment);
    this.totalPaymentPeriods = this.nextPaymentPeriods.length;
    if (this.nextPaymentPeriods.length > 0) {
      this.nextPaymentPeriods.sort((a, b) => a.sequence - b.sequence);
      for (let i = 0; i < 4; i++) {
        if (this.initialAdjustment === 0) {
          const adminAdjustment = this.nextPaymentPeriods[i].adjustments.find(
            adjustment => adjustment.adjustmentTypeId === this.adminType.id
          );
          if (adminAdjustment !== undefined) {
            this.initialAdjustment = adminAdjustment.value;
          }
        }
      }
      this.maxPayment = this.nextPaymentPeriods[0].netPayout;
    }
  }

  getAdjustmentViewName(name: string): string {
    switch (name) {
      case 'firewood': {
        return 'Firewood Adjustment';
      }
      case 'small_clearing': {
        return 'Clearing Adjustment';
      }
      case 'approval_adjustment': {
        return 'Approval Adjustment';
      }
      case 'promotion_adjustment': {
        return 'Promotion Adjustment';
      }
      default: {
        return 'Adjustment';
      }
    }
  }

  formatPaymentDate(date: Date): string {
    return moment(date).add(2, 'months').format('MMMM YYYY');
  }

  getPaymentDisplay(payment: SitePayment): string {
    return payment.error
      ? '—'
      : `$${((payment.paidAmountInCents || 0) / 100).toFixed(2)}`;
  }

  calculateTotalPayments(): void {
    let total = 0;
    if (this.payments && this.payments.length > 0) {
      this.payments.forEach(p => (total += p.paidAmountInCents));
    }
    this.totalPayments = (total / 100).toLocaleString('us-EN', {
      style: 'currency',
      currency: 'USD',
    });
  }

  addManualPayment(express) {
    this.expressPayment = express;
    if (express) {
      // Manual payments are ad-hoc Stripe payments (via Express) that are NOT associated with any payment period
      this.newManualPayment = this.formBuilder.group({
        paymentDate: [new Date(), Validators.required],
        paymentAmount: [
          '',
          [Validators.required, Validators.max(this.maxPayment)],
        ],
        paymentNote: ['', Validators.required],
      });
    } else {
      // External payments are ad-hoc non-Stripe payments that are NOT associated with any payment period
      this.maxPayment = null;
      this.newManualPayment = this.formBuilder.group({
        paymentDate: [new Date(), Validators.required],
        paymentAmount: ['', Validators.required],
        paymentNote: ['', Validators.required],
      });
    }
    this.error = null;
    this.showManualPayment = true;
  }

  handleNewPaymentButton(submit) {
    if (submit) {
      const data = {
        siteId: this.site.id,
        date: this.newManualPayment.get('paymentDate').value,
        amount: this.newManualPayment.get('paymentAmount').value,
        note: this.newManualPayment.get('paymentNote').value,
      };
      if (this.expressPayment) {
        let dialogData = {
          calcs: {
            totalPaid: data.amount,
            payments: [
              {
                siteId: this.site.id,
                name: this.site.name,
                amount: data.amount,
                buyerId: [this.site.cohort.buyer.id],
              },
            ],
          },
          alreadyPaid: 0,
          buyers: [this.site.cohort.buyer.id],
        };

        let dialogRef = this.dialog.open(DialogConfirmPaymentComponent, {
          panelClass: 'fc-dialog-confirm-payment',
          data: dialogData,
          autoFocus: false,
        });
        dialogRef.afterClosed().subscribe(result => {
          if (result) {
            let reqSub = this.expressPayment
              ? this.paymentService.expressPayment({ data })
              : this.paymentService.recordPayment({ data });
            reqSub.subscribe(
              response => {
                this.payments.push(response);
                this.calculateTotalPayments();
                this.error = null;
                this.showManualPayment = false;
                this.expressPayment = false;
              },
              err => {
                console.log(err.message);
                this.error =
                  'There was an error when adding your payment record. Please try again.';
              }
            );
          }
        });
      } else {
        this.paymentService.recordPayment({ data }).subscribe(
          response => {
            this.payments.push(response);
            this.calculateTotalPayments();
            this.error = null;
            this.showManualPayment = false;
            this.expressPayment = false;
          },
          err => {
            console.log(err.message);
            this.error =
              'There was an error when adding your payment record. Please try again.';
          }
        );
      }
    } else {
      this.error = null;
      this.showManualPayment = false;
      this.expressPayment = false;
    }
  }

  getPaymentMessage(payment) {
    if (payment.message != null && payment.message.length > 0) {
      return `${payment.transactionId ? 'Stripe: ' : ''}${payment.message}`;
    }
    if (payment.transactionId) {
      return 'Stripe';
    }
    return null;
  }

  setValueAdjustmentInitialValue() {
    this.valueAdjustmentForm.setValue({
      amount: this.initialAdjustment.toFixed(4),
    });
  }

  adjustValue(el: HTMLElement): void {
    this.setValueAdjustmentInitialValue();
    this.showAdjustValue = true;
    if (el) {
      el.scrollIntoView();
    }
  }

  cancelAdjustValue(): void {
    this.setValueAdjustmentInitialValue();
    this.showAdjustValue = false;
  }

  submitAdjustValue(): void {
    const currentValue = parseFloat(this.valuation);
    const newValue: number =
      currentValue - this.initialAdjustment + parseFloat(this.amount);
    const dialogRef = this.dialog.open(AdjustValueDialogComponent, {
      panelClass: 'fc-adjust-value-dialog',
      data: {
        currentValue: currentValue.toFixed(4),
        newValue: newValue.toFixed(4),
        siteName: this.site.name,
      },
      autoFocus: false,
    });

    dialogRef.afterClosed().subscribe(reason => {
      if (reason) {
        const value = this.amount;
        const newAdjustment = new Adjustment({
          value: value,
          adjustmentTypeId: this.adminType.id,
        });
        this.adjustmentService
          .createAdjustment(this.site.id, reason, newAdjustment)
          .subscribe({
            next: adjustment => {
              this.store.dispatch(setActiveSite({ id: this.site.id }));
            },
          });
      }
    });
  }

  doStripeConnect(): void {
    if (this.site && this.site.cohort.buyer) {
      // do api call to create stripe account uuid and save to user
      this.userService.doStripeConnect(
        this.site.account.user.id,
        this.site.cohort.buyerId,
        this.buyerName,
        this.site.account.user.email
      );
    }
  }

  getLoginLink(): void {
    if (this.site && this.site.cohort.buyer) {
      this.paymentService
        .getMyLoginUrl(this.site.cohort.buyerId)
        .subscribe((linkObject: StripeLoginLink) => {
          if (linkObject && linkObject.url) {
            this.store.dispatch(resetStripeAccountRequiresAction());
            const ua = window.navigator.userAgent;
            const iOS = !!ua.match(/iPad/i) || !!ua.match(/iPhone/i);
            const webkit = !!ua.match(/WebKit/i);
            const iOSSafari = iOS && webkit && !ua.match(/CriOS/i);
            if (iOSSafari) {
              window.location.href = linkObject.url;
            } else {
              window.open(linkObject.url);
            }
          }
        });
    }
  }

  get maxPaymentLabel() {
    return this.maxPayment
      ? `(max ${this.maxPayment.toLocaleString('us-EN', {
          style: 'currency',
          currency: 'USD',
        })})`
      : null;
  }

  get amount() {
    return this.valueAdjustmentForm.value.amount;
  }

  get stripeIsActive() {
    if (this.site && this.site.cohort.buyer) {
      const account = this.site.account.user.stripeAccounts.find(
        a => a.buyerId === this.site.cohort.buyerId
      );
      return account?.stripeAccountCreationDate != null;
    }
    return false;
  }

  get buyerName() {
    return this.site && this.site.cohort.buyer
      ? this.site.cohort.buyer.name
      : '';
  }

  get showStripeLink() {
    return (
      this.isLandowner &&
      this.site &&
      this.site.cohort.buyer &&
      this.site.activationDate
    );
  }

  viewMorePayments() {
    this.numShownPayments += 4;
  }

  editPeriod(pp: PaymentPeriod) {
    this.dialog
      .open(DialogEditPaymentPeriodComponent, {
        data: {
          paymentPeriod: pp,
        },
      })
      .afterClosed()
      .subscribe((updatePaymentPeriod: any) => {
        if (updatePaymentPeriod) {
          this.dialog
            .open(DialogYesNoComponent, {
              panelClass: 'fc-yes-no-dialog',
              data: {
                title: 'Are You Sure?',
                line1:
                  'This will change the payment information for this period. Please confirm.',
                buttonTextYes: 'Yes, Change',
                buttonTextNo: 'No, Cancel',
              },
              autoFocus: false,
            })
            .afterClosed()
            .subscribe(confirm => {
              if (confirm) {
                this.siteService
                  .updatePaymentPeriod(this.site.id, updatePaymentPeriod)
                  .subscribe(response => {
                    this.store.dispatch(setActiveSite({ id: this.site.id }));
                  });
              }
            });
        }
      });
  }
}
