import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { BalanceData, PaymentService } from '../../../core/api/payment.service';
import { PaymentPeriod, Site } from '../../models';

export interface DialogData {
  line1: string;
  periods: PaymentPeriod[];
  sites: Site[];
  showList: boolean;
  alreadyPaid: number;
  calcs: any;
  buyers: number[];
}

@Component({
  selector: 'fc-dialog-confirm-payment',
  templateUrl: './dialog-confirm-payment.component.html',
  styleUrls: ['./dialog-confirm-payment.component.scss'],
})
export class DialogConfirmPaymentComponent {
  payPeriod: PaymentPeriod = null;
  alreadyPaid: number = null;
  title: string = 'PAY SITE';
  calcs: any = null;
  balances: BalanceData[] = [];
  buyers: number[] = [];

  constructor(
    public dialogRef: MatDialogRef<DialogConfirmPaymentComponent>,
    readonly paymentService: PaymentService,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {
    if (data.alreadyPaid !== null) {
      this.alreadyPaid = data.alreadyPaid;
    }
    if (data.calcs) {
      this.calcs = data.calcs;
    }
    if (!Array.isArray(data.buyers) && Array.isArray(this.data.sites)) {
      this.buyers = [...new Set(this.data.sites.map(p => p.cohort.buyerId))];
    } else if (Array.isArray(data.buyers)) {
      this.buyers = data.buyers;
    }
    this.paymentService.getStripeBalance(this.buyers).subscribe(response => {
      this.balances = response;
    });
  }

  changePayPeriod(event: any): void {
    this.payPeriod = this.data.periods.find(p => p.id === event.value);
    this.alreadyPaid = 0;

    this.calcs = {
      payments: [],
      totalPaid: 0.0,
    };
    for (const site of this.data.sites) {
      const payment = site.versions[0].paymentPeriods.find(
        p =>
          p.startDate === this.payPeriod.startDate &&
          p.endDate === this.payPeriod.endDate &&
          p.report != null
      );
      if (payment) {
        if (payment.payment === null) {
          this.calcs.totalPaid += payment.netPayout;
          this.calcs.payments.push({
            site,
            buyerId: site.cohort.buyerId,
            name: site.name,
            amount: payment.netPayout,
          });
        } else {
          this.alreadyPaid += 1;
        }
      }
    }
    this.data.line1 = '';
  }

  onYesClick(): void {
    this.dialogRef.close({
      payPeriod: this.payPeriod,
      sites: this.calcs.payments.map(p => p.site),
    });
  }

  onNoClick(): void {
    this.dialogRef.close(null);
  }

  getPaymentsByBuyer(buyerId: any) {
    return this.calcs.payments.filter(p => p.buyerId == buyerId);
  }

  getTotalPaidByBuyer(buyerId: any) {
    return this.calcs.payments
      .filter(p => p.buyerId == buyerId)
      .reduce(
        (accumulator: number, value: any) => accumulator + (value.amount || 0),
        0
      );
  }
}
