import { createFeatureSelector, createSelector } from '@ngrx/store';

import * as fromBuyer from './buyer.reducer';

export const selectBuyerState = createFeatureSelector<fromBuyer.BuyerState>(
  fromBuyer.buyerFeatureKey
);

export const selectPreferredBuyer = createSelector(
  selectBuyerState,
  buyer => buyer.preferredBuyer
);
