<mat-expansion-panel>
  <mat-expansion-panel-header data-cy="reports-header">
    <mat-panel-title>Submitted Reports</mat-panel-title>
  </mat-expansion-panel-header>
  <mat-divider></mat-divider>
  <div class="report-list">
    <table
      mat-table
      [dataSource]="reports"
      class="report-list-table"
      aria-describedby="Site Reports">
      <ng-container matColumnDef="created">
        <th scope="col" mat-header-cell *matHeaderCellDef>Date Created</th>
        <td mat-cell *matCellDef="let element">
          {{ element.createDate | date: 'shortDate' }}
        </td>
      </ng-container>
      <ng-container matColumnDef="name">
        <th scope="col" mat-header-cell *matHeaderCellDef>Report Type</th>
        <td mat-cell *matCellDef="let element">{{ element.name }}</td>
      </ng-container>
      <ng-container matColumnDef="view">
        <th scope="col" mat-header-cell *matHeaderCellDef>&nbsp;</th>
        <td mat-cell *matCellDef="let element">
          <button
            (click)="viewReport(element)"
            color="primary"
            mat-stroked-button
            data-cy="view-report">
            View Report
          </button>
        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>
  </div>
  <div class="mobile-report-list">
    <div *ngFor="let report of reports">
      <div class="mobile-report" (click)="viewReport(report)">
        <div class="mobile-date-added">
          {{ report.createDate | date: 'shortDate' }}
        </div>
        <div class="mobile-name">{{ report.name }}</div>
      </div>
    </div>
  </div>
</mat-expansion-panel>
