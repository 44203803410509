import {
  Component,
  DestroyRef,
  EventEmitter,
  inject,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';

import { BuyerService } from '../../../core/api/buyer.service';
import { SiteService } from '../../../core/api/site.service';
import { FormatHelperService } from '../../../core/helper/format-helper.service';
import { SiteHelperService } from '../../../core/helper/site.service';
import * as fromApp from '../../../store';
import { selectIsMobileView } from '../../../store/app-config/app-config.selectors';
import { setMapControlDrawMode } from '../../../store/map-control/map-control.actions';
import SiteStatusConstants from '../../constants/site_status.constants';
import { RecalculateSiteDialogComponent } from '../../dialogs/dialog-recalculate-site/dialog-recalculate-site.component';
import { DialogYesNoComponent } from '../../dialogs/dialog-yes-no/dialog-yes-no.component';
import { Site } from '../../models';

@Component({
  selector: 'fc-site-card',
  templateUrl: './site-card.component.html',
  styleUrls: ['./site-card.component.scss'],
})
export class SiteCardComponent implements OnInit {
  @Input()
  site: Site;

  @Output()
  openSite: EventEmitter<{ id: number; hasParcel: boolean }> =
    new EventEmitter<{ id: number; hasParcel: boolean }>();

  @Output()
  deleteSite: EventEmitter<Site> = new EventEmitter<Site>();

  @Output()
  periodicReport: EventEmitter<Site> = new EventEmitter<Site>();

  @Output()
  disturbanceReport: EventEmitter<Site> = new EventEmitter<Site>();

  destroyRef = inject(DestroyRef);

  statusConstants = SiteStatusConstants;

  isMobileViewSubscription: Subscription;
  statusOwner = '';
  statusAdmin = '';
  isCardGray = false;
  statusColorStyle = '#201F1F';
  isMobile = false;
  isPendingSite = true;

  twentyYearMark = null;
  twentyYearProjection = 'N/A';
  projectedRevenue = '';
  showBuyerLogo = false;

  getOpenAssetURL = BuyerService.getOpenAssetURL;

  constructor(
    public dialog: MatDialog,
    readonly store: Store<fromApp.AppState>,
    readonly siteService: SiteService,
    readonly siteHelper: SiteHelperService,
    readonly formatHelper: FormatHelperService,
    readonly router: Router
  ) {}

  ngOnInit() {
    // Count stands

    this.isPendingSite = SiteStatusConstants.sitePendingStatusList.includes(
      this.site.status
    );

    this.store
      .select(selectIsMobileView)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(mobileViewMode => (this.isMobile = mobileViewMode));

    // TODO: Probably want to make owner/admin an input to this component
    this.statusOwner = SiteStatusConstants.getOwnerText(this.site.status);
    this.statusAdmin = SiteStatusConstants.getAdminText(this.site.status);

    this.setCardBackgroundColor();
    this.setStatusColor();

    this.twentyYearMark = this.site.getYearMarkString();
    this.twentyYearProjection = this.formatHelper.formatMinMaxValuation(
      this.site.getTwentyYearProjection()
    );

    this.projectedRevenue = this.formatHelper.formatMinMaxValuation(
      this.site.getAverageAnnualRevenue()
    );
    this.showBuyerLogo =
      (!!this.site.cohort.buyer ||
        this.site.status !== this.statusConstants.PENDING.status) &&
      this.site.submittedDate != null;
  }

  viewMap(id: number): void {
    this.router.navigate([`site/${id}/map-site`]);
  }

  get totalAcreage(): string {
    return Math.round(this.site.area).toLocaleString(undefined, {
      maximumFractionDigits: 0,
    });
  }

  notifyRestart(): void {
    this.dialog
      .open(DialogYesNoComponent, {
        panelClass: 'fc-yes-no-dialog',
        data: {
          title: 'THIS SITE MUST BE RESTARTED',
          line1:
            "We're sorry but the property data has changed and the submission must be restarted.",
          line2:
            'Click Restart Site to re-select the parcels and your forest data.',
          line3: 'Press Cancel to return to Dashboard.',
          buttonTextYes: 'Restart Site',
          buttonTextNo: 'Cancel',
        },
      })
      .afterClosed()
      .subscribe(result => {
        if (result === true) {
          // clear strata/parcels
          this.siteService.restartSite(this.site.id).subscribe(results => {
            this.openSite.emit({ id: this.site.id, hasParcel: false });
          });
        }
      });
  }

  needsUpdating(): boolean {
    if (this.site.lastRegionUpdate === null) {
      return false;
    }
    const cohortChangeDate = new Date(
      this.site.cohort.region.lastUpdated
    ).getTime();
    const siteChangeDate = new Date(this.site.lastRegionUpdate).getTime();
    return cohortChangeDate > siteChangeDate;
  }

  hasStands(): boolean {
    return this.site.versions[0].stands.length > 0;
  }

  onOpenSite($event, id: number): void {
    $event.stopPropagation();
    this.store.dispatch(setMapControlDrawMode({ active: true }));
    this.siteService
      .updateSiteParcelGIDs(this.site.id)
      .subscribe(updatedSite => {
        this.siteService.siteNeedsRestart(this.site.id).subscribe(restart => {
          if (restart) {
            this.notifyRestart();
          } else if (this.needsUpdating() && this.hasStands()) {
            const dialogConfig = new MatDialogConfig();
            dialogConfig.disableClose = true;
            dialogConfig.panelClass = 'fc-dialog-recalculate-site';
            dialogConfig.data = { site: updatedSite };
            dialogConfig.autoFocus = false;
            const dialogRef = this.dialog.open(
              RecalculateSiteDialogComponent,
              dialogConfig
            );
            dialogRef.afterClosed().subscribe(openSite => {
              if (openSite) {
                this.openSite.emit({ id, hasParcel: true });
              }
            });
          } else {
            this.openSite.emit({
              id,
              hasParcel: updatedSite.versions[0].parcels.length > 0,
            });
          }
        });
      });
  }

  onDeleteSite($event: any, site: Site): void {
    $event.stopPropagation();
    const dialogRef = this.dialog.open(DialogYesNoComponent, {
      panelClass: 'fc-yes-no-dialog',
      data: {
        title: 'Are you sure you want to delete this site?',
        line1: 'WARNING: This will permanently delete the site.',
        line2: 'This cannot be undone.',
        buttonTextYes: 'Delete Site',
        buttonTextNo: 'Cancel',
      },
      autoFocus: false,
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result === true) {
        this.deleteSite.emit(site);
      }
    });
  }

  onPeriodicReport(site: Site): void {
    this.periodicReport.emit(site);
  }

  onDisturbanceReport(site: Site): void {
    this.disturbanceReport.emit(site);
  }

  setCardBackgroundColor(): void {
    switch (this.site.status) {
      case SiteStatusConstants.PENDING.status:
      case SiteStatusConstants.REVIEW_REQUIRED.status:
        this.isCardGray = true;
        break;
      default:
        this.isCardGray = false;
    }
  }

  setStatusColor(): void {
    switch (this.site.status) {
      case SiteStatusConstants.REPORTING_REQUIRED.status:
        this.statusColorStyle = '#FF9330';
        break;
      case SiteStatusConstants.DISTURBANCE_REPORTED.status:
        this.statusColorStyle = '#ED5310';
        break;
      default:
        this.statusColorStyle = '#201F1F';
    }
  }

  projectionInfoBox(year: string): string {
    if (this.site && this.site.cohort && this.site.cohort.region) {
      return this.site.cohort.region.projectionInfoText(year);
    }
    return '';
  }

  navigate() {
    if (this.site.status !== SiteStatusConstants.PENDING.status) {
      this.router.navigate(['/account/site', this.site.id]);
    }
  }
}
