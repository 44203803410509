import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, concatMap, map, switchMap } from 'rxjs/operators';

import * as UserActions from './user.actions';

import { AccountService } from '../../core/api/account.service';
import { UserService } from '../../core/api/user.service';
import * as AuthActions from '../auth/auth.actions';

@Injectable()
export class UserEffects {
  createDelegate$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UserActions.createDelegate),
      concatMap(action => {
        return this.userService.createDelegate(action.data).pipe(
          switchMap(data => [
            AuthActions.setDelegate({ data }),
            UserActions.createDelegateSuccess({ data }),
          ]),
          catchError(error => of(UserActions.createDelegateFailure({ error })))
        );
      })
    );
  });

  removeDelegate$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UserActions.removeDelegate),
      concatMap(() => {
        return this.userService.removeDelegate().pipe(
          switchMap(data => [
            AuthActions.setDelegate({ data: null }),
            UserActions.removeDelegateSuccess({ data }),
          ]),
          catchError(error => of(UserActions.removeDelegateFailure({ error })))
        );
      })
    );
  });

  updateUserProfile$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UserActions.updateUserProfile),
      concatMap(action => {
        return this.userService.updateUserProfile(action.data).pipe(
          switchMap(data => {
            const actions = [];
            actions.push(UserActions.updateUserProfileSuccess({ data }));
            if (action.setCurrentUser) {
              actions.unshift(AuthActions.setCurrentUser({ data }));
            }
            return actions;
          }),
          catchError(error =>
            of(UserActions.updateUserProfileFailure({ error }))
          )
        );
      })
    );
  });

  getUsers$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UserActions.getUsers),
      concatMap(action => {
        return this.userService.getUsers().pipe(
          map(data => UserActions.getUsersSuccess({ data })),
          catchError(error => of(UserActions.getUsersFailure({ error })))
        );
      })
    );
  });

  setUserRole$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UserActions.setUserRole),
      concatMap(action => {
        return this.userService.setUserRole(action.userId, action.role).pipe(
          map(data => UserActions.setUserRoleSuccess({ data })),
          catchError(error => of(UserActions.setUserRoleFailure({ error })))
        );
      })
    );
  });

  verifyUser$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UserActions.verifyUser),
      concatMap(action => {
        return this.userService
          .verifyUser(action.userId, action.verified, action.clear)
          .pipe(
            map(data => UserActions.verifyUserSuccess({ data })),
            catchError(error => of(UserActions.verifyUserFailure({ error })))
          );
      })
    );
  });

  constructor(
    readonly actions$: Actions,
    readonly userService: UserService,
    readonly accountService: AccountService
  ) {}
}
