import { Action, createReducer, on } from '@ngrx/store';

import * as ParcelActions from './parcel.actions';

import { Parcel, Region, Stand } from '../../shared/models';
import * as SiteActions from '../site/site.actions';

export const parcelFeatureKey = 'parcel';

export interface MapCenter {
  lat: number;
  lng: number;
}

export interface ParcelState {
  selectedParcels: Array<Parcel>;
  selectedStands: Array<Stand>;
  forestryKml: Array<any>;
  mapCenter: MapCenter;
  email: string;
  acres: string;
  selectedRegion: Region;
  locationType: string;
  unsavedChanges: boolean;
  parcelSelectLock: boolean;
}

export const initialState: ParcelState = {
  selectedParcels: [],
  selectedStands: [],
  forestryKml: [],
  mapCenter: {
    lat: null,
    lng: null,
  },
  email: null,
  acres: null,
  selectedRegion: null,
  locationType: null,
  unsavedChanges: false,
  parcelSelectLock: false,
};

const parcelReducer = createReducer(
  initialState,
  on(ParcelActions.loadParcels, state => state),
  on(ParcelActions.loadParcelsSuccess, (state, _action) => state),
  on(ParcelActions.loadParcelsFailure, (state, _action) => state),
  on(ParcelActions.setMapCenter, (state, mapCenter) => ({
    ...state,
    mapCenter: mapCenter.data,
  })),
  on(ParcelActions.setSelectedParcels, (state, selectedParcels) => {
    const selectedStands = state.selectedStands;
    return {
      ...state,
      selectedParcels: selectedParcels.data,
      selectedStands,
    };
  }),
  on(ParcelActions.setUnsavedChanges, (state, data) => ({
    ...state,
    unsavedChanges: data.data.unsavedChanges,
  })),
  on(
    SiteActions.createSiteSuccess,
    SiteActions.updateSelectParcelSiteSuccess,
    (state, data) => ({
      ...state,
      unsavedChanges: false,
    })
  ),
  on(SiteActions.updateNewImprovedSiteSuccess, (state, data) => ({
    ...state,
    unsavedChanges: false,
  })),
  on(ParcelActions.selectAllStandsSuccess, (state, data) => ({
    ...state,
    selectedStands: data.data.results,
    unsavedChanges: true,
  })),
  on(ParcelActions.removeRedFlagSuccess, (state, data) => ({
    ...state,
    selectedStands: data.stands,
    unsavedChanges: true,
  })),
  on(ParcelActions.setForestryKml, (state, forestryStands) => ({
    ...state,
    forestryKml: forestryStands.data,
  })),
  on(ParcelActions.deleteForestryKml, (state, action) => ({
    ...state,
    forestryKml: [],
  })),
  on(ParcelActions.setStepOne, (state, action) => ({
    ...state,
    email: action.data.email,
    acres: action.data.acres,
  })),

  on(ParcelActions.clearCreateSiteStepThree, (state, action) => ({
    ...state,
    selectedParcels: [],
    forestryKml: [],
  })),
  on(ParcelActions.clearCreateSiteStepTwo, (state, action) => ({
    ...state,
    locationType: null,
    mapCenter: {
      lat: null,
      lng: null,
    },
    selectedRegion: null,
  })),

  on(ParcelActions.addSelectedStand, (state, selectedStand) => ({
    ...state,
    selectedStands: [...state.selectedStands, selectedStand.data.stand],
    unsavedChanges: true,
  })),
  on(ParcelActions.updateSelectedStand, (state, selectedStand) => ({
    ...state,
    selectedStands: [
      ...state.selectedStands.map(item =>
        item.id === selectedStand.data.id
          ? {
              ...selectedStand.data,
            }
          : item
      ),
    ],
    unsavedChanges: true,
  })),
  on(ParcelActions.resetParcels, (state, parcelStands) => ({
    ...state,
    selectedParcels: parcelStands.data.parcels,
    selectedStands: parcelStands.data.stands,
    unsavedChanges: parcelStands.data.unsavedChanges
      ? parcelStands.data.unsavedChanges
      : false,
  })),
  on(ParcelActions.resetStands, (state, data) => {
    const selectedStands = data.data.stands;

    return {
      ...state,
      selectedStands,
    };
  }),
  on(ParcelActions.removeParcel, (state, data) => {
    const selectedParcels = state.selectedParcels.filter(
      p => p.gid !== data.gid
    );
    const selectedStands = state.selectedStands;
    return {
      ...state,
      selectedParcels,
      selectedStands,
      unsavedChanges: true,
    };
  }),
  on(ParcelActions.deleteSelectedStand, (state, selectedStand) => ({
    ...state,
    selectedStands: [
      ...state.selectedStands.filter(
        item => item.feature.id !== selectedStand.data.feature.id
      ),
    ],
    unsavedChanges: true,
  })),
  on(ParcelActions.clearAll, state => {
    return {
      ...initialState,
    };
  }),
  on(ParcelActions.setSelectedRegion, (state, selectedRegion) => ({
    ...state,
    selectedRegion: selectedRegion.data,
  })),
  on(ParcelActions.setLocationType, (state, locationType) => ({
    ...state,
    locationType: locationType.data,
  })),
  on(ParcelActions.toggleParcelSelectLock, (state, action) => ({
    ...state,
    parcelSelectLock: action.lock,
  }))
);

export function reducer(state: ParcelState | undefined, action: Action) {
  return parcelReducer(state, action);
}
