<div *ngIf="totalPages > 0" class="paginator-container">
  <div>
    Jump to Page
    <input (keyup.enter)="changePage($event.target.value)" />
  </div>
  <div class="page-nav-numbers">
    <fa-icon
      (click)="changePage(currentPage - 1)"
      [icon]="['fas', 'chevron-left']"
      [ngClass]="{ disabled: currentPage === 1 }"
      class="dark-gray clickable"></fa-icon>
    <fa-icon
      (click)="changePage(1)"
      [icon]="['fas', 'chevrons-left']"
      [ngClass]="{ disabled: currentPage === 1 }"
      class="dark-gray clickable"></fa-icon>
    <ul>
      <li *ngFor="let page of showPages">
        <button
          (click)="changePage(page)"
          [ngClass]="{ 'highlight-page': page === currentPage }"
          mat-button>
          {{ page }}
        </button>
      </li>
    </ul>
    <fa-icon
      (click)="changePage(totalPages)"
      [icon]="['fas', 'chevrons-right']"
      [ngClass]="{ disabled: currentPage === totalPages }"
      class="dark-gray clickable"></fa-icon>
    <fa-icon
      (click)="changePage(currentPage + 1)"
      [icon]="['fas', 'chevron-right']"
      [ngClass]="{ disabled: currentPage === totalPages }"
      class="dark-gray clickable"></fa-icon>
  </div>
</div>
