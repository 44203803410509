import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, concatMap, finalize, map } from 'rxjs/operators';

import { SpinnerService } from 'src/app/shared/spinner/spinner.service';

import * as AppConfigActions from './app-config.actions';

import { AppConfigService } from '../../core/api/app-config.service';

@Injectable()
export class AppConfigEffects {
  getAppConfig$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AppConfigActions.getAppConfig),
      concatMap(_action => {
        return this.appConfigService.getAllAppConfig().pipe(
          map(data => AppConfigActions.getAppConfigSuccess({ data })),
          catchError(error =>
            of(AppConfigActions.getAppConfigFailure({ error }))
          )
        );
      })
    );
  });

  updateAppConfig$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AppConfigActions.updateAppConfig),
      concatMap(action => {
        this.spinnerService.show('app-config-spinner');
        return this.appConfigService.updateAppConfig(action.appConfig).pipe(
          map(data => AppConfigActions.updateAppConfigSuccess({ data })),
          catchError(error =>
            of(AppConfigActions.updateAppConfigFailure({ error }))
          ),
          finalize(() => this.spinnerService.hide('app-config-spinner'))
        );
      })
    );
  });

  constructor(
    readonly actions$: Actions,
    readonly appConfigService: AppConfigService,
    readonly spinnerService: SpinnerService
  ) {}
}
