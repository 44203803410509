<div mat-dialog-title>
  <div>About {{ data.programName }}</div>
  <div (click)="onNoClick()" class="close-icon-container">
    <fa-icon [icon]="['far', 'xmark']" class="gray"></fa-icon>
  </div>
</div>
<mat-divider></mat-divider>
<div mat-dialog-content>
  <div>
    <p *ngIf="data.aboutText">{{ data.aboutText }}</p>
    <div *ngIf="data.website">
      <a href="{{ data.website }}" rel="noopener noreferrer" target="_blank">
        Visit our website
        <fa-icon
          [icon]="['fas', 'arrow-up-right-from-square']"
          class="blue"></fa-icon>
      </a>
    </div>
    <p *ngIf="data.addressTo || data.addressLine1 || data.phone || data.email">
      You can contact {{ data.programName }} at:
    </p>
    <div *ngIf="data.addressTo" class="bold">Address:</div>
    <div *ngIf="data.addressTo" class="bold">{{ data.addressTo }}</div>
    <div *ngIf="data.addressLine1" class="bold">{{ data.addressLine1 }}</div>
    <div *ngIf="data.addressLine2">{{ data.addressLine2 }}</div>
    <div *ngIf="data.addressLine3">{{ data.addressLine3 }}</div>

    <p *ngIf="data.phone">
      <span class="bold">Phone:</span>
      <a href="tel:{{ data.phone }}">{{ data.phone }}</a>
    </p>
    <p *ngIf="data.email">
      <span class="bold">Email:</span>
      <a href="mailto:{{ data.email }}">{{ data.email }}</a>
    </p>
  </div>
</div>
