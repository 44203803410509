<div mat-dialog-title>
  <div>ADJUST VALUE</div>
  <div class="close-icon-container" [mat-dialog-close]="">
    <fa-icon [icon]="['far', 'xmark']" class="gray"></fa-icon>
  </div>
</div>
<mat-divider></mat-divider>
<div mat-dialog-content>
  <div class="values-container">
    <p>
      Current Annual ERT Valuation:
      <span class="value">{{ data.currentValue }}</span>
    </p>
    <p>
      Change to:
      <span class="value">{{ data.newValue }}</span>
    </p>
  </div>
  <mat-divider></mat-divider>
  <p>
    Are you sure you want to permanently adjust the valuation for Site:
    {{ data.siteName }}?
  </p>
  <p>
    Please provide an explanation for this adjustment:
    <textarea [(ngModel)]="reason" class="reason" rows="7"></textarea>
  </p>
</div>
<div mat-dialog-actions>
  <button
    data-cy="button-no"
    mat-stroked-button
    color="primary"
    [mat-dialog-close]="">
    No, Cancel
  </button>
  <button
    data-cy="button-yes"
    mat-raised-button
    color="primary"
    [disabled]="yesButtonDisabled"
    [mat-dialog-close]="reason">
    Yes, Confirm
  </button>
</div>
