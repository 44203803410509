<div mat-dialog-title>
  <div>{{ title }}</div>
  <div class="close-icon-container" (click)="onNoClick()">
    <fa-icon [icon]="['far', 'xmark']" class="gray"></fa-icon>
  </div>
</div>
<mat-divider></mat-divider>
<div mat-dialog-content>
  <p class="line-1" *ngIf="data.line1">{{ data.line1 }}</p>
  <div class="pay-period-select" *ngIf="data.periods">
    <mat-label>Payment Period:</mat-label>
    <mat-form-field>
      <mat-select
        class="period-select"
        (selectionChange)="changePayPeriod($event)"
        panelClass="status-panel"
        placeholder="Select a Pay Period">
        <mat-option *ngFor="let period of data.periods" [value]="period.id">
          {{ period.startDate | date: 'shortDate' }} -
          {{ period.endDate | date: 'shortDate' }}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-divider></mat-divider>
  </div>

  <ng-container *ngIf="alreadyPaid !== null && calcs">
    <div class="list-title">You are about to send the following payments:</div>
    <div class="payments">
      <ng-container *ngFor="let buyerBalance of balances">
        <div *ngIf="getTotalPaidByBuyer(buyerBalance.buyerId) > 0">
          <div class="buyer-label">{{ buyerBalance.buyerName }}</div>
          <mat-divider></mat-divider>
          <div class="buyer-details">
            <div
              class="payment"
              [ngClass]="buyerBalance.amount === 0 ? 'zero-balance' : ''">
              Beginning Stripe Balance:
              {{ buyerBalance.amount | currency }}
            </div>
            <div
              class="payment"
              *ngFor="let payment of getPaymentsByBuyer(buyerBalance.buyerId)">
              <span class="site-name">{{ payment.name }}:</span>
              {{ payment.amount | currency }}
            </div>
            <mat-divider></mat-divider>
            <div>
              Total Payments:
              {{ getTotalPaidByBuyer(buyerBalance.buyerId) | currency }}
            </div>
            <div class="payment">
              Ending Stripe Balance:
              {{
                buyerBalance.amount - getTotalPaidByBuyer(buyerBalance.buyerId)
                  | currency
              }}
            </div>
            <div
              class="error-message"
              *ngIf="
                buyerBalance.amount -
                  getTotalPaidByBuyer(buyerBalance.buyerId) <
                0
              ">
              This stripe balance is insufficient for the desired payment. Be
              advised that if you proceed with payment, some payments may fail.
              Please contact Finite Carbon Finance for details.
            </div>
          </div>
        </div>
      </ng-container>
      <div class="list-title">
        <span *ngIf="alreadyPaid > 0">
          Please note: {{ alreadyPaid }} site{{
            alreadyPaid > 1 ? 's' : ''
          }}
          for this payment period {{ alreadyPaid > 1 ? 'have' : 'has' }} already
          been paid and will not be paid again.
        </span>
      </div>
    </div>
  </ng-container>
</div>
<div mat-dialog-actions>
  <button
    (click)="onNoClick()"
    cdkFocusInitial
    color="primary"
    data-cy="button-no"
    mat-stroked-button>
    Cancel
  </button>
  <div></div>
  <button
    (click)="onYesClick()"
    [disabled]="alreadyPaid === null"
    color="primary"
    data-cy="button-yes"
    mat-raised-button>
    Confirm
  </button>
</div>
