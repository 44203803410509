import { createFeatureSelector, createSelector } from '@ngrx/store';

import * as fromSite from './document.reducer';

export const selectDocumentState =
  createFeatureSelector<fromSite.DocumentState>(fromSite.documentFeatureKey);

export const selectDocumentTypes = createSelector(
  selectDocumentState,
  document => document.documentTypes
);
