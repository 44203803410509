import { Component, DestroyRef, inject, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';

import {
  AppConfigModel,
  AppConfigService,
} from '../../../core/api/app-config.service';
import * as fromApp from '../../../store';
import { User } from '../../models';

@Component({
  selector: 'fc-dialog-confirm-submit',
  templateUrl: './dialog-confirm-submit.component.html',
  styleUrls: ['./dialog-confirm-submit.component.scss'],
})
export class DialogConfirmSubmitComponent implements OnInit {
  destroyRef = inject(DestroyRef);
  currentUser: User = null;

  finalConfirmation: string[] = [
    'You agree to a no harvest commitment of 20 years, with an additional 20 years of growth only harvesting.',
    'You agree that payments will be made at no-cost to you via a third party provider, currently Stripe, Inc.',
  ];
  constructor(
    readonly store: Store<fromApp.AppState>,
    readonly router: Router,
    readonly appConfigService: AppConfigService
  ) {}

  ngOnInit() {
    this.appConfigService
      .getAllAppConfig()
      .subscribe((appConfig: AppConfigModel[]) => {
        let config = appConfig.find(config => config.key === 'submit_review');
        if (config !== null) {
          this.finalConfirmation = JSON.parse(config.value);
        }
      });
  }
}
