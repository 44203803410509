@if (isAdmin || isLimitedAdmin) {
<div class="admin-toolbar">
  <mat-toolbar>
    <div (click)="navigate()" class="header-logo-container">
      <img
        alt="CORE Carbon by Finite Carbon"
        src="../../../assets/core-header-logo.svg" />
    </div>
    <div class="spacer"></div>

    <div
      #dashboard_la="routerLinkActive"
      [routerLinkActiveOptions]="{ exact: true }"
      class="menu-link"
      data-cy="app-header-sites"
      routerLinkActive>
      <a [routerLink]="['/admin/dashboard']">
        <div
          [ngClass]="
            dashboard_la.isActive
              ? 'icon-container__active'
              : 'icon-container_admin__inactive'
          "
          class="header-link"
          title="Site Dashboard">
          <fa-icon [icon]="['fas', 'table-columns']"></fa-icon>
          Sites
        </div>
      </a>
    </div>

    <div
      *ngIf="isAdmin || isLimitedAdmin"
      #dashboards_la="routerLinkActive"
      [routerLinkActiveOptions]="{ exact: false }"
      class="menu-link"
      data-cy="app-header-dashboards"
      routerLinkActive>
      <a
        (menuClosed)="menuClosed()"
        (menuOpened)="menuOpened()"
        [matMenuTriggerFor]="manageDashboardsMenu">
        <ng-container>
          <div
            [ngClass]="
              dashboards_la.isActive
                ? 'icon-container__active'
                : 'icon-container_admin__inactive'
            "
            class="header-link"
            title="Manage Info">
            <fa-icon [icon]="['fas', 'gauge']"></fa-icon>
            Dashboards
          </div>
        </ng-container>
      </a>
      <mat-menu #manageDashboardsMenu="matMenu">
        <button
          [routerLink]="['/admin/checklist-dashboard']"
          mat-menu-item
          data-cy="app-header-checklist-dashboard">
          Checklist
        </button>
        <button
          [routerLink]="['/admin/payment-dashboard']"
          mat-menu-item
          data-cy="app-header-payment-dashboard">
          Payments
        </button>
        <button
          [routerLink]="['/admin/stats-dashboard']"
          mat-menu-item
          data-cy="app-header-stats-dashboard">
          Stats
        </button>
      </mat-menu>
    </div>

    <div
      *ngIf="isAdmin"
      #manage_la="routerLinkActive"
      [routerLinkActiveOptions]="{ exact: false }"
      class="menu-link"
      data-cy="app-header-manage-info"
      routerLinkActive>
      <a
        (menuClosed)="menuClosed()"
        (menuOpened)="menuOpened()"
        [matMenuTriggerFor]="manageMenu">
        <ng-container>
          <div
            [ngClass]="
              manage_la.isActive
                ? 'icon-container__active'
                : 'icon-container_admin__inactive'
            "
            class="header-link"
            title="Manage Info">
            <fa-icon [icon]="['fas', 'pen-to-square']"></fa-icon>
            Manage Info
          </div>
        </ng-container>
      </a>
      <mat-menu #manageMenu="matMenu">
        <button
          [routerLink]="['/admin/manage-app-config']"
          mat-menu-item
          data-cy="app-header-manage-info-app-config">
          App Config
        </button>
        <button
          [routerLink]="['/admin/manage-buyers']"
          mat-menu-item
          data-cy="app-header-manage-info-buyers">
          Buyers
        </button>
        <button
          [routerLink]="['/admin/manage-cohort']"
          mat-menu-item
          data-cy="app-header-manage-info-cohorts">
          Cohorts
        </button>
        <button
          [routerLink]="['/admin/manage-program']"
          mat-menu-item
          data-cy="app-header-manage-info-programs">
          Programs
        </button>
        <button
          [routerLink]="['/admin/manage-region']"
          mat-menu-item
          data-cy="app-header-manage-info-regions">
          Regions
        </button>
        <button
          *ngIf="isSuperAdmin"
          [routerLink]="['/admin/admin-utilities']"
          mat-menu-item
          data-cy="app-header-admin-utilities">
          Utilities
        </button>
      </mat-menu>
    </div>

    <div
      #permissions_la="routerLinkActive"
      [routerLinkActiveOptions]="{ exact: true }"
      class="menu-link"
      routerLinkActive
      data-cy="app-header-manage-users">
      <span
        [matBadgeHidden]="adminFailedVerificationUsersCount === 0"
        class="admin-manage-users-badge"
        [matBadge]="adminFailedVerificationUsersCount"
        matBadgeColor="warn"
        data-cy="admin-manage-users-badge"></span>
      <a
        (menuClosed)="menuClosed()"
        (menuOpened)="menuOpened()"
        [matMenuTriggerFor]="userMenu"
        class="profile-menu">
        <ng-container>
          <div
            [ngClass]="
              permissions_la.isActive
                ? 'icon-container__active'
                : 'icon-container_admin__inactive'
            "
            class="header-link"
            title="Manage Users">
            <fa-icon [icon]="['fas', 'shield-halved']"></fa-icon>
            Manage Users
          </div>
        </ng-container>
      </a>
      <mat-menu #userMenu="matMenu">
        <span
          [matBadgeHidden]="adminFailedVerificationUsersCount === 0"
          matBadge="{{ adminFailedVerificationUsersCount }}"
          matBadgeColor="warn"
          class="user-permissions-badge"></span>
        <button
          [routerLink]="['/admin/permissions']"
          data-cy="user-permissions"
          mat-menu-item>
          User Permissions
        </button>
        <span class="drop-down-mat">
          <button [routerLink]="['/admin/user-activity']" mat-menu-item>
            User Activity
          </button>
        </span>
      </mat-menu>
    </div>

    <div
      #account_la="routerLinkActive"
      [routerLinkActiveOptions]="{ exact: true }"
      class="menu-link"
      routerLinkActive>
      <a
        (menuClosed)="menuClosed()"
        (menuOpened)="menuOpened()"
        [matMenuTriggerFor]="accountMenu"
        class="profile-menu">
        <ng-container>
          <div
            [ngClass]="{
              'icon-container__active': account_la.isActive,
              'icon-container__inactive': !account_la.isActive,
              'centered-icon': !accountName
            }"
            class="header-link allow-grow"
            title="Logout/Reset Password">
            <fa-icon [icon]="['fas', 'circle-user']"></fa-icon>
            Account
          </div>
        </ng-container>
      </a>
      <mat-menu #accountMenu="matMenu">
        <button
          [queryParams]="{ mode: authModeEnum.changePassword }"
          [routerLink]="['/auth']"
          mat-menu-item>
          Change Password
        </button>
        <button (click)="logout()" class="logout" mat-menu-item>Log Out</button>
      </mat-menu>
    </div>
  </mat-toolbar>
</div>
} @else {
<ng-container *ngIf="!isHomeRoute()">
  <div>
    <mat-toolbar class="toolbar">
      <div (click)="navigate()" class="header-logo-container">
        <div
          *ngIf="preferredBuyer; else coreLogo"
          class="buyer-logo"
          title="CORE Dashboard">
          <img
            [alt]="preferredBuyer.name"
            [src]="getOpenAssetURL(preferredBuyer.shortName, 'logo.svg')" />
          <div class="buyer-slogan">{{ preferredBuyer.slogan }}</div>
        </div>
        <ng-template #coreLogo>
          <img
            [ngClass]="{ clickable: isVerified }"
            alt="CORE Carbon by Finite Carbon"
            src="../../../assets/core-header-logo.svg" />
        </ng-template>
      </div>
      <div *ngIf="currentUser?.landowners.length > 0" class="delegate-name">
        <div class="avatar">
          {{ actAsUser.getDisplayName({ initials: true }) }}
        </div>
        <div>
          Viewing
          {{
            actAsUser.id === currentUser.id
              ? 'your personal account'
              : 'the account of ' + actAsUser.getDisplayName({})
          }}
        </div>
        <a routerLink="/account/dashboard">&lt;Change&gt;</a>
      </div>
      <div class="spacer"></div>

      <ng-container *ngIf="loggedIn; else loggedOut">
        <div
          #dashboard_la="routerLinkActive"
          *ngIf="!isMobile"
          [routerLinkActiveOptions]="{ exact: true }"
          class="menu-link"
          routerLinkActive>
          <ng-container>
            <a [routerLink]="'/account/dashboard'">
              <ng-container>
                <div
                  [ngClass]="
                    dashboard_la.isActive
                      ? 'icon-container__active'
                      : 'icon-container__inactive'
                  "
                  class="header-link">
                  <fa-icon [icon]="['fas', 'tree']"></fa-icon>
                  Dashboard
                </div>
              </ng-container>
            </a>
          </ng-container>
        </div>
        <div
          #faq_la="routerLinkActive"
          *ngIf="!isMobile"
          [routerLinkActiveOptions]="{ exact: true }"
          class="menu-link"
          routerLinkActive>
          <ng-container>
            <a [routerLink]="'/help'" target="_blank" rel="noopener noreferrer">
              <ng-container>
                <div
                  [ngClass]="
                    faq_la.isActive
                      ? 'icon-container__active'
                      : 'icon-container__inactive'
                  "
                  class="header-link">
                  <fa-icon [icon]="['fas', 'question-circle']"></fa-icon>
                  Help
                </div>
              </ng-container>
            </a>
          </ng-container>
        </div>

        <div
          #account_la="routerLinkActive"
          *ngIf="!isMobile"
          [routerLinkActiveOptions]="{ exact: true }"
          class="menu-link"
          data-cy="app-header-landowner-account"
          routerLinkActive>
          <span
            class="landowner-notification-badge"
            [matBadgeHidden]="
              landOwnerAccountNotificationBadgeCount <= 0 || accountMenuOpen
            "
            matBadge="{{ landOwnerAccountNotificationBadgeCount }}"
            matBadgeColor="warn"></span>
          <a
            (menuClosed)="menuClosed()"
            (menuOpened)="menuOpened()"
            [matMenuTriggerFor]="accountMenu"
            class="profile-menu">
            <ng-container>
              <div
                [ngClass]="{
                  'icon-container__active': account_la.isActive,
                  'icon-container__inactive': !account_la.isActive,
                  'centered-icon': !accountName
                }"
                class="header-link allow-grow">
                <fa-icon [icon]="['fas', 'circle-user']"></fa-icon>
                Account
              </div>
            </ng-container>
          </a>
          <mat-menu #accountMenu="matMenu">
            <span
              [matBadgeHidden]="landOwnerAccountNotificationBadgeCount <= 0"
              matBadge="{{ landOwnerAccountNotificationBadgeCount }}"
              matBadgeColor="warn"
              class="drop-down-mat">
              <button
                *ngIf="isVerified"
                [routerLink]="['/account/profile']"
                data-cy="app-header-landowner-account-profile"
                mat-menu-item>
                Profile
              </button>
            </span>
            <span class="drop-down-mat">
              <button
                *ngIf="isVerified"
                [routerLink]="['/account/profile/activity']"
                mat-menu-item>
                Activity
              </button>
            </span>
            <button (click)="logout()" class="logout" mat-menu-item>
              Log Out
            </button>
          </mat-menu>
        </div>

        <div
          #account_la="routerLinkActive"
          *ngIf="isMobile"
          [routerLinkActiveOptions]="{ exact: true }"
          class="menu-link"
          routerLinkActive>
          <a [matMenuTriggerFor]="mobileMenu">
            <ng-container>
              <div
                [ngClass]="
                  account_la.isActive
                    ? 'icon-container__active'
                    : 'icon-container__inactive'
                "
                class="header-link">
                <fa-icon [icon]="['fas', 'bars']"></fa-icon>
                Menu
              </div>
            </ng-container>
          </a>
          <mat-menu #mobileMenu="matMenu">
            <ng-container>
              <a
                [routerLink]="['/help']"
                target="_blank"
                rel="noopener noreferrer"
                mat-menu-item>
                Help
              </a>
              <button [routerLink]="'/account/dashboard'" mat-menu-item>
                Dashboard
              </button>
              <button
                *ngIf="isVerified"
                [routerLink]="['/account/profile']"
                mat-menu-item>
                Profile
              </button>
              <button
                *ngIf="isVerified"
                [routerLink]="['/account/profile/activity']"
                mat-menu-item>
                Activity
              </button>
              <button (click)="logout()" class="logout" mat-menu-item>
                Log Out
              </button>
            </ng-container>
          </mat-menu>
        </div>
      </ng-container>
      <ng-template #loggedOut>
        <div *ngIf="!isMobile" class="logged-out-link">
          <a routerLink="/auth">LOG IN</a>
        </div>
        <div
          #account_la="routerLinkActive"
          *ngIf="isMobile"
          [routerLinkActiveOptions]="{ exact: true }"
          class="menu-link"
          routerLinkActive>
          <a [matMenuTriggerFor]="mobileMenu">
            <ng-container>
              <div
                [ngClass]="
                  account_la.isActive
                    ? 'icon-container__active'
                    : 'icon-container__inactive'
                "
                class="header-link">
                <fa-icon [icon]="['fas', 'bars']"></fa-icon>
                Menu
              </div>
            </ng-container>
          </a>
          <mat-menu #mobileMenu="matMenu">
            <button [routerLink]="['/auth']" mat-menu-item>Log In</button>
          </mat-menu>
        </div>
      </ng-template>
    </mat-toolbar>
  </div>
</ng-container>
} @if (showInfoMessage) {
<div class="banner">
  <div class="infoMessage">
    {{ this.infoMessage }}
  </div>
  <div (click)="closeInfoMessage()" class="close-icon-container">
    <fa-icon [icon]="['far', 'xmark']" class="white"></fa-icon>
  </div>
</div>
}
