import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CanDeactivateEditMapGuard } from './core/auth/edit-map.guard';
import { TokenInterceptor } from './core/auth/token.interceptor';
import { CoreModule } from './core/core.module';
import { MapService } from './core/helper/map.service';
import { ErrorNotifierInterceptor } from './core/interceptor/error-handler.interceptor';
import { CreateAccountModule } from './feature/create-account/create-account.module';
import { DiagnosticsModule } from './feature/diagnostics/diagnostics.module';
import { HomeModule } from './feature/home/home.module';
import { SharedModule } from './shared';
import { metaReducers, reducers } from './store';
import { AdminEffects } from './store/admin/admin.effects';
import { AppConfigEffects } from './store/app-config/app-config.effects';
import { AuthEffects } from './store/auth/auth.effects';
import { BuyerEffects } from './store/buyer/buyer.effects';
import { CohortEffects } from './store/cohort/cohort.effects';
import { DocumentEffects } from './store/document/document.effects';
import { MapControlEffects } from './store/map-control/map-control.effects';
import { NotificationEffects } from './store/notification/notification.effects';
import { ParcelEffects } from './store/parcel/parcel.effects';
import { ReportEffects } from './store/report/report.effects';
import { SiteEffects } from './store/site/site.effects';
import { SiteNoteEffects } from './store/site-note/site-note.effects';
import { UserEffects } from './store/user/user.effects';

import { environment } from '../environments/environment';

@NgModule({
  declarations: [AppComponent],
  imports: [
    AppRoutingModule,
    BrowserModule,
    BrowserAnimationsModule,
    StoreModule.forRoot(reducers, {
      metaReducers,
      runtimeChecks: {
        strictStateImmutability: true,
        strictActionImmutability: true,
      },
    }),

    /* core & shared */
    CoreModule,
    SharedModule,

    /* Feature Modules */
    HomeModule,
    DiagnosticsModule,
    CreateAccountModule,

    StoreDevtoolsModule.instrument({
      maxAge: 25,
      logOnly: environment.production,
      connectInZone: true,
    }),

    EffectsModule.forRoot([
      AuthEffects,
      ParcelEffects,
      MapControlEffects,
      AdminEffects,
      UserEffects,
      SiteEffects,
      SiteNoteEffects,
      DocumentEffects,
      NotificationEffects,
      ReportEffects,
      AppConfigEffects,
      CohortEffects,
      BuyerEffects,
    ]),
  ],
  providers: [
    /* Add singleton services */
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorNotifierInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true,
    },
    CanDeactivateEditMapGuard,
    MapService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
