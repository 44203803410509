<div mat-dialog-title>
  <div>Terminate or Transfer a Contract</div>
  <div class="close-icon-container" (click)="onNoClick()">
    <fa-icon [icon]="['far', 'xmark']" class="gray"></fa-icon>
  </div>
</div>
<mat-divider></mat-divider>
<div class="message-box">
  <div class="message">
    Termination or Transfer of your carbon offset site can only be done over the
    phone. Please call us at
    <a href="tel:{{ contactPhone }}" rel="noopener noreferrer">
      {{ contactPhone }}
    </a>
    .
  </div>
  <div class="contact-info">
    You can also email us at
    <a
      href="mailto:{{ contactEmail }}"
      rel="noopener noreferrer"
      target="_blank">
      {{ contactEmail }}
    </a>
    for additional information.
  </div>
</div>
<div mat-dialog-actions>
  <button
    color="primary"
    mat-raised-button
    [mat-dialog-close]="true"
    data-cy="button-yes">
    OK, Got It
  </button>
</div>
