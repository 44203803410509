<div class="main">
  <div mat-dialog-title>
    <div>STOP: Final Confirmation</div>
    <div class="close-icon-container" [mat-dialog-close]="false">
      <fa-icon [icon]="['far', 'xmark']" class="gray"></fa-icon>
    </div>
  </div>
  <mat-divider></mat-divider>
  <div mat-dialog-content>
    <div class="message">
      Please confirm that you understand the following statements regarding your
      site submission:
      <ul>
        <li *ngFor="let confirm of finalConfirmation">{{ confirm }}</li>
      </ul>
    </div>
  </div>
  <div mat-dialog-actions>
    <button
      color="primary"
      mat-stroked-button
      [mat-dialog-close]="false"
      data-cy="button-no">
      Cancel
    </button>
    <button
      data-cy="button-yes"
      mat-raised-button
      color="primary"
      [mat-dialog-close]="true">
      I Agree, Submit
    </button>
  </div>
</div>
