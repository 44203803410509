import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { ToastLevel } from 'src/app/shared/models/toast-message.module';
import { ToastService } from 'src/app/shared/services/toast.service';

import { MaintenanceModeService } from '../helper/maintenance.service';

@Injectable({
  providedIn: 'root',
})
export class ErrorNotifierInterceptor implements HttpInterceptor {
  constructor(
    public dialog: MatDialog,
    readonly maintenanceModeService: MaintenanceModeService,
    private toastService: ToastService
  ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        switch (error.status) {
          case 503:
            this.maintenanceModeService.enterMaintenanceMode();
            break;
          case 404:
            // Skip; We currently use this for certain specific error response
            break;
          default:
            this.toastService.sendMessage(
              error.error?.detail || error.message,
              ToastLevel.WARN
            );
        }
        return throwError(error);
      })
    );
  }
}
