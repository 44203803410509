<div mat-dialog-title>
  <div>Change Email</div>
  <div class="close-icon-container" (click)="onNoClick()">
    <fa-icon [icon]="['far', 'xmark']" class="gray"></fa-icon>
  </div>
</div>
<mat-divider></mat-divider>
<div mat-dialog-content>
  <p>
    Changing your email address will also change your login credentials, and
    will automatically log you out of the system. We will send you an email to
    verify your new address. Next time you log in, enter your updated email.
  </p>
</div>
<div mat-dialog-actions>
  <button mat-stroked-button color="primary" [mat-dialog-close]="false">
    Cancel
  </button>
  <button mat-raised-button color="primary" [mat-dialog-close]="true">
    Change Email
  </button>
</div>
