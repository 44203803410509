import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { ErrorService } from './error.service';

import { ToastLevel, ToastMessage } from '../models/toast-message.module';

@Injectable({
  providedIn: 'root',
})
export class ToastService {
  private messages: ToastMessage[] = [];

  constructor(private errorService: ErrorService) {}

  // autoremove message after set time
  autoRemoveMessage(msgId: number, timeout: number) {
    setTimeout(() => this.removeMessage(msgId), timeout);
  }

  // get the messages to display to the user
  getMessages(): ToastMessage[] {
    return this.messages;
  }

  // remove the message
  removeMessage(msgId: number) {
    const idx = this.messages.findIndex(m => m.timeStamp === msgId);
    this.messages.splice(idx, 1);
  }

  // display a new message to the user
  sendMessage(
    msg: string,
    level: ToastLevel,
    timeout: number = 5000,
    heading?: string
  ) {
    const newMsg = heading
      ? new ToastMessage(msg, level, heading)
      : new ToastMessage(msg, level);

    this.messages.push(newMsg);
    this.autoRemoveMessage(newMsg.timeStamp, timeout);
  }

  // send an http error toast
  sendHttpError(error: HttpErrorResponse) {
    const message = this.errorService.getDisplayMessage(error);
    this.sendMessage(message, ToastLevel.WARN);
  }
}
