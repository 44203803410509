import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';

import { environment } from '../../../environments/environment';
import { Adjustment, AdjustmentType } from '../../shared/models';
import { deserialize, serialize } from '../../shared/models/base-helper';

@Injectable({
  providedIn: 'root',
})
export class AdjustmentService {
  apiUrl = environment.apiUrl + '/adjustments/';
  adjustmentTypesApiUrl = environment.apiUrl + '/adjustment_types/';

  adjustmentTypes: Array<AdjustmentType> = [];

  constructor(readonly http: HttpClient) {}

  createAdjustment(siteId: number, reason: string, adjustment: Adjustment) {
    return this.http
      .post<any[]>(`${this.apiUrl}?site_id=${siteId}`, serialize(adjustment), {
        params: new HttpParams().set('reason', reason),
      })
      .pipe(
        map(adjustments =>
          adjustments.map((adjustment: any) =>
            deserialize(adjustment, Adjustment)
          )
        )
      );
  }

  getAllAdjustmentTypes(): Observable<AdjustmentType[]> {
    return this.http.get<any[]>(this.adjustmentTypesApiUrl).pipe(
      map(adjustmentTypes =>
        adjustmentTypes.map((adjustmentType: any) =>
          deserialize(adjustmentType, AdjustmentType)
        )
      ),
      tap(adjustmentTypes => {
        this.adjustmentTypes = adjustmentTypes;
      })
    );
  }
}
