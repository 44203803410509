import { createAction, props } from '@ngrx/store';

export const loadMapControls = createAction('[MapControl] Load MapControls');

export const toggleMapControlsStandSelector = createAction(
  '[MapControl] Toggle Stand Selector'
);

export const setMapControlDrawMode = createAction(
  '[MapControl Set Draw Mode',
  props<{ active: boolean }>()
);

export const toggleMapControlsEditMode = createAction(
  '[MapControl] Toggle Edit Mode'
);

export const toggleDrawPolygonMode = createAction(
  '[MapControl] Toggle Draw Polygon Mode'
);

export const toggleMapControlsWaterways = createAction(
  '[MapControl] Toggle Waterways Display'
);

export const toggleMapControlsStands = createAction(
  '[MapControl] Toggle Selected Stands Display'
);

export const toggleMapControlsRoads = createAction(
  '[MapControl] Toggle Roads Display'
);

export const toggleMapControlsBaseMap = createAction(
  '[MapControl] Toggle Contour Base Map'
);

export const resetMapControls = createAction('[MapControl] Reset Map Controls');

export const toggleMapControlsForest = createAction(
  '[MapControl] Toggle Species Selector'
);

export const toggleMapControlsPublicLand = createAction(
  '[MapControl] Toggle Public Land Selector'
);

export const toggleMapControlsSuggestedStandsSelector = createAction(
  '[MapControl] Toggle Suggested Stand Selector'
);

export const toggleMapControlsKMLUpload = createAction(
  '[MapControl] Toggle KML Upload Selector'
);

export const setMapControlsForestOff = createAction(
  '[MapControl] Set Species Selector to false'
);

export const setMapControlsDrawModeOff = createAction(
  '[MapControl] Set Draw Mode to false'
);
