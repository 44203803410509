<div class="container">
  <div class="verify-side-panel"></div>
  <div class="main">
    <div class="verify-account">
      <div class="header">Identity Verification</div>
      <div class="verify-info-text">
        We ask for this information to verify your identity and protect you from
        identity fraud. You can reference CORE Carbon's
        <a routerLink="/legal/privacy-policy" target="_blank">Privacy Policy</a>
        if you would like more details.
      </div>
      <form
        (keydown.enter)="$event.preventDefault()"
        [formGroup]="verifyUserForm"
        fcFormUnloadWarning>
        <mat-form-field class="w-100-1">
          <mat-label>First Name</mat-label>
          <input
            formControlName="firstName"
            id="firstName"
            matInput
            required
            type="text" />
        </mat-form-field>
        <mat-form-field class="w-100-1">
          <mat-label>Last Name</mat-label>
          <input
            formControlName="lastName"
            id="lastName"
            matInput
            required
            type="text" />
        </mat-form-field>
        <mat-form-field class="w-50-2">
          <mat-label>Driver's License Number</mat-label>
          <input
            formControlName="licenseNo"
            id="licenseNo"
            matInput
            type="text" />
        </mat-form-field>
        <mat-form-field class="w-50-2">
          <mat-label>Driver's License State</mat-label>
          <mat-select class="state-select" formControlName="licenseState">
            <mat-option *ngFor="let state of stateList" [value]="state.value">
              {{ state.viewValue }}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field class="w-50-2">
          <mat-label>Last 4 Digits of SSN</mat-label>
          <input
            formControlName="last4Ssn"
            id="last4Ssn"
            matInput
            maxlength="4"
            required
            type="text" />
        </mat-form-field>
        <div class="w-50-2">
          <fc-datepicker
            formControlName="dateOfBirth"
            formFieldClass="w-100-1"
            inputID="dateOfBirth"
            label="Date of Birth"></fc-datepicker>
        </div>
        @if (dobInvalidMsg) {
        <div class="error">{{ dobInvalidMsg }}</div>
        }
        <mat-form-field class="w-100-1">
          <mat-label>Address Line 1</mat-label>
          <input
            formControlName="address1"
            id="address1"
            matInput
            required
            type="text" />
        </mat-form-field>
        <mat-form-field class="w-100-1">
          <mat-label>Address Line 2 (Optional)</mat-label>
          <input
            formControlName="address2"
            id="address2"
            matInput
            type="text" />
        </mat-form-field>
        <mat-form-field class="w-100-1">
          <mat-label>City</mat-label>
          <input
            formControlName="city"
            id="city"
            matInput
            required
            type="text" />
        </mat-form-field>
        <mat-form-field class="w-50-2">
          <mat-label>State</mat-label>
          <mat-select class="state-select" formControlName="state" required>
            <mat-option *ngFor="let state of stateList" [value]="state.value">
              {{ state.viewValue }}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field class="w-50-2">
          <mat-label>Zip Code</mat-label>
          <input
            formControlName="zip"
            id="zip"
            matInput
            maxlength="5"
            required
            type="text" />
        </mat-form-field>
        <mat-form-field class="w-50-2">
          <mat-label>Phone #</mat-label>
          <input
            formControlName="phone"
            id="phone"
            matInput
            placeholder="(XXX) XXX-XXXX"
            required
            type="text" />
        </mat-form-field>
        <div class="footer">
          <div class="verify-info-text">
            Asterisks (*) denote required fields.
          </div>
          <div class="button-container">
            <button
              (click)="onCancel()"
              color="primary"
              mat-stroked-button
              type="button">
              Cancel
            </button>
            <button
              (click)="onSubmit()"
              [class.disabled]="!verifyUserForm.valid"
              [disabled]="!verifyUserForm.valid"
              color="primary"
              mat-raised-button>
              Submit
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
