import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from '../../../environments/environment';
import { DisturbanceReport, PeriodicReport, Report } from '../../shared/models';
import { deserialize } from '../../shared/models/base-helper';

@Injectable({
  providedIn: 'root',
})
export class ReportService {
  apiUrl = environment.apiUrl + '/reports/';

  constructor(readonly http: HttpClient) {}

  getReportList(siteId: number): Observable<Report[]> {
    return this.http.get<any[]>(`${this.apiUrl}?site_id=${siteId}`).pipe(
      map(reports => {
        const reportList = reports.map(
          (report: any) =>
            new Report({
              id: report.id,
              createDate: new Date(report.create_date),
              name: report.name,
            })
        );
        const idx = reportList.findIndex(r => r.name === 'Periodic Report');
        if (idx !== -1) {
          reportList[idx].name = 'Eligibility Report';
        }
        return reportList;
      })
    );
  }

  submitPeriodicReport(
    siteId: number,
    periodicReport: any
  ): Observable<PeriodicReport> {
    return this.http
      .post<any>(`${this.apiUrl}periodic?site_id=${siteId}`, periodicReport)
      .pipe(
        map((serializedPeriodicReport: any) => {
          return deserialize(serializedPeriodicReport, PeriodicReport);
        })
      );
  }

  getPeriodicReport(id: number): Observable<PeriodicReport> {
    return this.http
      .get<any>(`${this.apiUrl}periodic/${id}`)
      .pipe(
        map(serializedPeriodicReport =>
          deserialize(serializedPeriodicReport, PeriodicReport)
        )
      );
  }

  submitDisturbanceReport(
    siteId: number,
    disturbanceReport: any
  ): Observable<DisturbanceReport> {
    return this.http
      .post<any>(
        `${this.apiUrl}disturbance?site_id=${siteId}`,
        disturbanceReport
      )
      .pipe(
        map((serializedDisturbanceReport: any) =>
          deserialize(serializedDisturbanceReport, DisturbanceReport)
        )
      );
  }

  getDisturbanceReport(id: number): Observable<DisturbanceReport> {
    return this.http
      .get<any>(`${this.apiUrl}disturbance/${id}`)
      .pipe(
        map(serializedDisturbanceReport =>
          deserialize(serializedDisturbanceReport, DisturbanceReport)
        )
      );
  }
}
