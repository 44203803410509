<div mat-dialog-title class="change-site-state">
  Apply a KML
  <div class="close-icon-container" (click)="onNoClick()">
    <fa-icon [icon]="['far', 'xmark']" class="gray"></fa-icon>
  </div>
</div>
<mat-divider></mat-divider>
<div mat-dialog-content>
  <p>
    You can use a KML/KMZ file to define your site boundaries. Select or upload
    a new one:
  </p>
  <form [formGroup]="kmlDocSelectForm" class="site-form">
    <mat-form-field class="status-select">
      <mat-select
        data-cy="kml-document-select"
        formControlName="kmlDocSelect"
        placeholder="Upload a new KML/KMZ">
        <mat-option data-cy="kml-new-document-option" value="">
          Upload a new KML/KMZ
        </mat-option>
        <mat-option
          *ngFor="let document of documents"
          data-cy="kml-document-option"
          [value]="document.identifier">
          {{ document.name }}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <div
      class="kml-upload-button-container"
      *ngIf="this.kmlDocSelectForm.get('kmlDocSelect').value === ''">
      <ng-container>
        <button
          (click)="onClickKmlUpload()"
          class="button"
          data-cy="kml-upload-button"
          color="primary"
          mat-raised-button
          type="button">
          {{ uploadKmlFileName ? 'Re-' : '' }}Upload
        </button>
      </ng-container>
      <ng-container *ngIf="uploadKmlFileName">
        <p>{{ uploadKmlFileName }}</p>
      </ng-container>
    </div>
    <input
      #kmlFileUpload
      accept=".kml,.kmz"
      id="kmlFileUpload"
      name="kmlFileUpload"
      style="display: none"
      type="file" />
  </form>
</div>
<div mat-dialog-actions>
  <button
    data-cy="button-yes"
    mat-raised-button
    color="primary"
    [disabled]="!isValid()"
    class="green-shadow-button"
    (click)="applyKML()">
    Apply
  </button>
</div>
