import { Component, DestroyRef, inject, Inject, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { finalize } from 'rxjs/operators';

import { SiteService } from '../../../core/api/site.service';
import { SpinnerService } from '../../../shared/spinner/spinner.service';
import SiteStatusConstants from '../../constants/site_status.constants';
import { DialogResubmitNoteComponent } from '../../dialogs/dialog-resubmit-note/dialog-resubmit-note.component';
import { Site } from '../../models';

export interface DialogData {
  sites: Array<Site>;
  statuses: Array<string>;
  buttonTextNo: string;
  buttonTextYes: string;
}

@Component({
  selector: 'fc-yes-no-dialog',
  templateUrl: './dialog-change-site-state.component.html',
  styleUrls: ['./dialog-change-site-state.component.scss'],
})
export class DialogChangeSiteStateComponent implements OnInit {
  destroyRef = inject(DestroyRef);

  changeSiteStateForm: UntypedFormGroup;
  errorMessage = null;

  constructor(
    public dialogRef: MatDialogRef<DialogChangeSiteStateComponent>,
    public extraDialog: MatDialog,
    private formBuilder: UntypedFormBuilder,
    readonly siteService: SiteService,
    readonly spinnerService: SpinnerService,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {
    this.createForm();
    data.statuses.sort(); // make sure the statuses are sorted alphabetically
  }

  createForm() {
    this.changeSiteStateForm = this.formBuilder.group({
      currentState: ['', Validators.required],
      transitionToState: ['', Validators.required],
    });
  }

  ngOnInit() {
    if (
      this.data.sites &&
      this.data.sites.length > 0 &&
      this.data.sites.every((val, i, arr) => val.status === arr[0].status)
    ) {
      this.changeSiteStateForm
        .get('currentState')
        .setValue(this.data.sites[0].status);
      if (
        SiteStatusConstants.sitePendingStatusList.includes(
          this.data.sites[0].status
        )
      ) {
        this.data.statuses.splice(
          this.data.statuses.indexOf(
            SiteStatusConstants.REVIEW_REQUIRED.status
          ),
          1
        );
      }
    }
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  doChangeSitesState() {
    this.errorMessage = null;
    const currentState = this.changeSiteStateForm.get('currentState').value;
    const transitionToState =
      this.changeSiteStateForm.get('transitionToState').value;
    const siteIdList = this.data.sites.map(site => site.id);
    if (
      transitionToState ==
      SiteStatusConstants.REQUIRES_LANDOWNER_REVISION.status
    ) {
      this.extraDialog
        .open(DialogResubmitNoteComponent)
        .afterClosed()
        .subscribe(note => {
          if (note) {
            this.spinnerService.show('app-spinner');
            this.siteService
              .changeSitesState(siteIdList, currentState, transitionToState)
              .pipe(
                finalize(() => this.spinnerService.hide('app-spinner')),
                takeUntilDestroyed(this.destroyRef)
              )
              .subscribe(response => {
                if (response.error) {
                  this.errorMessage = response.error;
                } else {
                  let count = 0;
                  this.data.sites.forEach(site => {
                    this.siteService
                      .sendResubmissionNote(site.id, note)
                      .subscribe(response => {
                        count++;
                        if (count == this.data.sites.length) {
                          this.dialogRef.close(true);
                        }
                      });
                  });
                }
              });
          }
        });
    } else {
      this.siteService
        .changeSitesState(siteIdList, currentState, transitionToState)
        .subscribe(response => {
          if (response.error) {
            this.errorMessage = response.error;
          } else {
            this.dialogRef.close(true);
          }
        });
    }
  }
}
