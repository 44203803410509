import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';

import { FormatHelperService } from '../../../core/helper/format-helper.service';
import { MapEvent } from '../../../shared-map/components/map-layers-pages/map-layers-page.component';
import { Stand } from '../../models';

@Component({
  selector: 'fc-site-stand-item',
  templateUrl: './site-stand-item.component.html',
  styleUrls: ['./site-stand-item.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class SiteStandItemComponent implements AfterViewInit {
  @ViewChild('dataContainer', { static: false }) dataContainer: ElementRef;

  @Input()
  index: number;

  @Input()
  stand: Stand;

  @Input()
  siteYear: number;

  @Input()
  allowRemove: boolean = true;

  @Input()
  clickable: boolean = true;

  @Input()
  cohortId: number = null;

  @Output()
  mapEvent: EventEmitter<MapEvent> = new EventEmitter<MapEvent>();

  constructor(public formatHelper: FormatHelperService) {}

  getValuation() {
    if (this.cohortId == null) {
      return this.stand.priceValuation;
    } else {
      return this.formatHelper.formatCurrency(
        this.stand.pricingData.annual_valuation.find(
          obj => obj.id == this.cohortId
        ).valuation
      );
    }
  }

  ngAfterViewInit(): void {
    this.dataContainer.nativeElement.innerHTML = this.stand.svg;
  }

  deleteStand() {
    if (this.allowRemove) {
      this.mapEvent.emit({ action: 'delete', event: this.stand });
    }
  }

  centerOnStand() {
    this.mapEvent.emit({ action: 'center', event: this.stand });
  }

  get acreage() {
    let acreage = this.stand.area || 0.0;
    return acreage.toLocaleString('us-EN', {
      maximumFractionDigits: 2,
    });
  }
}
