<div
  (click)="navigate()"
  [class]="
    site.status === statusConstants.PENDING.status ? 'reset-cursor' : null
  "
  class="mat-card-container">
  <mat-card
    [ngClass]="{ grayCard: isCardGray === true }"
    appearance="outlined"
    class="site-card">
    <div class="title-container">
      <div class="buyer-logo-container">
        <img
          *ngIf="site.buyerSelected"
          [alt]="site.cohort.buyer.name"
          [src]="getOpenAssetURL(site.cohort.buyer.shortName, 'logo.svg')"
          class="buyer-logo" />
      </div>

      <mat-divider></mat-divider>

      <div class="title-icon">
        <div class="status-icon">
          <ng-container
            *ngIf="site.status === statusConstants.APPROVED.status"
            class="footer">
            <fa-icon [icon]="['fas', 'star']" class="green size-20"></fa-icon>
          </ng-container>
          <ng-container
            *ngIf="site.status === statusConstants.ACTIVE.status"
            class="footer">
            <fa-icon
              [icon]="['fas', 'check-circle']"
              class="green size-20"></fa-icon>
          </ng-container>
          <ng-container
            *ngIf="site.status === statusConstants.REPORTING_REQUIRED.status"
            class="footer">
            <fa-icon
              [icon]="['fas', 'circle-exclamation']"
              class="orange size-20"></fa-icon>
          </ng-container>
          <ng-container
            *ngIf="site.status === statusConstants.DISTURBANCE_REPORTED.status"
            class="footer">
            <fa-icon
              [icon]="['fas', 'circle-exclamation']"
              class="red size-20"></fa-icon>
          </ng-container>
        </div>
        <div class="title-container">
          <div class="title">{{ site.name }}</div>
        </div>
      </div>
    </div>

    <mat-divider></mat-divider>

    <div class="site-status">
      <div>
        <span>Status:</span>
        &nbsp;
        <span [style.color]="statusColorStyle">
          {{ statusOwner }}
        </span>
      </div>
      <div *ngIf="!this.isPendingSite" class="fc-link" click="navigate()">
        <span>Details</span>
        <fa-icon [icon]="['fas', 'chevron-right']" class="blue"></fa-icon>
      </div>
    </div>

    <mat-divider></mat-divider>

    <div class="site-info">
      <div class="info site-number">
        <span class="label">Site #:</span>
        <span>{{ site.id }}</span>
      </div>
      <div class="info"></div>
      <div class="info">
        <span class="label">Acres:</span>
        <span>{{ totalAcreage }}</span>
      </div>
      <div class="info">
        <span class="label">Site Since:</span>
        <span>{{ site.createDate | date: 'shortDate' }}</span>
      </div>
      <div class="info">
        <div class="label-container">
          <span class="label">Projection Through {{ twentyYearMark }}:</span>
          <fa-icon
            #tooltip1="matTooltip"
            (click)="tooltip1.toggle()"
            [fixedWidth]="false"
            [icon]="['far', 'circle-info']"
            [matTooltip]="projectionInfoBox(twentyYearMark)"
            class="blue"
            matTooltipClass="fc-tooltip"
            matTooltipPosition="right"
            title="Is Verified"></fa-icon>
        </div>
        <span class="twenty-year-projection">{{ twentyYearProjection }}</span>
      </div>
      <div class="info">
        <div class="label-container">
          <span class="label">Estimated Annual Revenue:</span>
          <fa-icon
            #tooltip2="matTooltip"
            (click)="tooltip2.toggle()"
            [fixedWidth]="false"
            [icon]="['far', 'circle-info']"
            class="blue"
            matTooltip="Estimated annual revenue is the base payout for the next year of payments. This value can change based on your responses to periodic reporting or on market performance."
            matTooltipClass="fc-tooltip"
            matTooltipPosition="right"
            title="Is Verified"></fa-icon>
        </div>
        <span class="valuation">{{ projectedRevenue }}</span>
      </div>
    </div>

    <mat-divider class="bottom-divider"></mat-divider>

    <div class="footer">
      <div class="button-container">
        <ng-container
          *ngIf="
            site.status === statusConstants.ACTIVE.status ||
            site.status === statusConstants.ELIGIBILITY_PENDING.status ||
            site.status === statusConstants.REPORTING_COMPLETE.status ||
            site.status === statusConstants.REVIEW_REQUIRED.status ||
            site.status === statusConstants.APPROVED.status ||
            site.status === statusConstants.REPORTING_REQUIRED.status ||
            site.status === statusConstants.DISTURBANCE_REPORTED.status
          ">
          <button
            (click)="viewMap(site.id)"
            class="view-map-link"
            color="primary"
            mat-stroked-button>
            View Map
          </button>
        </ng-container>

        <ng-container *ngIf="this.isPendingSite">
          <div class="delete-container">
            <a
              (click)="onDeleteSite($event, site)"
              class="fc-link"
              href="javascript:void(0)">
              Delete
            </a>
          </div>
          <button
            (click)="onOpenSite($event, site.id)"
            class="large"
            color="primary"
            data-cy="continue-site"
            mat-raised-button>
            Continue Site
          </button>
        </ng-container>

        <ng-container
          *ngIf="site.status === statusConstants.REPORTING_REQUIRED.status">
          <button
            (click)="onPeriodicReport(site)"
            class="large"
            color="primary"
            data-cy="button-complete-report"
            mat-raised-button>
            Complete Periodic Report
          </button>
        </ng-container>

        <ng-container
          *ngIf="site.status === statusConstants.DISTURBANCE_REPORTED.status">
          <button
            (click)="onDisturbanceReport(site)"
            class="large"
            color="primary"
            mat-raised-button>
            Report on Disturbance
          </button>
        </ng-container>
      </div>
    </div>
  </mat-card>
</div>
