import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, of, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { environment } from '../../../environments/environment';
import { Account, User } from '../../shared/models';
import { deserialize } from '../../shared/models/base-helper';
import { UserActivity } from '../../shared/models/user-activity.model';
import * as fromApp from '../../store';
import { resetLandOwnerPaymentsAccountRequiresSetup } from '../../store/notification/notification.actions';

interface QueryResponse {
  results: any[];
  total: number;
}

interface ExistsResponse {
  exists: boolean;
}

interface DeserializedResponse {
  results: User[];
  total: number;
}

interface ActivityQueryResponse {
  results: any[];
  total: number;
}

interface DeserializedActivityResponse {
  results: UserActivity[];
  total: number;
}
@Injectable({
  providedIn: 'root',
})
export class UserService {
  apiUrl = environment.apiUrl + '/users/';

  constructor(
    readonly http: HttpClient,
    readonly store: Store<fromApp.AppState>
  ) {}

  isValid(token: string): Observable<string> {
    const headers = new HttpHeaders()
      .set('Authorization', `Bearer ${token}`)
      .set('Content-Type', 'application/json')
      .set('X-FC-Noauth', 'true');

    return this.http.get<any>(`${this.apiUrl}valid`, { headers }).pipe(
      map(resp => resp),
      catchError(error => of(error))
    );
  }

  getMe(): Observable<User> {
    return this.http.get<any>(`${this.apiUrl}me`).pipe(
      map((serializedUser: any) => deserialize(serializedUser, User)),
      catchError(error => of(error))
    );
  }

  getAdmins(): Observable<User[]> {
    return this.http.get<any[]>(`${this.apiUrl}admins`).pipe(
      map((response: any[]) =>
        response.map((serializedUser: any) => deserialize(serializedUser, User))
      ),
      catchError(error => of(error))
    );
  }

  getUsers(): Observable<User[]> {
    return this.http.get<QueryResponse>(this.apiUrl).pipe(
      map(response => {
        const results = [];
        for (const serializedUser of response.results) {
          results.push(deserialize(serializedUser, User));
        }
        return results;
      })
    );
  }

  getUsersForStatDashboard(): Observable<User[]> {
    return this.http.get<QueryResponse>(`${this.apiUrl}stat_dashboard/`).pipe(
      map(response => {
        const results = [];
        for (const serializedUser of response.results) {
          results.push(deserialize(serializedUser, User));
        }
        return results;
      })
    );
  }

  exists(email: string): Observable<boolean> {
    return this.http
      .get<ExistsResponse>(`${this.apiUrl}exists`, {
        params: new HttpParams().set(
          'email',
          encodeURIComponent(email.toLowerCase())
        ),
      })
      .pipe(
        map((response: ExistsResponse) => {
          return response.exists;
        })
      );
  }

  getUser(id: number): Observable<User> {
    return this.http
      .get<any>(`${this.apiUrl}${id}`)
      .pipe(map(serializedUser => deserialize(serializedUser, User)));
  }

  deleteUser(userId: number) {
    return this.http
      .delete<boolean>(`${this.apiUrl}${userId}/`)
      .pipe(map((response: any) => response.success));
  }

  getFilteredUsers(filterString: string): Observable<DeserializedResponse> {
    const filterUrl = this.apiUrl + filterString;

    return this.http.get<QueryResponse>(filterUrl).pipe(
      map(response => {
        const deserializedResponse: DeserializedResponse = {
          results: [],
          total: response.total,
        };
        for (const serializedUser of response.results) {
          deserializedResponse.results.push(deserialize(serializedUser, User));
        }
        return deserializedResponse;
      })
    );
  }

  requestUserOpen(
    email: string,
    preferredBuyerCode?: number
  ): Observable<Account> {
    const lowerEmail = email.toLowerCase();
    const body = {
      email: lowerEmail,
      preferred_buyer_code: preferredBuyerCode,
    };
    return this.http
      .post<any>(`${this.apiUrl}request`, body)
      .pipe(
        map((serializedAccount: any) => deserialize(serializedAccount, Account))
      );
  }

  updateUserProfile(profile: any): Observable<User> {
    return this.http
      .put<any>(`${this.apiUrl}${profile.id}/profile`, profile)
      .pipe(map((serializedUser: any) => deserialize(serializedUser, User)));
  }

  inviteUpdateUser(
    email: string,
    role: string,
    buyers: number[] | number, // user could be a buyer admin for multiple buyers
    isActive: boolean,
    id?: number
  ): Observable<User> {
    const lowerEmail = email.toLowerCase();
    const body = { email: lowerEmail, role, buyers, is_active: isActive, id };
    if (id) {
      return this.http
        .patch<any>(`${this.apiUrl}update_user_permissions/${id}`, body)
        .pipe(map((serializedUser: any) => deserialize(serializedUser, User)));
    } else {
      return this.http
        .post<any>(`${this.apiUrl}invite`, body)
        .pipe(map((serializedUser: any) => deserialize(serializedUser, User)));
    }
  }

  sendPasswordChangeEmail() {
    return this.http
      .post<string>(
        `${this.apiUrl}notify`,
        {},
        { params: new HttpParams().set('subject', 'password-change') }
      )
      .pipe(map((response: string) => response));
  }

  createDelegate(data: any): Observable<User> {
    return this.http
      .post<any>(`${this.apiUrl}delegate`, data)
      .pipe(map((serializedUser: any) => deserialize(serializedUser, User)));
  }

  removeDelegate(): Observable<User> {
    return this.http
      .delete<any>(`${this.apiUrl}delegate`)
      .pipe(map((serializedUser: any) => deserialize(serializedUser, User)));
  }

  setUserRole(userId: number, role: string): Observable<User> {
    return this.http
      .put<any>(`${this.apiUrl}${userId}/role`, { name: role })
      .pipe(map((serializedUser: any) => deserialize(serializedUser, User)));
  }

  verifyUser(
    userId: number,
    verified: boolean,
    clear: boolean
  ): Observable<User> {
    return this.http
      .put<any>(`${this.apiUrl}${userId}/verify`, {
        verified,
        clear,
      })
      .pipe(map((serializedUser: any) => deserialize(serializedUser, User)));
  }

  changeEmailAddress(userId: number, email: string): Observable<User> {
    const lowerEmail = email.toLowerCase();
    return this.http
      .put<any>(`${this.apiUrl}${userId}/email`, {
        email: lowerEmail,
      })
      .pipe(map((serializedUser: any) => deserialize(serializedUser, User)));
  }

  resendInvite(userId: number) {
    return this.http
      .post<string>(`${this.apiUrl}reinvite`, { user_id: userId })
      .pipe(map((response: string) => response));
  }

  adminResetPassword(userId: number): Observable<boolean> {
    return this.http
      .get<boolean>(`${this.apiUrl}reset_password?user_id=${userId}`)
      .pipe(
        map(() => true),
        catchError((err: any, caught: Observable<boolean>) => of(false))
      );
  }

  getStripeAccountCreationToken(
    userId: number,
    buyerId: number
  ): Observable<any> {
    return this.http
      .put<any>(`${this.apiUrl}${userId}/stripe-token?buyer_id=${buyerId}`, {})
      .pipe(
        map((result: any) => ({
          token: result['stripe_account_creation_token'],
          clientID: result['stripe_client_id'],
        }))
      );
  }

  doStripeConnect(userId, buyerId, buyerName, userEmail) {
    this.getStripeAccountCreationToken(userId, buyerId).subscribe(
      (result: any) => {
        this.store.dispatch(resetLandOwnerPaymentsAccountRequiresSetup());
        window.location.href =
          environment.payment.stripe_connect_url +
          '?client_id=' +
          result.clientID +
          '&state=' +
          result.token +
          '&suggested_capabilities[]=transfers&suggested_capabilities[]=tax_reporting_us_1099_misc&stripe_user[email]=' +
          encodeURIComponent(userEmail) +
          '&stripe_user[country]=US' +
          '&redirect_uri=' +
          window.location.protocol +
          '//' +
          window.location.host +
          '/account/profile' +
          '&stripe_user[product_description]=Accepting Payments from ' +
          buyerName +
          ' for carbon offsets';
      },
      catchError(err => {
        console.log('caught stripe token creation error and rethrowing', err);
        return throwError(err);
      })
    );
  }

  allowReset(email: string) {
    return this.http
      .get<boolean>(`${this.apiUrl}can_reset`, {
        params: new HttpParams().set(
          'email',
          encodeURIComponent(email.toLowerCase())
        ),
      })
      .pipe(map((response: any) => response.allow_reset));
  }

  getUserActivity(
    filterString: string
  ): Observable<DeserializedActivityResponse> {
    return this.http
      .get<ActivityQueryResponse>(`${this.apiUrl}activity/` + filterString)
      .pipe(
        map(response => {
          const deserializedResponse: DeserializedActivityResponse = {
            results: [],
            total: response.total,
          };
          for (const activity of response.results) {
            deserializedResponse.results.push(
              deserialize(activity, UserActivity)
            );
          }
          return deserializedResponse;
        })
      );
  }
}
