import {
  Component,
  DestroyRef,
  HostListener,
  inject,
  OnInit,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { filter, take } from 'rxjs/operators';

import { environment } from 'src/environments/environment';

import { NotificationService } from './core/api/notification.service';
import { UserService } from './core/api/user.service';
import { User } from './shared/models';
import {
  getAppConfig,
  mobileViewMaxScreenWidth,
  setMobileViewMode,
} from './store/app-config/app-config.actions';
import { selectAppConfigState } from './store/app-config/app-config.selectors';
import { login, setActAsUser } from './store/auth/auth.actions';
import { AuthState } from './store/auth/auth.reducer';
import { selectAuthState } from './store/auth/auth.selectors';
import { getAllDocumentTypes } from './store/document/document.actions';
import * as fromApp from './store/index';

declare let gtag: Function;

@Component({
  selector: 'fc-root',
  styleUrls: ['./app.component.scss'],
  templateUrl: `./app.component.html`,
})
export class AppComponent implements OnInit {
  destroyRef = inject(DestroyRef);

  constructor(
    readonly store: Store<fromApp.AppState>,
    readonly route: ActivatedRoute,
    readonly router: Router,
    readonly userService: UserService,
    readonly notificationService: NotificationService
  ) {
    // Add Google Analytics to index.html
    if ('googleAnalyticsID' in environment) {
      const gaScript = document.createElement('script');
      gaScript.async = true;
      gaScript.src =
        'https://www.googletagmanager.com/gtag/js?id=' +
        environment.googleAnalyticsID;
      document.head.prepend(gaScript);
    }
    this.onResize(null);

    this.store.dispatch(getAppConfig());
    this.store
      .select(selectAppConfigState)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(appConfig => {
        if (
          typeof appConfig.config['show_zendesk_widget'] !== 'undefined'
            ? appConfig.config['show_zendesk_widget'].toLowerCase() === 'true'
            : false
        ) {
          const zdScript = document.createElement('script');
          zdScript.async = true;
          zdScript.id = 'ze-snippet';
          zdScript.src = `https://static.zdassets.com/ekr/snippet.js?key=${environment.zendeskKey}`;
          document.head.prepend(zdScript);
        }
      });

    // if we're leaving a saved site map clear the preferred buyer
    // this.router.events
    //   .pipe(filter((evt: any) => evt instanceof RoutesRecognized), pairwise(), takeUntilDestroyed(this.destroyRef))
    //   .subscribe((events: RoutesRecognized[]) => {
    //     if (events[0].url.match(/\/site\/\d*\/map-site/) &&
    //       events[1].url !== '/site/locate-property/select-parcels') {
    //       this.store.dispatch(clearPreferredBuyer());
    //     }
    //   });

    this.router.events
      .pipe(
        filter((evt: any) => evt instanceof NavigationEnd),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe((event: NavigationEnd) => {
        if ('googleAnalyticsID' in environment) {
          gtag('config', environment.googleAnalyticsID, {
            page_path: event.urlAfterRedirects,
          });
        }
      });
  }

  ngOnInit() {
    this.store
      .select(selectAuthState)
      .pipe(take(1))
      .subscribe((authState: AuthState) => {
        if (authState.token) {
          const initialPath = window.location.pathname + window.location.search;
          this.store.dispatch(
            login({
              data: {
                idToken: authState.token,
                tokenExp: authState.exp,
                returnUrl: initialPath,
              },
            })
          );
          if (authState.actAsUser) {
            this.store.dispatch(
              setActAsUser({ data: new User(authState.actAsUser) })
            );
          }
        }
      });

    this.store.dispatch(getAllDocumentTypes());
    this.notificationService.initNotificationChecks();
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.store.dispatch(
      setMobileViewMode({
        mobileViewMode: window.innerWidth <= mobileViewMaxScreenWidth,
      })
    );
  }
}
