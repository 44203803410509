import { createFeatureSelector, createSelector } from '@ngrx/store';

import * as fromAppConfig from './app-config.reducer';

export const selectAppConfigState =
  createFeatureSelector<fromAppConfig.AppConfigState>(
    fromAppConfig.appConfigFeatureKey
  );

export const selectIsMobileView = createSelector(
  selectAppConfigState,
  state => state.mobileViewMode
);
