import { createFeatureSelector, createSelector } from '@ngrx/store';

import * as fromParcel from './parcel.reducer';

import { selectAuthState } from '../auth/auth.selectors';
import { selectActiveSite } from '../site/site.selectors';

export const selectParcelState = createFeatureSelector<fromParcel.ParcelState>(
  fromParcel.parcelFeatureKey
);

export const selectSelectedParcels = createSelector(
  selectParcelState,
  parcel => parcel.selectedParcels
);

export const selectSelectedStands = createSelector(
  selectParcelState,
  stand => stand.selectedStands
);

export const selectForestryKml = createSelector(
  selectParcelState,
  forestryKml => forestryKml.forestryKml
);

export const selectAccountParcelsStandsProgramsAndRegion = createSelector(
  selectAuthState,
  selectParcelState,
  selectActiveSite,
  (auth, parcel, activeSite) => ({
    account: auth.currentAccount,
    parcels: parcel.selectedParcels,
    stands: parcel.selectedStands,
    region: parcel.selectedRegion,
    mapCenter: parcel.mapCenter,
    activeSite,
  })
);

export const selectSelectedRegion = createSelector(
  selectParcelState,
  cohort => cohort.selectedRegion
);

export const selectEmail = createSelector(
  selectParcelState,
  email => email.email
);

export const selectLocationType = createSelector(
  selectParcelState,
  locationType => locationType.locationType
);

export const hasUnsavedChanges = createSelector(
  selectParcelState,
  unsavedChanges => unsavedChanges.unsavedChanges
);

export const selectParcelsLocked = createSelector(
  selectParcelState,
  parcelSelectLock => parcelSelectLock.parcelSelectLock
);
