import { Injectable } from '@angular/core';
import * as turf from '@turf/turf';
import * as mapboxgl from 'mapbox-gl';

import { MapControlState } from '../../store/map-control/map-control.reducer';

@Injectable({
  providedIn: 'root',
})
export class MapService {
  currentMap = null;
  // Toggle Roads
  layers = [
    'road-street-case',
    'road-street-low',
    'road-minor-case',
    'road-minor-low',
    'road-secondary-tertiary-case',
    'road-motorway-trunk-case',
    'road-major-link-case',
    'road-primary-case',
    'road-major-link',
    'road-minor',
    'road-street',
    'road-secondary-tertiary',
    'road-primary',
    'road-motorway-trunk',
    'road-label',
    'road-label-outdoors',
    'road-number-shield',
    'road-exit-shield',
  ];

  toggleLayer(map: mapboxgl.Map, layer: string, boolCheck: boolean) {
    if (map.getLayer(layer)) {
      map.setLayoutProperty(
        layer,
        'visibility',
        boolCheck ? 'visible' : 'none'
      );
    }
  }
  setRoadLayerVisibility(map: mapboxgl.Map, mapControls: MapControlState) {
    this.layers.forEach(layer =>
      this.toggleLayer(map, layer, mapControls.displayRoads)
    );
  }

  flyToBoundingBox(feature: any) {
    if (this.currentMap) {
      const boundingBox = turf.bbox({
        type: 'FeatureCollection',
        features: [feature],
      }) as any;
      this.currentMap.fitBounds(boundingBox, {
        padding: 200,
        animate: true,
      });
    }
  }
}
