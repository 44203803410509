<div class="background-container">
  <div class="outer-container">
    <ng-container *ngIf="site">
      <div *ngIf="!isAdmin; else forAdmin" class="page-heading-container">
        <div class="page-name-row">
          Carbon Offset Site Report
          <span *ngIf="beginningDate && endDate">
            {{ beginningDate | date: 'shortDate' }} -
            {{ endDate | date: 'shortDate' }}
          </span>
        </div>
        <mat-divider></mat-divider>
        <div *ngIf="!readOnly" class="page-description">
          Please take a moment to fill out the questions to keep us informed on
          the status of your Carbon Offset Site. Some answers may require some
          additional description.
        </div>
      </div>
      <ng-template #forAdmin>
        <div class="page-heading-buttons">
          <button (click)="onChangeState()" color="primary" mat-raised-button>
            Change Site Status
          </button>
        </div>
        <div
          [ngStyle]="{ 'flex-direction': 'row', 'align-items': 'center' }"
          class="page-heading-container">
          <div class="page-name-row">
            Carbon Site Report
            <span *ngIf="beginningDate && endDate">
              {{ beginningDate | date: 'shortDate' }} -
              {{ endDate | date: 'shortDate' }}
            </span>
          </div>
          <div class="page-status-row">
            <span>Status:</span>
            {{ site.status }}
          </div>
        </div>
        <mat-divider></mat-divider>
      </ng-template>
      <div class="inner-container">
        <div class="site-name">{{ site.name }}</div>
        <mat-divider>&nbsp;</mat-divider>
        <div class="site-overview">
          <div class="overview-title">Site Overview</div>
          <div class="overview-data">
            <div class="site-name">
              Site Name:
              <span>{{ site.name }}</span>
            </div>
            <div>
              Owner:
              <span>{{ getOwnerName() }}</span>
            </div>
            <div>
              Submitted Date:
              <span>{{ site.submittedDate | date: 'shortDate' }}</span>
            </div>
            <div>
              Approved Date:
              <span>{{ site.approvedDate | date: 'shortDate' }}</span>
            </div>
            <div>
              Parcel Number:
              <span>{{ parcelCommaSeparatedList }}</span>
            </div>
            <div>
              Forested Acres:
              <span>{{ getArea() }}</span>
            </div>
            <div>
              Stands:
              <span>{{ getStandCount() }}</span>
            </div>
            <div>
              Site #:
              <span>{{ site.id }}</span>
            </div>
            <div>
              Cohort:
              <span>{{ site.cohort.name }}</span>
            </div>
            <div class="projected-payment">
              <div class="payment-label">Estimated Annual Revenue:</div>
              <span>{{ projectedNext12MonthRevenue }}</span>
              <fa-icon
                #tooltip1="matTooltip"
                (click)="tooltip1.toggle()"
                [icon]="['far', 'circle-info']"
                class="blue"
                matTooltip="Estimated annual revenue is the base payout for the next year of payments. This value can change based on your responses to periodic reporting or on market performance."
                matTooltipClass="fc-tooltip"
                matTooltipPosition="right"></fa-icon>
            </div>
            <div *ngIf="submittedBy">
              Report Submitted By:
              <span>
                {{ submittedBy.firstName + ' ' + submittedBy.lastName }}
              </span>
            </div>
          </div>
        </div>
        <mat-divider>&nbsp;</mat-divider>
        <div class="full-form" [ngClass]="readOnly ? 'readonly' : ''">
          <form [formGroup]="reportForm" fcFormUnloadWarning>
            <div class="annual-question-heading">
              <div class="site-name">General Eligibility</div>
              <div class="overview-title">
                These questions are to confirm you are still in compliance with
                the contract and program as agreed.
              </div>
              <mat-divider>&nbsp;</mat-divider>
            </div>
            <div class="question-list">
              <div>1.</div>
              <div class="question-body">
                <div>
                  Do you still own the land for this carbon offset site?
                </div>
                <mat-radio-group formControlName="stillOwnLand">
                  <mat-radio-button [value]="true">Yes</mat-radio-button>
                  <mat-radio-button [value]="false">No</mat-radio-button>
                </mat-radio-group>
              </div>
              <div>2.</div>
              <div class="question-body">
                <div>
                  Are you in compliance with the contract signed for this carbon
                  offset site, including all commercial harvesting restrictions?
                </div>
                <mat-radio-group formControlName="inComplianceContract">
                  <mat-radio-button [value]="true">Yes</mat-radio-button>
                  <mat-radio-button [value]="false">No</mat-radio-button>
                </mat-radio-group>
                <div
                  *ngIf="reportForm.get('inComplianceContract').value === 'No'"
                  class="desc-area">
                  <div>
                    Please describe the way(s) you are not in compliance with
                    your contract.
                  </div>
                  <textarea
                    formControlName="waysNotInCompliance"
                    rows="4"></textarea>
                </div>
              </div>
              <div>3.</div>
              <div class="question-body">
                <div>
                  Are you in compliance with all local, state and federal laws
                  that apply to the management and usage of this property?
                </div>
                <mat-radio-group formControlName="inComplianceLaw">
                  <mat-radio-button [value]="true">Yes</mat-radio-button>
                  <mat-radio-button [value]="false">No</mat-radio-button>
                </mat-radio-group>
              </div>
            </div>
            <div class="annual-question-heading">
              <div class="site-name">Site Eligibility</div>
              <div class="overview-title">
                These questions are specifically in regards to the period
                between {{ beginningDate | date: 'shortDate' }} and
                {{ endDate | date: 'shortDate' }}.
              </div>
              <mat-divider>&nbsp;</mat-divider>
            </div>
            <div class="question-list">
              <div>4.</div>
              <div class="question-body">
                <div>
                  Have there been any disturbances, of any size, either
                  intentional or unintentional for this carbon offset site?
                  <fa-icon
                    #tooltip2="matTooltip"
                    (click)="tooltip2.toggle()"
                    [icon]="['far', 'circle-info']"
                    class="blue"
                    matTooltip="A disturbance is an event that causes a change in structure and composition of a forest. Unintentional disturbances are generally of natural causes and include wildfire, wind, storm, insects, landslide, earthquake, drought, freezing/ice storm and volcanic eruption. Intentional disturbances are generally caused by human activity and include harvesting, arson and livestock."
                    matTooltipClass="fc-tooltip"
                    matTooltipPosition="right"></fa-icon>
                </div>
                <mat-radio-group formControlName="anyDisturbances">
                  <mat-radio-button [value]="true">Yes</mat-radio-button>
                  <mat-radio-button [value]="false">No</mat-radio-button>
                </mat-radio-group>
                <div
                  *ngIf="reportForm.get('anyDisturbances').value"
                  class="desc-area">
                  <div>
                    (Required) Please describe the disturbance. If possible cite
                    specific stands or parts of your property where the
                    disturbance took place.
                  </div>
                  <textarea
                    formControlName="disturbanceDesc"
                    rows="4"></textarea>
                </div>
              </div>
              <div>5.</div>
              <div class="question-body">
                <div>
                  Do you own forests outside of the selected stands in your
                  carbon offset site, and if so, have you conducted timber
                  harvests in those areas that total 40 acres or more in a
                  single stand or group of stands?
                </div>
                <mat-radio-group formControlName="activityLeakage">
                  <mat-radio-button [value]="true">Yes</mat-radio-button>
                  <mat-radio-button [value]="false">No</mat-radio-button>
                </mat-radio-group>
                <div *ngIf="hasActivityLeakage" class="leakage-upload">
                  <div>
                    Since you conducted timber harvests in forest stands outside
                    of your carbon offset site boundaries that total 40 acres or
                    more in a single stand or group of stands in the past
                    reporting period, you are required to provide proof of one
                    of the following:
                  </div>
                  <ul>
                    <li>
                      Entity wide forest certification (e.g. FSC, SFI, or ATFS)
                    </li>
                    <li>
                      Adherence to a long-term forest management plan across all
                      forestlands in ownership, subject to review and approval
                      prior to acceptance. Please provide a copy of this
                      management plan.
                      <fa-icon
                        #tooltip3="matTooltip"
                        (click)="tooltip3.toggle()"
                        [icon]="['far', 'circle-info']"
                        class="blue"
                        matTooltip="This option requires adherence to a long-term forest management plan or program incorporating all your forested landholdings, prescribing principals of sustained yield and natural forest management (plan and program criteria subject to ACR approval). If you plan to use this option, the plan needs to be pre-approved before harvests commence to ensure it satisfies all criteria and landowner remains eligible for payment."
                        matTooltipClass="fc-tooltip"
                        matTooltipPosition="right"></fa-icon>
                    </li>
                    <li>
                      Harvest Inspection Report prepared by a Qualified Forestry
                      Professional stating that the harvest was conducted
                      following silvicultural norms for the region and forest
                      type.
                      <a
                        href="{{ leakageFileURL }}"
                        target="_blank"
                        rel="noopener noreferrer">
                        Click here to download a template for this report.
                      </a>
                    </li>
                  </ul>
                  <div *ngIf="!isAdmin">
                    <div class="title">Uploaded Documents (Required)</div>
                    <mat-divider [style]="'margin: 15px 0'"></mat-divider>
                    <div
                      (click)="selectDocument()"
                      *ngIf="docFiles.length === 0; else hasDocs"
                      class="fc-link doc-select">
                      <fa-icon
                        [icon]="['far', 'paperclip']"
                        class="blue"></fa-icon>
                      Attach Document
                    </div>
                    <ng-template #hasDocs>
                      <div *ngFor="let docFile of docFiles" class="doc-file">
                        <div>
                          <fa-icon
                            [icon]="['fal', 'file']"
                            class="gray"></fa-icon>
                          <div>{{ docFile.name }}</div>
                        </div>
                        <fa-icon
                          (click)="removeDocument($event, docFile.key)"
                          [icon]="['far', 'trash']"
                          class="gray"></fa-icon>
                      </div>
                    </ng-template>
                    <mat-divider [style]="'margin: 15px 0'"></mat-divider>
                    <div
                      (click)="selectDocument()"
                      *ngIf="docFiles.length > 0"
                      class="fc-link add-document-link">
                      + Add Another Document
                    </div>
                  </div>
                  <div>Additional Details (optional)</div>
                  <textarea
                    formControlName="leakageInfo"
                    id="leakageInfo"
                    rows="5"></textarea>
                </div>
              </div>
              <div>6.</div>
              <div class="question-body">
                <div>
                  This site required cutting for purposes of stopping pest or
                  disease outbreaks, or other salvage cutting and preventive
                  silvicultural activities.
                </div>
                <mat-radio-group formControlName="salvageCut">
                  <mat-radio-button [value]="true">Yes</mat-radio-button>
                  <mat-radio-button [value]="false">No</mat-radio-button>
                </mat-radio-group>
              </div>
              <div>7.</div>
              <div class="question-body">
                <div>
                  I have drained or flooded any wetlands in the site area.
                </div>
                <mat-radio-group formControlName="hasDrained">
                  <mat-radio-button [value]="true">Yes</mat-radio-button>
                  <mat-radio-button [value]="false">No</mat-radio-button>
                </mat-radio-group>
              </div>
            </div>
            <div class="annual-question-heading">
              <div class="site-name">Annual Questions</div>
              <div class="overview-title">
                These questions are only asked on a yearly basis.
              </div>
              <mat-divider>&nbsp;</mat-divider>
            </div>
            <div
              class="question-list"
              [ngClass]="firewoodQuestionDisabled ? 'readonly' : ''">
              <div>8.</div>
              <div class="question-body">
                <div>
                  In the coming year, I want to cut up to 5 cords of dead or
                  dying trees for firewood use from areas enrolled in CORE
                  Carbon. I agree that I will not sell the removed timber and
                  that my annual payment will be adjusted for the next year for
                  this activity.
                  <fa-icon
                    #tooltip5="matTooltip"
                    (click)="tooltip5.toggle()"
                    [icon]="['far', 'circle-info']"
                    class="blue"
                    matTooltip="A cord is defined as 128 cubic feet of wood. This measures approximately 4' high x 8' long x 4' deep."
                    matTooltipClass="fc-tooltip"
                    matTooltipPosition="right"></fa-icon>
                </div>
                <mat-radio-group
                  data-cy="radio-firewoodCutting"
                  class="no-error-box"
                  (change)="onAdjustmentChanged($event, 'firewoodCutting')"
                  formControlName="firewoodCutting">
                  <mat-radio-button [value]="true">Yes</mat-radio-button>
                  <mat-radio-button [value]="false">No</mat-radio-button>
                </mat-radio-group>
                <mat-hint *ngIf="firewoodQuestionDisabled">
                  This question is not applicable for this reporting period.
                </mat-hint>
              </div>
            </div>
            <div
              class="question-list"
              [ngClass]="clearingQuestionDisabled ? 'readonly' : ''">
              <div>9.</div>
              <div class="question-body">
                <div>
                  In the coming year, I want to clear up to
                  {{ allowedClearing }} acres of my enrolled areas. I agree that
                  I will not sell the removed timber and that my annual payment
                  will be adjusted permanently for this activity.
                  <fa-icon
                    #tooltip4="matTooltip"
                    (click)="tooltip4.toggle()"
                    [icon]="['far', 'circle-info']"
                    class="blue"
                    matTooltip="This applies to clearing trees for uses such as food plots, hunt stands, borrow pits, turnarounds, short spur roads, small pasture areas or small cabin sites."
                    matTooltipClass="fc-tooltip"
                    matTooltipPosition="right"></fa-icon>
                </div>
                <mat-radio-group
                  data-cy="radio-isSmallClearing"
                  class="no-error-box"
                  (change)="onAdjustmentChanged($event, 'isSmallClearing')"
                  formControlName="isSmallClearing">
                  <mat-radio-button [value]="true">Yes</mat-radio-button>
                  <mat-radio-button [value]="false">No</mat-radio-button>
                  <mat-radio-button
                    [value]=""
                    *ngIf="
                      (site.hasSmallClearing && reportId === null) ||
                      showPreviousOption
                    ">
                    Previously Selected
                  </mat-radio-button>
                </mat-radio-group>
                <mat-hint *ngIf="site.hasSmallClearing && reportId === null">
                  This is a one-time opt-in. No further response is requested.
                </mat-hint>
                <mat-hint
                  *ngIf="!site.hasSmallClearing && clearingQuestionDisabled">
                  This question is not applicable for this reporting period.
                </mat-hint>
              </div>
            </div>

            <div class="question-list">
              <div></div>
              <div class="question-body">
                <div>
                  Are there any additional considerations or changes we should
                  know about this site? (Optional)
                </div>
                <textarea
                  class="feedback-area"
                  formControlName="feedback"
                  id="feedback"
                  rows="4"></textarea>
              </div>

              <div>&nbsp;</div>
              <div *ngIf="!readOnly" class="question-body">
                <mat-checkbox
                  class="legalAck"
                  id="legalAck"
                  data-cy="legal-ack"
                  formControlName="legalAck">
                  {{ LegalConstants.QUESTION_DISCLAIMER }}
                </mat-checkbox>
              </div>
              <div>&nbsp;</div>
              <div *ngIf="!readOnly" class="question-body buttons">
                <button
                  (click)="submitReport()"
                  [disabled]="submitButtonDisabled()"
                  color="primary"
                  data-cy="submit-periodic-report-button"
                  mat-raised-button>
                  Submit Report
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </ng-container>
  </div>
  <input
    #documentUpload
    accept="*"
    id="documentUpload"
    name="documentUpload"
    style="display: none"
    type="file" />
</div>
