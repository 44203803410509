import { Base } from './base.model';
import { User } from './user.model';

import { Type } from '../decorators/type.decorator';

export class SiteReviewCheck extends Base {
  public checked?: boolean;
  public type?: string;
  public siteId?: number;
  public checkedAt?: string;
  @Type(User) public checkedBy?: User;
}
