import { Action, createReducer, on } from '@ngrx/store';

import * as MapControlActions from './map-control.actions';

export const mapControlFeatureKey = 'mapControl';

export enum MapDisplayTypeEnum {
  CONTOUR = 'CONTOUR',
  SATELLITE = 'SATELLITE',
}

export interface MapControlState {
  standSelector: boolean;
  editMode: boolean;
  drawPolygonMode: boolean;
  displayForest: boolean;
  suggestedStandsSelector: boolean;
  displayWaterways: boolean;
  displayStands: boolean;
  displayRoads: boolean;
  displayPublicLand: boolean;
  displayKMLUpload: boolean;
  displayContour: MapDisplayTypeEnum;
}

export const initialState: MapControlState = {
  standSelector: false,
  editMode: false,
  drawPolygonMode: true,
  displayForest: true,
  suggestedStandsSelector: false,
  displayWaterways: true,
  displayStands: true,
  displayRoads: true,
  displayPublicLand: false,
  displayKMLUpload: null,
  displayContour: MapDisplayTypeEnum.SATELLITE,
};

const mapControlReducer = createReducer(
  initialState,

  on(MapControlActions.loadMapControls, state => state),
  on(MapControlActions.toggleMapControlsStandSelector, state => ({
    ...state,
    standSelector: !state.standSelector,
    editMode: false,
    drawPolygonMode: false,
    displayForest: !state.standSelector ? true : state.displayForest,
    suggestedStandsSelector: !state.standSelector
      ? true
      : state.suggestedStandsSelector,
  })),
  on(MapControlActions.setMapControlDrawMode, (state, selector) => ({
    ...state,
    drawPolygonMode: selector.active,
  })),
  on(MapControlActions.toggleMapControlsEditMode, state => ({
    ...state,
    standSelector: false,
    editMode: !state.editMode,
    drawPolygonMode: false,
  })),
  on(MapControlActions.toggleDrawPolygonMode, state => ({
    ...state,
    standSelector: false,
    editMode: false,
    drawPolygonMode: !state.drawPolygonMode,
  })),
  on(MapControlActions.toggleMapControlsForest, state => ({
    ...state,
    standSelector: state.displayForest ? false : state.standSelector,
    suggestedStandsSelector: state.displayForest
      ? false
      : state.suggestedStandsSelector,
    displayForest: !state.displayForest,
  })),
  on(MapControlActions.toggleMapControlsSuggestedStandsSelector, state => ({
    ...state,
    standSelector: state.suggestedStandsSelector ? false : state.standSelector,
    displayForest: !state.suggestedStandsSelector ? true : state.displayForest,
    suggestedStandsSelector: !state.suggestedStandsSelector,
  })),
  on(MapControlActions.toggleMapControlsWaterways, state => ({
    ...state,
    displayWaterways: !state.displayWaterways,
  })),
  on(MapControlActions.toggleMapControlsStands, state => ({
    ...state,
    displayStands: !state.displayStands,
  })),
  on(MapControlActions.toggleMapControlsRoads, state => ({
    ...state,
    displayRoads: !state.displayRoads,
  })),
  on(MapControlActions.toggleMapControlsPublicLand, state => ({
    ...state,
    displayPublicLand: !state.displayPublicLand,
  })),
  on(MapControlActions.toggleMapControlsKMLUpload, state => ({
    ...state,
    displayKMLUpload: !state.displayKMLUpload,
  })),
  on(MapControlActions.toggleMapControlsBaseMap, state => ({
    ...state,
    displayContour:
      state.displayContour === MapDisplayTypeEnum.SATELLITE
        ? MapDisplayTypeEnum.CONTOUR
        : MapDisplayTypeEnum.SATELLITE,
  })),
  on(MapControlActions.resetMapControls, () => ({
    ...initialState,
  })),
  on(MapControlActions.setMapControlsForestOff, state => ({
    ...state,
    displayForest: false,
  })),
  on(MapControlActions.setMapControlsDrawModeOff, state => ({
    ...state,
    drawPolygonMode: false,
  }))
);

export function reducer(state: MapControlState | undefined, action: Action) {
  return mapControlReducer(state, action);
}
