import { Component, DestroyRef, inject, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import {
  FormControl,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Store } from '@ngrx/store';

import { selectActiveSite } from 'src/app/store/site/site.selectors';

import * as fromApp from '../../../store';
import { RegexConstants } from '../../constants/regex.constants';
import { Site } from '../../models';

@Component({
  selector: 'fc-dialog-change-site-name',
  templateUrl: './dialog-change-site-name.component.html',
  styleUrl: './dialog-change-site-name.component.scss',
})
export class DialogChangeSiteNameComponent implements OnInit {
  destroyRef = inject(DestroyRef);
  changeSiteForm: UntypedFormGroup;
  site: Site;

  constructor(
    readonly store: Store<fromApp.AppState>,
    public dialogRef: MatDialogRef<DialogChangeSiteNameComponent>,
    public dialog: MatDialog,
    private formBuilder: UntypedFormBuilder
  ) {
    this.store
      .select(selectActiveSite)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(site => {
        this.site = site;
      });
  }

  ngOnInit(): void {
    this.changeSiteForm = this.formBuilder.group({
      newName: new FormControl('', [
        Validators.required,
        Validators.maxLength(50),
        Validators.pattern(RegexConstants.SITE_NAME),
      ]),
    });
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  onSubmit() {
    this.dialogRef.close({
      ...this.site,
      name: this.changeSiteForm.value.newName,
    });
  }
}
