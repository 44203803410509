import { Component, DestroyRef, inject, Inject, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { finalize } from 'rxjs/operators';

import { PremiumAcreageUserService } from '../../../core/api/premium-acreage-user.service';
import * as fromApp from '../../../store';
import { selectAppConfigState } from '../../../store/app-config/app-config.selectors';
import { clearPreferredBuyer } from '../../../store/buyer/buyer.actions';
import { RegexConstants } from '../../constants/regex.constants';
import { SpinnerService } from '../../spinner/spinner.service';

@Component({
  selector: 'fc-dialog-premium-acreage',
  templateUrl: './dialog-premium-acreage.component.html',
  styleUrls: ['./dialog-premium-acreage.component.scss'],
})
export class DialogPremiumAcreageComponent implements OnInit {
  destroyRef = inject(DestroyRef);
  contactEmail: string = '';
  signupForm: UntypedFormGroup;
  state: string = '';
  county: string = '';
  referrerCode: number = null;
  premiumAcreageUserCreate: boolean = false;

  constructor(
    readonly premiumAcreageUserService: PremiumAcreageUserService,
    public dialogRef: MatDialogRef<DialogPremiumAcreageComponent>,
    private formBuilder: UntypedFormBuilder,
    readonly spinnerService: SpinnerService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    readonly store: Store<fromApp.AppState>
  ) {
    this.signupForm = this.formBuilder.group({
      email: [
        data.email,
        [
          Validators.maxLength(254),
          Validators.pattern(RegexConstants.EMAIL),
          Validators.required,
        ],
      ],
    });
    this.state = data.state;
    this.county = data.county;
    this.referrerCode = data.referrerCode;
  }

  ngOnInit() {
    this.store
      .select(selectAppConfigState)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(appConfig => {
        this.contactEmail = appConfig.config['fc_contact_email'];
      });
  }

  onYesClick(): void {
    if (this.premiumAcreageUserCreate) {
      this.dialogRef.close(false);
    } else {
      const email = this.signupForm.get('email').value;
      const premiumAcreageUser = {
        email,
        state: this.state,
        county: this.county,
        referrer_code: this.referrerCode,
      };
      this.spinnerService.show('app-spinner');
      this.premiumAcreageUserService
        .createPremiumAcreageUser(premiumAcreageUser)
        .pipe(finalize(() => this.spinnerService.hide('app-spinner')))
        .subscribe(
          () => this.store.dispatch(clearPreferredBuyer()),
          () => console.log('ERROR creating ineligible area user'),
          () => {}
        );
      this.premiumAcreageUserCreate = true;
    }
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
}
