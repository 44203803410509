import { DestroyRef, inject, Injectable } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';

import { User } from '../../shared/models';
import * as fromApp from '../../store';
import { selectCurrentUser } from '../../store/auth/auth.selectors';

@Injectable({
  providedIn: 'root',
})
export class RedirectService {
  destroyRef = inject(DestroyRef);
  currentUser: User;
  constructor(
    readonly router: Router,
    readonly store: Store<fromApp.AppState>
  ) {
    this.store
      .select(selectCurrentUser)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(currentUser => {
        this.currentUser = currentUser;
      });
  }

  redirectToDashboard() {
    if (!this.currentUser) {
      this.router.navigate(['/']);
    } else if (this.currentUser.isAdmin || this.currentUser.isLimitedAdmin) {
      this.router.navigate(['/admin/dashboard']);
    } else {
      this.router.navigate(['/account/dashboard']);
    }
  }

  redirectTo(url: any) {
    this.router.navigate(url);
  }
}
