<div mat-dialog-title>
  <div>
    Sign up for {{ data.hasStripeAccounts ? 'Another' : 'a' }} Payment Account
  </div>
  <div class="close-icon-container" (click)="onNoClick()">
    <fa-icon [icon]="['far', 'xmark']" class="gray"></fa-icon>
  </div>
</div>
<div mat-dialog-content>
  <mat-divider></mat-divider>
  <p *ngIf="!data.isSelf">
    Your managed landowner's carbon site has been approved. They need to sign up
    for Stripe in their profile to begin receiving payments.
  </p>
  <p *ngIf="data.isSelf && data.hasStripeAccounts">
    Your carbon site has been approved. Since you selected a different buyer
    than you have in the past, you need a separate payment account.
  </p>
  <p *ngIf="data.isSelf && !data.hasStripeAccounts">
    Your carbon site has been approved. Please sign up for Stripe in your
    profile to begin receiving payments.
  </p>
</div>
<div mat-dialog-actions>
  <button
    *ngIf="data.isSelf"
    data-cy="button-no"
    mat-stroked-button
    color="primary"
    [mat-dialog-close]="false">
    Sign up Later
  </button>
  <button mat-raised-button color="primary" [mat-dialog-close]="true">
    {{ data.isSelf ? 'Yes, Continue' : 'Ok' }}
  </button>
</div>
