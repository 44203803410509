<div
  #spinnerDOM
  *ngIf="spinner.show"
  [@fadeIn]="'in'"
  [style.background-color]="
    spinner.overlay ? 'rgba(0, 0, 0, 0.2)' : 'transparent'
  "
  [style.position]="spinner.fullScreen ? 'fixed' : 'absolute'"
  [style.z-index]="spinner.zIndex"
  class="spinner-overlay">
  <div [class.enhanced]="enhanced || spinner.enhanced" class="dialog">
    <div class="spinner-container">
      <mat-progress-spinner
        [color]="spinner.color"
        [mode]="spinner.mode"
        [strokeWidth]="spinner.strokeWidth"
        [value]="spinner.value"></mat-progress-spinner>
    </div>
    <div
      class="loading-text"
      [class.enhanced]="enhanced || spinner.enhanced"
      [style.z-index]="spinner.zIndex">
      {{ text || spinner.text }}
    </div>
  </div>
</div>
