<div mat-dialog-title>
  <div>Change Landowner</div>
  <div class="close-icon-container" (click)="onNoClick()">
    <fa-icon [icon]="['far', 'xmark']" class="gray"></fa-icon>
  </div>
</div>
<mat-divider></mat-divider>
<div mat-dialog-content>
  <p class="line-1">
    You are currently acting on your own behalf. Would you like to transfer this
    site to one of your managed landowners?
  </p>
  <form [formGroup]="landownerSelectionForm" class="landowner-selection">
    <div class="landowner-dropdown">
      <mat-form-field class="landowner-select">
        <mat-select
          formControlName="landowner"
          placeholder="Select a new Owner">
          <mat-option *ngFor="let landowner of landowners" [value]="landowner">
            {{
              landowner.getDisplayName({
                reverse: true,
                includeEmail: true
              })
            }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </form>
</div>
<div mat-dialog-actions>
  <button
    color="primary"
    mat-stroked-button
    [mat-dialog-close]="false"
    cdkFocusInitial
    data-cy="button-no">
    Continue as Myself
  </button>
  <button
    color="primary"
    mat-raised-button
    (click)="onSubmit()"
    [disabled]="landownerSelectionForm.invalid"
    data-cy="button-yes">
    Transfer Owner
  </button>
</div>
