import { createFeatureSelector, createSelector } from '@ngrx/store';

import * as fromSite from './site.reducer';

import { selectCurrentUser } from '../auth/auth.selectors';

export const selectSiteState = createFeatureSelector<fromSite.SiteState>(
  fromSite.siteFeatureKey
);

export const selectActiveSite = createSelector(
  selectSiteState,
  site => site.activeSite
);

export const selectAvailableSites = createSelector(
  selectSiteState,
  site => site.availableSites
);

export const selectReadOnlyMapInfo = createSelector(
  selectActiveSite,
  selectCurrentUser,
  (activeSite, currentUser) => ({
    siteStatus: activeSite ? activeSite.status : null,
    isAdmin: currentUser ? currentUser.isAdmin : false,
    activeSite,
    currentUser,
  })
);

export const selectAvailableCohorts = createSelector(
  selectSiteState,
  site => site.availableCohorts
);

export const selectReadyForSubmit = createSelector(
  selectSiteState,
  site => site.readyForSubmit
);
