import { Directive, ElementRef, HostListener } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[dateMask]',
})
export class DateMaskDirective {
  constructor(private el: ElementRef, public ngControl: NgControl) {}

  @HostListener('ngModelChange', ['$event'])
  onModelChange($event) {
    const formattedDate = this.formatDate($event);
    this.ngControl.valueAccessor.writeValue(formattedDate);
  }

  @HostListener('keydown.backspace', ['$event'])
  keydownBackspace($event) {
    // if a backspace hits a slash, move the cursor position to before the slash
    const formattedDate = this.formatDate($event.target.value, true);
    this.ngControl.valueAccessor.writeValue(formattedDate);
  }

  formatDate(dateString: string, backspace: boolean = false) {
    // this will allow us to keep our cursor position at the end of this function
    // if any non-permitted characters are typed
    const startPosition = this.el.nativeElement.selectionStart;
    const endPosition = this.el.nativeElement.selectionStart;
    const badCharacters = dateString.match(/[^/\d]+/g);

    // replace all characters that aren't numbers or `/`
    let formattedDate = dateString.replace(/[^/\d]+/g, '');
    if (
      formattedDate.substring(
        formattedDate.length - 2,
        formattedDate.length
      ) === '//'
    ) {
      formattedDate = formattedDate.substring(0, formattedDate.length - 1);
    }
    if (formattedDate === '/') {
      formattedDate = '';
    }

    // Handle backspaces by removing `/` characters
    if (backspace && dateString[dateString.length - 1] === '/') {
      if (startPosition === dateString.length) {
        formattedDate = formattedDate.substring(0, formattedDate.length - 2);
      } else {
        setTimeout(() => {
          this.el.nativeElement.selectionStart = startPosition - 1;
          this.el.nativeElement.selectionEnd = startPosition - 1;
        });
      }
    } else if (backspace) {
      setTimeout(() => {
        this.el.nativeElement.selectionStart = startPosition - 1;
        this.el.nativeElement.selectionEnd = startPosition - 1;
      });
    }

    let splitString = formattedDate.split('/');
    let month = splitString[0];
    let day = splitString[1];
    let year = splitString[2];
    if (splitString.length > 3) {
      year = year + splitString.slice(3, splitString.length);
    }
    let monthInt = typeof month !== 'undefined' ? parseInt(month) : null;
    let dayInt = typeof day !== 'undefined' ? parseInt(day) : null;

    // handle month formatting and prep for day
    if (monthInt > 12 && month.length == 2) {
      day = month[1];
      month = month[0];
    } else if (monthInt >= 10 && month.length === 2 && day === undefined) {
      day = '';
    } else if (monthInt > 12 && month.length > 2) {
      day = month.substring(2, month.length);
      month = month.substring(0, 2);
    } else if (monthInt) {
      if (month.length === 2 && month[0] === '0') {
        month = month[1];
        day = '';
      } else {
        month = String(monthInt);
        if (!day && monthInt > 1 && monthInt < 10) {
          day = '';
        }
      }
    }

    // handle day formatting and prep for year
    if (dayInt > 31 && day.length == 2) {
      year = day[1];
      day = day[0];
    } else if (dayInt >= 30 && day.length === 2 && year === undefined) {
      year = '';
    } else if (dayInt > 31 && day.length > 2) {
      year = day.substring(2, day.length);
      day = day.substring(0, 2);
    } else if (dayInt) {
      if (day.length === 2 && day[0] === '0') {
        day = day[1];
        year = '';
      } else {
        day = String(dayInt);
        if (!year && dayInt > 3 && dayInt < 10) {
          year = '';
        }
      }
    }

    // build date components to construct date string
    let dateComponents = [];
    if (typeof month !== 'undefined') {
      dateComponents.push(month);
    }
    if (typeof day !== 'undefined') {
      dateComponents.push(day);
    }
    if (typeof year !== 'undefined') {
      dateComponents.push(year);
    }

    // join date components with slashes
    formattedDate = dateComponents.join('/');

    // if we found any characters that weren't numbers or `/`, keep the cursor at the already selected position.
    if (badCharacters && badCharacters.length > 0) {
      setTimeout(() => {
        this.el.nativeElement.selectionStart = startPosition - 1;
        this.el.nativeElement.selectionEnd = endPosition - 1;
      });
    }

    return formattedDate;
  }
}
