import { HttpClient } from '@angular/common/http';
import {
  Component,
  ElementRef,
  Inject,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  AbstractControl,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Store } from '@ngrx/store';
import moment from 'moment/moment';

import { DocumentService } from '../../../core/api/document.service';
import { GisService } from '../../../core/api/gis.service';
import { KmlForestryUploadService } from '../../../core/api/kml-forestry-upload.service';
import { SiteService } from '../../../core/api/site.service';
import { SpinnerService } from '../../../shared/spinner/spinner.service';
import * as fromApp from '../../../store';
import { Site } from '../../models';

export interface DialogData {
  site: Site;
}

@Component({
  selector: 'fc-dialog-split-site',
  templateUrl: './dialog-split-site.component.html',
  styleUrls: ['./dialog-split-site.component.scss'],
})
export class DialogSplitSiteComponent implements OnInit {
  @ViewChild('kmlFileUpload', { static: false }) kmlFileUpload: ElementRef;
  kmlDocSelectForm: UntypedFormGroup;
  errorMessage = null;
  fileUpload = null;
  kmlParsedUpload: any;
  minEffectiveDate: Date = new Date();
  maxEffectiveDate: Date = new Date();
  constructor(
    public dialogRef: MatDialogRef<DialogSplitSiteComponent>,
    readonly http: HttpClient,
    public extraDialog: MatDialog,
    private formBuilder: UntypedFormBuilder,
    readonly siteService: SiteService,
    readonly documentService: DocumentService,
    readonly spinnerService: SpinnerService,
    readonly gisService: GisService,
    readonly kmlForestryUploadService: KmlForestryUploadService,
    readonly store: Store<fromApp.AppState>,
    public snackBar: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {
    this.minEffectiveDate = data.site.versions[0].paymentPeriods
      .filter(p => !p.payment)
      .sort((a, b) => a.sequence - b.sequence)[0].startDate;
    if (data.site.submittedDate > this.minEffectiveDate) {
      this.minEffectiveDate = data.site.submittedDate;
    }
    this.minEffectiveDate = new Date(this.minEffectiveDate + 'T00:00:00');
    this.maxEffectiveDate.setHours(0);
  }

  ngOnInit() {
    this.kmlDocSelectForm = this.formBuilder.group({
      uploadKmlFileName: ['', Validators.required],
      effectiveDate: ['', Validators.required],
    });
  }

  validateKMLFile = (file: any) => {
    const fileExtension = file.name.substring(file.name.length - 4);
    this.spinnerService.show('app-spinner', true, 'Validating KML');
    switch (fileExtension) {
      case '.kml':
        this.kmlForestryUploadService
          .parseKmlDocument(file, false)
          .then(parsedKml => {
            this.checkKmlFeatures(parsedKml);
            this.uploadKmlFileNameControl.setValue(this.fileUpload.name);
            this.kmlParsedUpload = parsedKml;
          })
          .catch((err: any) => {
            this.uploadKmlFileNameControl.setValue('');
          })
          .finally(() => {
            this.spinnerService.hide('app-spinner');
          });
        break;
      case '.kmz':
        this.kmlForestryUploadService
          .parseKmzDocument(file, false)
          .then(parsedKml => {
            this.checkKmlFeatures(parsedKml);
            this.uploadKmlFileNameControl.setValue(this.fileUpload.name);
            this.kmlParsedUpload = parsedKml;
          })
          .catch((err: any) => {
            this.uploadKmlFileNameControl.setValue('');
          })
          .finally(() => {
            this.spinnerService.hide('app-spinner');
          });
        break;
      default:
        this.snackBar.open('Only .kml and .kmz files are supported.', 'close', {
          duration: 8000,
          panelClass: 'errorSnack',
        });
        this.uploadKmlFileNameControl.setValue('');
        this.spinnerService.hide('app-spinner');
    }
  };

  onClickKmlUpload(): void {
    const kmlFileUpload = this.kmlFileUpload.nativeElement;
    kmlFileUpload.onchange = () => {
      if (kmlFileUpload.files.length === 1) {
        this.fileUpload = kmlFileUpload.files[0];
        this.validateKMLFile(this.fileUpload);
      }
    };
    kmlFileUpload.click();
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
  applyKML() {
    this.dialogRef.close({
      effectiveDate: moment(
        this.kmlDocSelectForm.get('effectiveDate').value,
        true
      ).format('YYYY-MM-DD'),
      newKML: this.kmlParsedUpload.features[0],
    });
  }

  get uploadKmlFileName(): string {
    return this.kmlDocSelectForm.get('uploadKmlFileName').value;
  }

  get uploadKmlFileNameControl(): AbstractControl {
    return this.kmlDocSelectForm.get('uploadKmlFileName');
  }

  checkKmlFeatures(parsedKml: any) {
    if (parsedKml.features.length != 1) {
      this.snackBar.open(
        'Error Parsing KML. Should only have 1 feature!',
        'close',
        {
          duration: 8000,
          panelClass: 'errorSnack',
        }
      );
      throw new Error('KML bad format');
    }
  }
}
