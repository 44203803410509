<div class="quick-links-container">
  <div>Quick Links</div>
  <div *ngIf="allowAdd; else noActAs" class="quick-links-link">
    <a routerLink="/site/new-site">Add a New Site</a>
  </div>
  <ng-template #noActAs>
    <div class="quick-links-link disabled">Add a New Site</div>
  </ng-template>
  <div *ngIf="allowTerminate; else noTerminate" class="quick-links-link">
    <a (click)="showTerminatePage()" href="javascript:void(0)">
      Transfer or Terminate a Contract
    </a>
  </div>
  <ng-template #noTerminate>
    <div class="quick-links-link disabled">
      Transfer or Terminate a Contract
    </div>
  </ng-template>
  <div class="quick-links-link ext-link">
    <a
      href="https://finitecarbon.com"
      rel="noopener noreferrer"
      target="_blank">
      Finite Carbon Website
      <fa-icon
        [icon]="['fas', 'arrow-up-right-from-square']"
        class="blue"></fa-icon>
    </a>
  </div>
  <div
    *ngIf="currentUser.preferredBuyer && currentUser.preferredBuyer.url"
    class="quick-links-link ext-link">
    <a
      href="{{ currentUser.preferredBuyer.url }}"
      rel="noopener noreferrer"
      target="_blank">
      {{ currentUser.preferredBuyer.name }} Website
      <fa-icon
        [icon]="['fas', 'arrow-up-right-from-square']"
        class="blue"></fa-icon>
    </a>
  </div>
  <div class="quick-links-link ext-link">
    <a routerLink="/legal/terms-and-conditions" target="_blank">
      Terms and Conditions
    </a>
  </div>
  <div class="quick-links-link ext-link">
    <a routerLink="/legal/privacy-policy" target="_blank">Privacy Policy</a>
  </div>
</div>
