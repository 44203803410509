import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { cloneDeep } from 'lodash';
import moment from 'moment';
import { Observable } from 'rxjs';

import SiteStatusConstants from '../../shared/constants/site_status.constants';
import { DialogEligibilityQuestionComponent } from '../../shared/dialogs/dialog-eligibility-question/dialog-eligibility-question.component';
import { DialogTerminateSiteComponent } from '../../shared/dialogs/dialog-terminate-site/dialog-terminate-site.component';
import { DialogYesNoComponent } from '../../shared/dialogs/dialog-yes-no/dialog-yes-no.component';
import { PaymentPeriod, Site } from '../../shared/models';
import * as fromApp from '../../store';
import { CohortService } from '../api/cohort.service';
import { SiteService } from '../api/site.service';

@Injectable({
  providedIn: 'root',
})
export class SiteHelperService {
  constructor(
    public dialog: MatDialog,
    readonly router: Router,
    readonly siteService: SiteService,
    readonly cohortService: CohortService,
    readonly store: Store<fromApp.AppState>
  ) {}

  readOnlyMapCheck(info: { siteStatus: string; isAdmin: boolean }): boolean {
    if (
      info.isAdmin &&
      info.siteStatus &&
      (info.siteStatus === SiteStatusConstants.REVIEW_REQUIRED.status ||
        info.siteStatus === SiteStatusConstants.ADMIN_REVISED.status)
    ) {
      return false;
    }
    return (
      info.isAdmin ||
      (info.siteStatus &&
        !SiteStatusConstants.sitePendingStatusList.includes(info.siteStatus))
    );
  }

  showFirewoodDialog(deductionPerYear: string) {
    return this.dialog.open(DialogEligibilityQuestionComponent, {
      panelClass: 'fc-dialog-eligibility-question',
      data: {
        title: 'ARE YOU SURE?',
        question:
          'By selecting YES to cutting up to 5 cords of dead or dying trees for firewood use in the coming year,',
        redText:
          ' you agree that your annual payment will be adjusted for the coming year:',
        infoBox:
          "A cord is defined as 128 cubic feet of wood. This measures approximately 4' high x 8' long x 4' deep.",
        deductionPerYear,
        buttonTextYes: 'Yes, I Agree',
        buttonTextNo: 'No, Cancel',
      },
      autoFocus: false,
    });
  }

  showClearingDialog(allowedClearing: string, deductionPerYear: string) {
    return this.dialog.open(DialogEligibilityQuestionComponent, {
      panelClass: 'fc-dialog-eligibility-question',
      data: {
        title: 'ARE YOU SURE?',
        question: `You are exercising the <b>one-time option</b> to clear up to ${allowedClearing} acres of land in the coming year`,
        redText: ` and you agree that your annual payment will be <b>permanently</b> adjusted:`,
        deductionPerYear: deductionPerYear,
        buttonTextYes: 'Yes, I Agree',
        buttonTextNo: 'No, Cancel',
      },
      autoFocus: false,
    });
  }

  getPayablePaymentPeriods(sites: Site[]): PaymentPeriod[] {
    let payablePaymentPeriods = [];
    if (sites) {
      // get a list of all the sites' payment periods that are
      // both in the past and have a report
      for (const site of sites) {
        if (site.versions[0].paymentPeriods) {
          site.versions[0].paymentPeriods.forEach(period => {
            // Check if the combination already exists in uniqueCombinations
            if (
              period.report != null &&
              moment(period.endDate).isBefore(moment())
            ) {
              const exists = payablePaymentPeriods.some(
                combination =>
                  combination.startDate === period.startDate &&
                  combination.endDate === period.endDate
              );

              // If not, add it to uniqueCombinations
              if (!exists) {
                let clonePeriod = cloneDeep(period);
                clonePeriod.id = payablePaymentPeriods.length + 1;
                payablePaymentPeriods.push(clonePeriod);
              }
            }
          });
        }
      }
    }
    return payablePaymentPeriods;
  }

  sitesArePayable(sites: Site[]): boolean {
    let accountsPayable = false;
    let isActive = false;

    if (sites) {
      // the sites must have been activated
      isActive = sites.every((val, i, arr) => val.activationDate !== null);
      // make sure the users are all able to be paid via Stripe
      accountsPayable = sites.every(
        (val, i, arr) =>
          val.account.user.stripeAccounts.filter(
            sa =>
              sa.buyerId === val.cohort.buyerId &&
              sa.stripeAccountCreationToken !== null &&
              val.status === SiteStatusConstants.PAYMENT_READY.admin
          ).length > 0
      );
    }
    return accountsPayable && isActive;
  }

  showTerminateDialog() {
    this.dialog.open(DialogTerminateSiteComponent, {
      panelClass: 'fc-yes-no-dialog',
      data: {},
    });
  }

  showDifferentTermsAvailableDialog(): Observable<boolean> {
    return this.dialog
      .open(DialogYesNoComponent, {
        panelClass: 'fc-yes-no-dialog',
        data: {
          title: 'Different Terms Available',
          line1:
            'This parcel is not available with your previously selected term agreement but it is available with a different set of terms. Would you like to continue?',
          line2: '',
          buttonTextYes: 'Yes, Continue',
          buttonTextNo: 'No, Cancel',
        },
      })
      .afterClosed();
  }
}
