import { Component } from '@angular/core';

import { environment } from '../../../../environments/environment';
import { EnvironmentsEnum } from '../../constants/environments.constants';

@Component({
  selector: 'fc-env-ribbon',
  template: `
    <div *ngIf="env === EnvironmentsEnum.develop" class="dev env">Dev</div>
    <div *ngIf="env === EnvironmentsEnum.staging" class="stage env">
      Staging
    </div>
  `,
})
export class EnvRibbonComponent {
  env: string = environment.env;

  protected readonly EnvironmentsEnum = EnvironmentsEnum;
}
