<div class="container">
  <div class="page-container">
    <div class="page-title">User Activity Dashboard</div>
    <mat-divider class="divider-top"></mat-divider>
    <div class="subtitle-row">
      <div class="left-section">
        <div class="total-programs">
          {{ startIndex }} - {{ endIndex }} of {{ totalItems }}
        </div>
        <div class="pagination-select">
          <mat-form-field>
            <mat-select
              (selectionChange)="goToPage(1)"
              [(value)]="paginationTotal"
              class="select-filter">
              <mat-option [value]="20">20 Per page</mat-option>
              <mat-option [value]="40">40 Per page</mat-option>
              <mat-option [value]="100">100 Per page</mat-option>
              <mat-option [value]="99999">Show All</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div
          (click)="clearFilters()"
          [className]="
            this.getBaseQueryString() === this.getFullQueryString()
              ? 'clear-filter disabled-filter'
              : 'clear-filter'
          "
          color="primary"
          mat-stroked-button>
          Clear All Filters
        </div>
      </div>
    </div>

    <mat-divider class="divider-bottom"></mat-divider>

    <div class="table-container">
      <table
        [dataSource]="dataSource"
        aria-describedby="Activity List"
        mat-table>
        <ng-container matColumnDef="userName">
          <th *matHeaderCellDef class="header-col" mat-header-cell scope="col">
            <div>User Name</div>
            <mat-form-field class="change-owner-select">
              <input
                [formControl]="selectedUser"
                [matAutocomplete]="filterUser"
                data-lpignore="true"
                matInput
                placeholder="Filter"
                type="text" />
              <mat-autocomplete
                #filterUser="matAutocomplete"
                (optionSelected)="
                  applyFilter($event.option, 'user_id');
                  selectedUser.setValue($event.option.viewValue)
                ">
                <mat-option
                  *ngFor="let user of filteredUsers | async"
                  [value]="user.id">
                  {{ user.getDisplayName({ includeEmail: true }) }}
                </mat-option>
              </mat-autocomplete>
            </mat-form-field>
          </th>
          <td *matCellDef="let row" mat-cell>
            <div>
              {{ row.user.getDisplayName() }}
            </div>
          </td>
        </ng-container>

        <ng-container matColumnDef="event">
          <th *matHeaderCellDef class="header-col" mat-header-cell scope="col">
            <div>Event Name</div>

            <mat-form-field>
              <mat-select
                (selectionChange)="applyFilter($event, 'event')"
                [(value)]="filterValues.event"
                class="select-filter">
                <mat-option [value]="">All</mat-option>
                <mat-option *ngFor="let type of eventTypeFilter" [value]="type">
                  {{ type }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </th>
          <td *matCellDef="let row" mat-cell>
            <div>
              {{ row.event }}
            </div>
          </td>
        </ng-container>
        <ng-container matColumnDef="eventDetail">
          <th *matHeaderCellDef mat-header-cell scope="col">
            <div>Detail</div>
          </th>
          <td *matCellDef="let row" mat-cell>
            <div>
              {{ row.eventDetails }}
            </div>
          </td>
        </ng-container>
        <ng-container matColumnDef="eventTime">
          <th *matHeaderCellDef class="header-col" mat-header-cell scope="col">
            <div
              (click)="toggleSingleSort('event_time')"
              class="header-with-sort">
              <span>Event Time</span>
              <span [class]="sortBy['event_time']" id="sort-icon-container">
                <fc-sort-icon
                  [column]="'event_time'"
                  [sortBy]="sortBy"
                  [sortStates]="sortStates"></fc-sort-icon>
              </span>
            </div>
          </th>
          <td *matCellDef="let row" mat-cell>
            <div>
              {{ row.eventTime | date: 'long' }}
            </div>
          </td>
        </ng-container>
        <tr *matHeaderRowDef="displayedColumns" mat-header-row></tr>
        <tr *matRowDef="let row; columns: displayedColumns" mat-row></tr>
      </table>
      <fc-spinner
        [overlay]="true"
        name="activity-spinner"
        text="Loading Activity Log"></fc-spinner>
    </div>

    <mat-divider class="divider-top"></mat-divider>
    <fc-table-pagination
      [totalPages]="totalPages"
      (goToPage)="goToPage($event)"
      [currentPage]="currentPage"
      [showPages]="showPages"></fc-table-pagination>
  </div>
</div>
