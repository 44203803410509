<div mat-dialog-title>
  <div>Recalculating Parcel Values</div>
</div>
<mat-divider></mat-divider>
<div mat-dialog-content>
  <p>
    The region data has changed since the last time the site has been edited. If
    you wish to update your site data, please click Proceed, otherwise, you can
    cancel and return to your site dashboard.
  </p>
</div>
<div mat-dialog-actions>
  <button mat-stroked-button color="primary" [mat-dialog-close]="false">
    Cancel
  </button>
  <button mat-raised-button color="primary" (click)="onProceedClick()">
    Proceed
  </button>
</div>
