import { createAction, props } from '@ngrx/store';

export const getCohorts = createAction(
  '[Cohort] Get Cohorts',
  props<{ filter: string }>()
);

export const getCohortsSuccess = createAction(
  '[Cohort] Get Cohorts Success',
  props<{ data: any }>()
);

export const getCohortsFailure = createAction(
  '[Cohort] Get Cohorts Failure',
  props<{ error: any }>()
);
