import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { skipWhile, switchMap, take } from 'rxjs/operators';

import * as fromApp from '../../store';
import { selectAppConfigState } from '../../store/app-config/app-config.selectors';

@Injectable({ providedIn: 'root' })
export class NotInviteOnlyGuard {
  constructor(
    private router: Router,
    readonly store: Store<fromApp.AppState>
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    return this.store.select(selectAppConfigState).pipe(
      skipWhile(appConfig => appConfig.appConfig.length === 0),
      take(1),
      switchMap(appConfig => {
        let inviteOnly =
          typeof appConfig.config['invite_only'] !== 'undefined'
            ? appConfig.config['invite_only'].toLowerCase() === 'true'
            : false;
        if (!inviteOnly) {
          return of(true);
        } else {
          this.router.navigate(['/']);
        }
      })
    );
  }
}
