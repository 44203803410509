<div mat-dialog-title>
  <div>{{ data.title }}</div>
  <div (click)="onNoClick()" class="close-icon-container">
    <fa-icon [icon]="['far', 'xmark']" class="gray"></fa-icon>
  </div>
</div>
<mat-divider></mat-divider>
<div mat-dialog-content>
  <p class="line-1">{{ data.line1 }}</p>
  <ng-container *ngIf="data.sitePayments">
    <div>
      <table aria-describedby="Payment Results">
        <thead>
          <tr>
            <th scope="col">Site Id</th>
            <th scope="col">Paid Amount</th>
            <th scope="col">Transaction Id</th>
            <th scope="col">Error Message</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let row of data.sitePayments">
            <td>{{ row.siteId }}</td>
            <td>
              {{
                row.paidAmountInCents
                  ? (row.paidAmountInCents / 100 | currency: 'USD')
                  : 0
              }}
            </td>
            <td>{{ row.transactionId }}</td>
            <td>{{ row.error }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </ng-container>
</div>
<div mat-dialog-actions>
  <button
    *ngIf="data.buttonTextNo"
    [mat-dialog-close]="false"
    cdkFocusInitial
    color="primary"
    data-cy="button-no"
    mat-stroked-button>
    {{ data.buttonTextNo }}
  </button>
  <button
    *ngIf="data.buttonTextYes"
    [mat-dialog-close]="true"
    color="primary"
    data-cy="button-yes"
    mat-raised-button>
    {{ data.buttonTextYes }}
  </button>
</div>
