import { Injectable } from '@angular/core';
import * as tj from '@mapbox/togeojson';
import { Store } from '@ngrx/store';
import { FeatureCollection } from 'geojson';
import * as JSZip from 'jszip';

import { ToastLevel } from 'src/app/shared/models/toast-message.module';
import { ToastService } from 'src/app/shared/services/toast.service';

import { DocumentService } from './document.service';

import { Site } from '../../shared/models';
import * as fromApp from '../../store';
import { createDocument } from '../../store/document/document.actions';
import { setForestryKml } from '../../store/parcel/parcel.actions';

@Injectable({
  providedIn: 'root',
})
export class KmlForestryUploadService {
  constructor(
    readonly store: Store<fromApp.AppState>,
    private documentService: DocumentService,
    private toastService: ToastService
  ) {}

  parseKmlDocument(file, setStore = true) {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.onload = async (e: any) => {
        this.extractAndParseKML(file.name, e.target.result, setStore)
          .then(parsedKML => {
            resolve(parsedKML);
          })
          .catch(error => {
            reject(error);
          });
      };
      fileReader.readAsText(file);
    });
  }

  parseKmzDocument(file, setStore = true) {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.onload = async (e: any) => {
        this.extractAndParseKMZ(file.name, e.target.result, setStore)
          .then(parsedKML => {
            resolve(parsedKML);
          })
          .catch(error => {
            reject(error);
          });
      };
      fileReader.readAsArrayBuffer(file);
    });
  }

  extractAndParseKML(fileName: string, fileData, setStore = true) {
    return new Promise((resolve, reject) => {
      try {
        const geoJSONFromKML: FeatureCollection =
          this.parseKMLContent(fileData);
        if (setStore) {
          this.store.dispatch(
            setForestryKml({ data: geoJSONFromKML.features })
          );
        }
        resolve(geoJSONFromKML);
      } catch (error) {
        reject(error);
      }
    });
  }

  extractAndParseKMZ(fileName: string, fileData, setStore = true) {
    return new Promise((resolve, reject) => {
      const zipParser = new JSZip();
      zipParser.loadAsync(fileData).then(
        zip => {
          const kmlFile = Object.values(zip.files).filter(doc =>
            doc.name.toLowerCase().endsWith('.kml')
          )[0];
          kmlFile.async('text').then(content => {
            try {
              const geoJSONFromKML: FeatureCollection =
                this.parseKMLContent(content);
              if (setStore) {
                this.store.dispatch(
                  setForestryKml({ data: geoJSONFromKML.features })
                );
              }
              resolve(geoJSONFromKML);
            } catch (error) {
              reject(error);
            }
          });
        },
        e => {
          reject(new Error('error converting the =' + e.message));
        }
      );
    });
  }

  parseKMLContent = (content: string): FeatureCollection => {
    const parser = new DOMParser();
    const xmlDoc = parser.parseFromString(content, 'text/xml');
    if (xmlDoc.getElementsByTagName('parsererror').length > 0) {
      this.toastService.sendMessage(
        'XML is Invalid. Please correct before continuing.',
        ToastLevel.WARN
      );
      throw new Error('Invalid XML found!');
    }
    return tj.kml(xmlDoc);
  };

  uploadKMLToSite(site: Site, KmlFile: File) {
    const newDoc = {
      site_id: site.id,
      document_type_id: this.documentService.getDocTypeId('Site KML'),
      name: new Date().getTime() + '_' + KmlFile.name,
    };
    this.store.dispatch(
      createDocument({
        siteIdentifier: site.identifier,
        document: newDoc,
        file: KmlFile,
      })
    );
  }
}
