<div data-cy="add-user-dialog">
  <div mat-dialog-title class="header">
    {{ title }}
  </div>
  <mat-divider></mat-divider>
  <div mat-dialog-content>
    <form [formGroup]="userForm" class="new-user-form">
      <mat-form-field class="full-width">
        <mat-label>Email</mat-label>
        <input
          #email
          formControlName="email"
          id="email"
          matInput
          data-cy="email-input"
          name="email"
          type="text" />
        <mat-error *ngIf="emailErrorMessage">{{ emailErrorMessage }}</mat-error>
        <mat-error *ngIf="errorMessage">
          {{ errorMessage }}
        </mat-error>
      </mat-form-field>
      <mat-form-field class="full-width">
        <mat-label>Role:</mat-label>
        <mat-select formControlName="role" data-cy="select-role">
          <mat-option value="SuperAdmin">Super Admin</mat-option>
          <mat-option value="Admin">Admin</mat-option>
          <mat-option value="FinanceAdmin">Finance Admin</mat-option>
          <mat-option value="LimitedAdmin" [disabled]="disableUserRoleOptions">
            Limited Admin {{ disableUserRoleOptions ? '(disabled)' : '' }}
          </mat-option>
          <mat-option value="Landowner" [disabled]="disableUserRoleOptions">
            Landowner {{ disableUserRoleOptions ? '(disabled)' : '' }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <ng-container
        *ngIf="
          userForm.getRawValue().role &&
          (userForm.getRawValue().role === RoleEnum.Landowner ||
            userForm.getRawValue().role === RoleEnum.LimitedAdmin)
        ">
        <ng-container
          *ngIf="
            userForm.getRawValue().role === RoleEnum.LimitedAdmin;
            else singleSelect
          ">
          <mat-form-field class="full-width">
            <mat-label>{{ buyerSelectLabel }}</mat-label>
            <mat-select
              formControlName="buyers"
              data-cy="buyer-select-multiple"
              multiple
              (selectionChange)="addBuyerId($event)">
              <mat-option>None</mat-option>
              <mat-option *ngFor="let buyer of buyers" [value]="buyer.id">
                {{ buyer.name }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </ng-container>
        <ng-template #singleSelect>
          <mat-form-field class="full-width">
            <mat-label>{{ buyerSelectLabel }}</mat-label>
            <mat-select
              formControlName="buyers"
              (selectionChange)="addBuyerId($event)">
              <mat-option>None</mat-option>
              <mat-option *ngFor="let buyer of buyers" [value]="buyer.id">
                {{ buyer.name }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </ng-template>
      </ng-container>
      <mat-checkbox data-cy="is-active-checkbox" formControlName="isActive">
        Is Active
      </mat-checkbox>
    </form>
  </div>
  <div mat-dialog-actions>
    <button
      mat-stroked-button
      color="primary"
      mat-dialog-close
      data-cy="cancel-button">
      CANCEL
    </button>
    <button
      (click)="submitUser()"
      [disabled]="userForm.invalid || userForm.pristine"
      color="primary"
      data-cy="submit-button"
      mat-raised-button>
      {{ primaryButtonText }}
    </button>
  </div>
</div>
