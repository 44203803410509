import { createAction, props } from '@ngrx/store';

export const setLandOwnerNotifications = createAction(
  '[Notification] Set LO Notifications',
  props<{ data: { count: number; required: boolean; userId: number } }>()
);

export const resetStripeAccountRequiresAction = createAction(
  '[Notification] Reset Stripe Account Requires Action'
);

export const resetLandOwnerPaymentsAccountRequiresSetup = createAction(
  '[Notification] Reset LO Payment Account Requires Setup'
);

export const resetNotifications = createAction(
  '[Notification] Reset Notifications'
);

export const getAdminNotifications = createAction(
  '[Notification] Get Admin Notifications'
);

export const getAdminNotificationsSuccess = createAction(
  '[Notification] Get Admin Notifications Success',
  props<{ data: any }>()
);

export const getAdminNotificationsFailure = createAction(
  '[Notification] Get Admin Notifications Failure',
  props<{ error: any }>()
);
