<div class="background-container">
  <div class="outer-container">
    <ng-container *ngIf="site">
      <div *ngIf="!isAdmin; else forAdmin" class="page-heading-container">
        <div class="page-name-row">Disturbance Reporting</div>
        <mat-divider></mat-divider>
        <div *ngIf="!readOnly" class="page-description">
          Please take a moment to fill out the questions to keep us informed on
          the status of your Carbon Offset Site. Some answers may require some
          additional description.
        </div>
      </div>
      <ng-template #forAdmin>
        <div class="page-heading-buttons">
          <button (click)="onChangeState()" color="primary" mat-raised-button>
            Change Site Status
          </button>
        </div>
        <div
          [ngStyle]="{ 'flex-direction': 'row', 'align-items': 'center' }"
          class="page-heading-container">
          <div class="page-name-row">Disturbance Reporting</div>
          <div class="page-status-row">
            <span>Status:</span>
            {{ site.status }}
          </div>
        </div>
        <mat-divider></mat-divider>
      </ng-template>

      <div class="inner-container">
        <div class="site-name">{{ site.name }}</div>
        <mat-divider></mat-divider>
        <div class="site-overview">
          <div class="overview-title">Site Overview</div>
          <div class="overview-data">
            <div class="site-name">
              Site Name:
              <span>{{ site.name }}</span>
            </div>
            <div>
              Owner:
              <span>{{ getOwnerName() }}</span>
              <span class="user-verified">
                {{ isOwnerVerified() ? '✓' : '' }}
              </span>
            </div>
            <div>
              Submitted Date:
              <span>{{ site.submittedDate | date: 'shortDate' }}</span>
            </div>
            <div>
              Approved Date:
              <span>{{ site.approvedDate | date: 'shortDate' }}</span>
            </div>
            <div>
              Parcel Number:
              <span>{{ parcelCommaSeparatedList }}</span>
            </div>
            <div>
              Forested Acres:
              <span>{{ getArea() }}</span>
            </div>
            <div>
              Stands:
              <span>{{ getStandCount() }}</span>
            </div>
            <div>
              Site #:
              <span>{{ site.id }}</span>
            </div>
            <div>
              Cohort:
              <span>{{ site.cohort.name }}</span>
            </div>
            <div class="projected-payment">
              <div class="payment-label">Estimated Annual Revenue:</div>
              <span>{{ projectedNext12MonthRevenue }}</span>
              <fa-icon
                #tooltip="matTooltip"
                (click)="tooltip.toggle()"
                [icon]="['far', 'circle-info']"
                class="blue"
                matTooltip="Estimated annual revenue is the base payout for the next year of payments. This value can change based on your responses to periodic reporting or on market performance."
                matTooltipClass="fc-tooltip"
                matTooltipPosition="right"></fa-icon>
            </div>
          </div>
        </div>
        <mat-divider></mat-divider>
        <div class="full-form" [ngClass]="readOnly ? 'readonly' : ''">
          <form [formGroup]="reportForm" fcFormUnloadWarning>
            <div class="question-list">
              <div>1.</div>
              <div class="question-section">
                <div>How would you describe the disturbance on this land?</div>
                <mat-form-field>
                  <mat-select
                    [disabled]="readOnly"
                    formControlName="disturbanceType"
                    placeholder="Select One">
                    <mat-option
                      *ngFor="let group of disturbanceCauses"
                      [value]="group.name">
                      {{ group.name }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <div>2.</div>
              <div class="question-section">
                <div>Select the cause of this disturbance:</div>
                <mat-form-field>
                  <mat-select
                    [disabled]="readOnly"
                    formControlName="cause"
                    placeholder="Select One">
                    <mat-optgroup
                      *ngFor="let group of disturbanceCauses"
                      [label]="group.name">
                      <mat-option
                        *ngFor="let cause of group.cause"
                        [value]="cause.value">
                        {{ cause.viewValue }}
                      </mat-option>
                    </mat-optgroup>
                  </mat-select>
                </mat-form-field>
              </div>
              <div>3.</div>
              <div class="question-section">
                <div>
                  What was the approximate percentage of the Forest that was
                  impacted by this disturbance?
                </div>
                <mat-form-field>
                  <mat-select
                    [disabled]="readOnly"
                    formControlName="impactPercentage"
                    placeholder="Select One">
                    <mat-option
                      *ngFor="let percentage of impactPercentages"
                      [value]="percentage.value">
                      {{ percentage.viewValue }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <div>4.</div>
              <div class="question-section">
                <div>
                  In your own words, please provide us with a general
                  description of the disturbance:
                </div>
                <textarea formControlName="description" rows="4"></textarea>
              </div>
              <div>&nbsp;</div>
              <div *ngIf="!readOnly" class="question-section">
                <mat-checkbox
                  class="legalAck"
                  id="legalAck"
                  data-cy="legal-ack"
                  formControlName="legalAck">
                  {{ LegalConstants.QUESTION_DISCLAIMER }}
                </mat-checkbox>
              </div>
              <div>&nbsp;</div>
              <div *ngIf="!readOnly" class="question-section buttons">
                <button
                  (click)="submitReport()"
                  [disabled]="!reportForm.valid"
                  class="submit-button"
                  color="primary"
                  data-cy="submit-disturbance-report-button"
                  mat-raised-button>
                  Submit Report
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </ng-container>
  </div>
</div>
