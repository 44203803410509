import { Component, Inject, OnInit } from '@angular/core';
import {
  AbstractControl,
  UntypedFormControl,
  UntypedFormGroup,
  ValidationErrors,
  ValidatorFn,
} from '@angular/forms';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { isString } from 'lodash';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

import { UserService } from 'src/app/core/api/user.service';

import * as fromApp from '../../../store';
import { getUsers } from '../../../store/user/user.actions';
import { User } from '../../models';
import { ConfirmOwnerDialogComponent } from '../confirm-owner-dialog/confirm-owner-dialog.component';

interface DialogData {
  currentOwner: User;
  siteName: string;
}

@Component({
  selector: 'fc-dialog-change-owner',
  templateUrl: './dialog-change-owner.component.html',
  styleUrls: ['./dialog-change-owner.component.scss'],
})
export class DialogChangeOwnerComponent implements OnInit {
  changeOwnerForm: UntypedFormGroup;
  usersList: User[] = [];

  newOwner = new UntypedFormControl('', this.isUserValidator());

  filteredUsers: Observable<User[]> = this.newOwner.valueChanges.pipe(
    startWith(''),
    map((value: string) => this._filter(value))
  );

  constructor(
    readonly store: Store<fromApp.AppState>,
    public dialogRef: MatDialogRef<DialogChangeOwnerComponent>,
    readonly userService: UserService,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {}

  ngOnInit(): void {
    this.changeOwnerForm = new UntypedFormGroup({
      newOwner: this.newOwner,
    });

    this.store.dispatch(getUsers());
    this.userService.getUsers().subscribe(users => {
      this.usersList = users;
    });
  }

  private _filter(value: string | User): User[] {
    let filterValue = '';
    if (isString(value)) {
      filterValue = String(value).toLowerCase();
    } else {
      filterValue = new User(value).getDisplayName();
    }

    return this.usersList.filter(user => {
      return (
        (user.getDisplayName().toLowerCase().includes(filterValue) ||
          user.email.toLowerCase().includes(filterValue)) &&
        user.id !== this.data.currentOwner.id &&
        user.isLandowner
      );
    });
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  displayUserName(user: User): string {
    return user ? user.getDisplayName() : '';
  }

  onSubmit() {
    const confirmDialogRef = this.dialog.open(ConfirmOwnerDialogComponent, {
      panelClass: 'fc-confirm-owner-dialog',
      data: {
        previousOwner: this.data.currentOwner.getDisplayName(),
        newOwner: this.newOwner.value.getDisplayName(),
        siteName: this.data.siteName,
      },
      autoFocus: false,
    });

    confirmDialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.dialogRef.close({ user: this.newOwner.value });
      }
    });
  }

  isUserValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null =>
      control.value instanceof User
        ? null
        : { isNotUser: { value: control.value } };
  }
}
