export default class SiteStatusConstants {
  public static PENDING = {
    status: 'Pending',
    owner: 'Unsubmitted',
    admin: 'Pending',
  };
  public static REVIEW_REQUIRED = {
    status: 'Review Required',
    owner: 'Under Review',
    admin: 'Review Required',
  };
  public static ADMIN_REVISED = {
    status: 'Admin Revised',
    owner: 'Under Review',
    admin: 'Admin Revised',
  };
  public static REQUIRES_LANDOWNER_REVISION = {
    status: 'Requires Landowner Revision',
    owner: 'Requires Revision',
    admin: 'Requires Landowner Revision',
  };
  public static DORMANT = {
    status: 'Dormant',
    owner: 'Dormant',
    admin: 'Dormant',
  };
  public static APPROVED = {
    status: 'Approved',
    owner: 'Map Approved',
    admin: 'Map Approved',
  };
  public static CONTRACT_SENT = {
    status: 'Landowner Awaiting Contract',
    owner: 'Landowner Awaiting Contract',
    admin: 'Landowner Awaiting Contract',
  };
  public static LANDOWNER_CONTRACT_SIGNED = {
    status: 'Landowner Contract Signed',
    owner: 'Awaiting Contract Approval',
    admin: 'Landowner Signed Contract',
  };
  public static CONTRACT_SIGNED = {
    status: 'Contract Signed',
    owner: 'Contract Signed',
    admin: 'Contract Signed',
  };
  public static CONTRACT_REJECTED = {
    status: 'Contract Rejected',
    owner: 'Contract Rejected',
    admin: 'Contract Rejected',
  };
  public static ACTIVE = { status: 'Active', owner: 'Active', admin: 'Active' };
  public static REPORTING_REQUIRED = {
    status: 'Reporting Required',
    owner: 'Reporting Required',
    admin: 'Reporting Required',
  };
  public static DISTURBANCE_REPORTED = {
    status: 'Disturbance Reported',
    owner: 'Disturbance Detected',
    admin: 'Disturbance Detected',
  };
  public static ELIGIBILITY_PENDING = {
    status: 'Eligibility Pending',
    owner: 'Eligibility Pending',
    admin: 'Eligibility PendingEligibility Pending',
  };
  public static REPORTING_COMPLETE = {
    status: 'Reporting Complete',
    owner: 'Reporting Complete',
    admin: 'Reporting Complete',
  };
  public static PAYMENT_FAILED = {
    status: 'Payment Failed',
    owner: 'Payment Failed',
    admin: 'Payment Failed',
  };
  public static PAYMENT_READY = {
    status: 'Payment Ready',
    owner: 'Payment Ready',
    admin: 'Payment Ready',
  };
  public static TERMINATED = {
    status: 'Terminated',
    owner: 'Terminated',
    admin: 'Terminated',
  };
  public static NOT_CREDITING = {
    status: 'Not Crediting',
    owner: 'Not Crediting',
    admin: 'Not Crediting',
  };

  static siteStatusList = [
    SiteStatusConstants.PENDING,
    SiteStatusConstants.REVIEW_REQUIRED,
    SiteStatusConstants.ADMIN_REVISED,
    SiteStatusConstants.DORMANT,
    SiteStatusConstants.REQUIRES_LANDOWNER_REVISION,
    SiteStatusConstants.APPROVED,
    SiteStatusConstants.CONTRACT_SENT,
    SiteStatusConstants.LANDOWNER_CONTRACT_SIGNED,
    SiteStatusConstants.CONTRACT_SIGNED,
    SiteStatusConstants.CONTRACT_REJECTED,
    SiteStatusConstants.ACTIVE,
    SiteStatusConstants.REPORTING_REQUIRED,
    SiteStatusConstants.DISTURBANCE_REPORTED,
    SiteStatusConstants.ELIGIBILITY_PENDING,
    SiteStatusConstants.REPORTING_COMPLETE,
    SiteStatusConstants.PAYMENT_FAILED,
    SiteStatusConstants.PAYMENT_READY,
    SiteStatusConstants.TERMINATED,
    SiteStatusConstants.NOT_CREDITING,
  ];

  static sitePendingStatusList = [
    SiteStatusConstants.PENDING.status,
    SiteStatusConstants.REQUIRES_LANDOWNER_REVISION.status,
    SiteStatusConstants.DORMANT.status,
  ];
  static siteSubmittedStatusList = [
    SiteStatusConstants.ADMIN_REVISED.status,
    SiteStatusConstants.REVIEW_REQUIRED.status,
    SiteStatusConstants.APPROVED.status,
    SiteStatusConstants.CONTRACT_SENT.status,
    SiteStatusConstants.LANDOWNER_CONTRACT_SIGNED.status,
    SiteStatusConstants.CONTRACT_SIGNED.status,
  ];
  static siteActiveStatusList = [
    SiteStatusConstants.ACTIVE.status,
    SiteStatusConstants.REPORTING_REQUIRED.status,
    SiteStatusConstants.DISTURBANCE_REPORTED.status,
    SiteStatusConstants.ELIGIBILITY_PENDING.status,
    SiteStatusConstants.REPORTING_COMPLETE.status,
    SiteStatusConstants.PAYMENT_FAILED.status,
    SiteStatusConstants.PAYMENT_READY.status,
  ];
  static siteInactiveStatusList = [
    SiteStatusConstants.CONTRACT_REJECTED.status,
    SiteStatusConstants.TERMINATED.status,
    SiteStatusConstants.NOT_CREDITING.status,
  ];

  public static getDBStatusList(): Array<string> {
    return this.siteStatusList.map(item => item.status).sort();
  }

  public static getOwnerText(status: string) {
    const foundObject = this.siteStatusList.find(o => o.status === status);
    return foundObject ? foundObject.owner : null;
  }

  public static getAdminText(status: string) {
    const foundObject = this.siteStatusList.find(o => o.status === status);
    return foundObject ? foundObject.admin : null;
  }
}
