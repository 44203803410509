<div mat-dialog-title>
  <div>Update Parcel Data</div>
  <div (click)="onNoClick()" class="close-icon-container">
    <fa-icon [icon]="['far', 'xmark']" class="gray"></fa-icon>
  </div>
</div>
<mat-divider></mat-divider>
<div mat-dialog-content>
  <form [formGroup]="updatePaymentForm">
    <mat-form-field
      class="full-width"
      data-cy="edit-ppt"
      subscriptSizing="dynamic">
      <mat-label>Price Per Ton</mat-label>
      <input
        currencyMask
        data-cy="ppt"
        formControlName="ppt"
        matInput
        placeholder="Enter Price Per Ton"
        type="text" />
    </mat-form-field>
    <mat-form-field
      class="full-width"
      data-cy="edit-payout-adjustment"
      subscriptSizing="dynamic">
      <mat-label>Payment Period Adjustment</mat-label>
      <input
        currencyMask
        data-cy="payout-adjustment"
        formControlName="netPayoutAdjustment"
        matInput
        placeholder="Enter Adjustment"
        type="text" />
    </mat-form-field>
    <mat-radio-group formControlName="isVariablePeriod">
      <mat-radio-button [value]="true">Variable</mat-radio-button>
      <mat-radio-button [value]="false">Fixed</mat-radio-button>
    </mat-radio-group>
    <mat-form-field
      class="full-width"
      data-cy="comments"
      subscriptSizing="dynamic">
      <textarea
        matInput
        placeholder="Comments"
        formControlName="comments"
        required
        rows="4"></textarea>
    </mat-form-field>
  </form>
</div>
<div mat-dialog-actions>
  <button
    [mat-dialog-close]="false"
    color="primary"
    data-cy="button-cancel"
    mat-stroked-button>
    Cancel
  </button>
  <button
    (click)="onSubmit()"
    [disabled]="updatePaymentForm.invalid"
    color="primary"
    data-cy="button-submit"
    mat-raised-button>
    Save
  </button>
</div>
