import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, concatMap, map, switchMap } from 'rxjs/operators';

import * as DocumentActions from './document.actions';

import { DocumentService } from '../../core/api/document.service';
import { AppState } from '../index';

@Injectable()
export class DocumentEffects {
  getAllDocumentTypes$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(DocumentActions.getAllDocumentTypes),
      concatMap(action => {
        return this.documentService.getAllDocumentTypes().pipe(
          map(data => DocumentActions.getAllDocumentTypesSuccess({ data })),
          catchError(error =>
            of(DocumentActions.getAllDocumentTypesFailure({ error }))
          )
        );
      })
    );
  });

  getDocuments$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(DocumentActions.getDocuments),
      concatMap(action => {
        return this.documentService.getDocuments(action.identifier).pipe(
          map(data => DocumentActions.getDocumentsSuccess({ data })),
          catchError(error =>
            of(DocumentActions.getDocumentsFailure({ error }))
          )
        );
      })
    );
  });

  deleteDocument$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(DocumentActions.deleteDocument),
      concatMap(action => {
        return this.documentService
          .deleteDocument(action.siteIdentifier, action.identifier)
          .pipe(
            map(data => DocumentActions.deleteDocumentSuccess({ data })),
            catchError(error =>
              of(DocumentActions.deleteDocumentFailure({ error }))
            )
          );
      })
    );
  });

  createDocument$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(DocumentActions.createDocument),
      concatMap(action => {
        return this.documentService
          .uploadFile(action.siteIdentifier, action.file)
          .pipe(
            switchMap((fileKey: string) => {
              return this.documentService
                .saveDocument(action.document, fileKey)
                .pipe(
                  map(data => DocumentActions.createDocumentSuccess({ data })),
                  catchError(error =>
                    of(DocumentActions.createDocumentFailure({ error }))
                  )
                );
            })
          );
      })
    );
  });

  saveDocument$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(DocumentActions.saveDocument),
      concatMap(action => {
        return this.documentService.updateDocument(action.document).pipe(
          map(data => DocumentActions.saveDocumentSuccess({ data })),
          catchError(error =>
            of(DocumentActions.saveDocumentFailure({ error }))
          )
        );
      })
    );
  });

  constructor(
    readonly actions$: Actions,
    readonly documentService: DocumentService,
    readonly store: Store<AppState>
  ) {}
}
