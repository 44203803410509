import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';

import { MapService } from '../../../../core/helper/map.service';
import { Parcel } from '../../../../shared/models';

@Component({
  selector: 'fc-parcel-item',
  templateUrl: './parcel-item.component.html',
  styleUrls: ['./parcel-item.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ParcelItemComponent implements AfterViewInit {
  @ViewChild('dataContainer', { static: false }) dataContainer: ElementRef;

  @Input()
  parcel: Parcel;
  constructor(readonly mapService: MapService) {}

  ngAfterViewInit(): void {
    this.dataContainer.nativeElement.innerHTML = this.parcel.svg;
  }
  zoomToParcel() {
    this.mapService.flyToBoundingBox(this.parcel.feature);
  }
}
