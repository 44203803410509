<mat-expansion-panel #matExpansionPanel>
  <mat-expansion-panel-header data-cy="verifications-header">
    <mat-panel-title>
      <div class="title">Verification Visits</div>
      <a
        (click)="showAddNew($event)"
        *ngIf="matExpansionPanel.expanded && !isLimitedAdmin"
        class="fc-link"
        data-cy="new-visit">
        Add New
      </a>
    </mat-panel-title>
  </mat-expansion-panel-header>
  <mat-divider></mat-divider>
  <div *ngIf="showAddNewForm" class="form-container">
    <form [formGroup]="addNewForm" class="verification-form">
      <fc-datepicker
        formControlName="date"
        formFieldClass="w-100-1"
        inputID="date"
        label="Date"></fc-datepicker>
      <mat-form-field class="w-50-2">
        <mat-label>Type</mat-label>
        <mat-select formControlName="verificationType">
          <mat-option value="Field Verification">Field Verification</mat-option>
          <mat-option value="Desktop Verification">
            Desktop Verification
          </mat-option>
        </mat-select>
      </mat-form-field>
    </form>
    <div class="form-buttons">
      <button
        (click)="showAddNewForm = false"
        color="primary"
        mat-stroked-button>
        Cancel
      </button>
      <button (click)="submitNewVisit()" color="primary" mat-raised-button>
        Save
      </button>
    </div>
  </div>

  <div *ngFor="let visit of visits; let i = index">
    <div [class.hide]="updateVisitIndex === i" class="verification-visit">
      <div class="info">
        <div class="date">{{ visit.date | date: 'longDate' }}</div>
        <div class="type">
          Type:
          <span class="data">{{ visit.verificationType }}</span>
        </div>
      </div>
      <div class="buttons">
        <fa-icon
          (click)="showUpdateForm(i)"
          *ngIf="!isLimitedAdmin"
          [icon]="['far', 'pencil']"
          class="blue pencil-icon"></fa-icon>
        <fa-icon
          (click)="deleteVisit(visit.id)"
          *ngIf="!isLimitedAdmin"
          [icon]="['far', 'xmark']"
          class="blue close-icon"></fa-icon>
      </div>
    </div>

    <div *ngIf="updateVisitIndex === i" class="form-container">
      <form [formGroup]="updateForm" class="verification-form">
        <fc-datepicker
          formControlName="date"
          formFieldClass="w-100-1"
          inputID="date"
          label="Date"></fc-datepicker>
        <mat-form-field class="w-50-2">
          <mat-label>Type</mat-label>
          <mat-select formControlName="verificationType">
            <mat-option value="Field Verification">
              Field Verification
            </mat-option>
            <mat-option value="Desktop Verification">
              Desktop Verification
            </mat-option>
          </mat-select>
        </mat-form-field>
      </form>
      <div class="form-buttons">
        <button
          (click)="updateVisitIndex = undefined"
          color="primary"
          mat-stroked-button>
          Cancel
        </button>
        <button
          (click)="updateVisit()"
          class="save-button"
          color="primary"
          mat-raised-button>
          Save
        </button>
      </div>
    </div>
  </div>
</mat-expansion-panel>

<div *ngIf="showUpdateToast" class="toast update">
  <fa-icon [icon]="['fas', 'check-circle']" class="white"></fa-icon>
  Verification Visit Updated
</div>

<div *ngIf="showDeleteToast" class="toast delete">
  <fa-icon [icon]="['fas', 'circle-xmark']" class="red"></fa-icon>
  Verification Visit Deleted
</div>
