import { Base } from './base.model';

export class Buyer extends Base {
  public name: string;
  public shortName: string;
  public referrerCode: number;
  public secondarySignerName: string;
  public secondarySignerEmail: string;
  public slogan: string;
  public stripeClientId: string;
  public url: string;
  public contactAddressLine1: string;
  public contactAddressLine2: string;
  public contactCity: string;
  public contactState: string;
  public contactZip: string;
  public secondarySignerTitle: string;
  public docusignIntegratorKey: string;
  public docusignUserId: string;
  public docusignPrivateKey: string;
  public buyerSignatureSettings: string;
  public landownerSignatureSettings: string;
  public landownerTitleSettings: string;
  public landownerEntitySettings: string;
  public truliooUsername: string;
  public truliooPassword: string;
  public stripeSecretKey: string;
  public stripePublicKey: string;
}
