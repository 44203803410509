import { Component, DestroyRef, Inject, inject, OnInit } from '@angular/core';
import {
  FormControl,
  UntypedFormBuilder,
  UntypedFormGroup,
} from '@angular/forms';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { Store } from '@ngrx/store';

import { SiteService } from '../../../core/api/site.service';
import * as fromApp from '../../../store';
import { setActiveSite } from '../../../store/site/site.actions';
import { Parcel } from '../../models';

@Component({
  selector: 'fc-dialog-edit-parcel-data',
  templateUrl: './dialog-edit-parcel-data.component.html',
  styleUrl: './dialog-edit-parcel-data.component.scss',
})
export class DialogEditParcelDataComponent implements OnInit {
  destroyRef = inject(DestroyRef);
  changeSiteForm: UntypedFormGroup;
  parcel: Parcel;
  siteId: number;

  constructor(
    readonly store: Store<fromApp.AppState>,
    public dialogRef: MatDialogRef<DialogEditParcelDataComponent>,
    public dialog: MatDialog,
    public siteService: SiteService,
    private formBuilder: UntypedFormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.parcel = data.parcel;
    this.siteId = data.siteId;
  }

  ngOnInit(): void {
    this.changeSiteForm = this.formBuilder.group({
      parcelNumber: new FormControl(this.parcel.parcelNumber),
      owner: new FormControl(this.parcel.owner),
      address: new FormControl(this.parcel.address),
      county: new FormControl(this.parcel.county),
      state: new FormControl(this.parcel.state),
    });
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  onSubmit() {
    this.siteService
      .updateParcelData(
        this.siteId,
        this.parcel.id,
        this.changeSiteForm.value.owner,
        this.changeSiteForm.value.address,
        this.changeSiteForm.value.parcelNumber,
        this.changeSiteForm.value.county,
        this.changeSiteForm.value.state
      )
      .subscribe(updatedParcel => {
        this.store.dispatch(setActiveSite({ id: this.siteId }));
        this.dialogRef.close();
      });
  }
}
