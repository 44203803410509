<form [formGroup]="newSiteForm">
  <div class="form-step-container eligibility">
    <div class="eligibility-header">AM I ELIGIBLE?</div>
    <div class="eligibility-content">
      Certain criteria can disqualify you from creating a carbon offset site
      with us. Before continuing, consider if you could answer “YES” to all of
      the following:
      <ul>
        <li>
          I own this property or am acting on behalf of someone who owns this
          property.
        </li>
        <li>
          The legal owner of the enrolled acres owns less than 5,000 forested
          acres in the United States.
        </li>
        <li>
          I have the rights to and ability to physically access the timber on
          the acreage I want to enroll.
        </li>
        <li>
          I have not cleared trees on this property in the previous 12 months.
        </li>
        <li>
          My property is not enrolled in any other programs that restrict me
          from enrolling it with CORE Carbon.
          <fa-icon
            #tooltip1="matTooltip"
            (click)="tooltip1.toggle()"
            [icon]="['far', 'circle-info']"
            class="blue"
            matTooltip="Examples include:&#13;&#13;
            •  Participation in a government or private cost share program that specifies or restricts forest management for a period of time.&#13;&#13;
            •  Inclusion in another carbon offset site or programs that compensate for storing carbon."
            matTooltipClass="fc-tooltip"
            matTooltipPosition="right"></fa-icon>
        </li>
        <li>
          The acres I want to enroll include less than 20% pre-merchantable
          stands.
          <fa-icon
            #tooltip1="matTooltip"
            (click)="tooltip1.toggle()"
            [icon]="['far', 'circle-info']"
            class="blue"
            matTooltip="The term 'pre-merchantable' is defined as a contiguous grouping of trees (of 3 acres or more) that fail to meet industrial minimums for either tree size or volume per acre so as to preclude a commercial harvest operation."
            matTooltipClass="fc-tooltip"
            matTooltipPosition="right"></fa-icon>
        </li>
        <li>
          I understand that , if enrolled in CORE Carbon, I cannot commercially
          harvest timber on the property for 20 years and must maintain
          increased carbon stocking levels on the property for 40 years.
        </li>
      </ul>
    </div>
    <div class="eligibility-button-container">
      <button (click)="goToDash()" color="primary" mat-stroked-button>
        Cancel
      </button>
      <button
        (click)="confirmInitialEligibility()"
        color="primary"
        data-cy="continue-button"
        mat-raised-button
        type="button">
        Continue
      </button>
    </div>
  </div>
</form>
