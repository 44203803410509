<div mat-dialog-title>
  <div>Update Parcel Data</div>
  <div (click)="onNoClick()" class="close-icon-container">
    <fa-icon [icon]="['far', 'xmark']" class="gray"></fa-icon>
  </div>
</div>
<mat-divider></mat-divider>
<div mat-dialog-content>
  <form [formGroup]="changeSiteForm">
    <mat-form-field
      class="full-width"
      data-cy="edit-owner"
      subscriptSizing="dynamic">
      <mat-label>Owner Name</mat-label>
      <input
        data-cy="owner-name"
        formControlName="owner"
        matInput
        placeholder="Enter owner name"
        type="text" />
    </mat-form-field>
    <mat-form-field
      class="full-width"
      data-cy="edit-address"
      subscriptSizing="dynamic">
      <mat-label>Parcel Address</mat-label>
      <input
        data-cy="address"
        formControlName="address"
        matInput
        placeholder="Enter address"
        type="text" />
    </mat-form-field>
    <mat-form-field
      class="full-width"
      data-cy="edit-parcel-number"
      subscriptSizing="dynamic">
      <mat-label>Parcel Number</mat-label>
      <input
        data-cy="parcel-number"
        formControlName="parcelNumber"
        matInput
        placeholder="Enter parcel number"
        type="text" />
    </mat-form-field>
    <mat-form-field
      class="full-width"
      data-cy="edit-county"
      subscriptSizing="dynamic">
      <mat-label>County</mat-label>
      <input
        data-cy="county"
        formControlName="county"
        matInput
        placeholder="Enter County"
        type="text" />
    </mat-form-field>
    <mat-form-field
      class="full-width"
      data-cy="edit-state"
      subscriptSizing="dynamic">
      <mat-label>State</mat-label>
      <input
        data-cy="state"
        formControlName="state"
        matInput
        placeholder="Enter State"
        type="text" />
    </mat-form-field>
  </form>
</div>
<div mat-dialog-actions>
  <button
    [mat-dialog-close]="false"
    color="primary"
    data-cy="button-cancel"
    mat-stroked-button>
    Cancel
  </button>
  <button
    (click)="onSubmit()"
    [disabled]="changeSiteForm.invalid"
    color="primary"
    data-cy="button-submit"
    mat-raised-button>
    Save
  </button>
</div>
