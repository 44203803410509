import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Store } from '@ngrx/store';

import { GisService } from '../../../core/api/gis.service';
import * as fromApp from '../../../store';
import { SpinnerService } from '../../spinner/spinner.service';

@Component({
  selector: 'fc-dialog-overlapping-stands',
  templateUrl: './dialog-overlapping-stands.component.html',
  styleUrls: ['./dialog-overlapping-stands.component.scss'],
})
export class DialogOverlappingStandsComponent {
  siteId: number;

  constructor(
    public dialogRef: MatDialogRef<DialogOverlappingStandsComponent>,
    readonly gisService: GisService,
    readonly spinnerService: SpinnerService,
    readonly store: Store<fromApp.AppState>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.siteId = data.siteId;
  }

  buttonClick(adjust): void {
    if (adjust) {
      this.spinnerService.show('app-spinner');
      this.gisService
        .correctOverlappingStands(this.siteId)
        .subscribe(standArray => {
          this.spinnerService.hide('app-spinner');
          this.dialogRef.close(adjust);
        });
    } else {
      this.dialogRef.close(adjust);
    }
  }
}
