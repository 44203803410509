import { createFeatureSelector, createSelector } from '@ngrx/store';

import * as fromNotification from './notification.reducer';

export const selectNotificationState =
  createFeatureSelector<fromNotification.NotificationState>(
    fromNotification.notificationFeatureKey
  );

export const selectAdminNotifications = createSelector(
  selectNotificationState,
  notifications => notifications.admin
);
