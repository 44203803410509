<div class="upload-button">
  <button
    color="primary"
    class="button-next"
    (click)="selectDocument()"
    data-cy="button-attach-document"
    mat-raised-button>
    <span>Upload Document</span>
  </button>
</div>
<div class="doc-list">
  <table
    [dataSource]="existingDocs"
    class="doc-list-table"
    mat-table
    aria-describedby="List of Site Documents">
    <ng-container matColumnDef="added">
      <th scope="col" *matHeaderCellDef mat-header-cell>Date Added</th>
      <td *matCellDef="let element" mat-cell>
        <div class="doc-name">{{ element.createDate | date: 'short' }}</div>
        <div class="doc-description">
          {{ element.user?.getDisplayName({ includeEmail: false }) }}
        </div>
      </td>
    </ng-container>
    <ng-container matColumnDef="title">
      <th scope="col" *matHeaderCellDef mat-header-cell>Document Title</th>
      <td *matCellDef="let element" mat-cell>
        <div class="doc-name">{{ element.name }}</div>
        <div class="doc-description">{{ element.description }}</div>
      </td>
    </ng-container>
    <ng-container matColumnDef="type">
      <th scope="col" *matHeaderCellDef mat-header-cell>Document Type</th>
      <td *matCellDef="let element" mat-cell>
        {{ getDocTypeName(element.documentTypeId) }}
      </td>
    </ng-container>
    <ng-container matColumnDef="actions">
      <th scope="col" *matHeaderCellDef mat-header-cell>Actions</th>
      <td *matCellDef="let element" class="action-cell" mat-cell>
        <div
          *ngIf="
            !actAsUser.isLimitedAdmin &&
            (activeSite.submittedDate === null || actAsUser.isAdmin)
          "
          (click)="editDocument(element)"
          class="edit-icon">
          <fa-icon
            title="Edit Document"
            [icon]="['far', 'edit']"
            class="blue size-18"></fa-icon>
        </div>
        <div (click)="downloadDocument(element, false)" class="open-icon">
          <fa-icon
            title="Download Document"
            [icon]="['far', 'download']"
            class="blue size-18"></fa-icon>
        </div>
        <div (click)="downloadDocument(element, true)" class="open-icon">
          <fa-icon
            title="Open in New Tab"
            [icon]="['far', 'arrow-up-right-from-square']"
            class="blue size-18"></fa-icon>
        </div>
        <div
          *ngIf="
            !actAsUser.isLimitedAdmin &&
            (activeSite.submittedDate === null || actAsUser.isAdmin)
          "
          (click)="deleteDocument(element)"
          class="delete-icon">
          <fa-icon
            title="Delete Document"
            [icon]="['far', 'trash']"
            class="gray delete-icon size-18"></fa-icon>
        </div>
      </td>
    </ng-container>
    <tr *matHeaderRowDef="displayedColumns" mat-header-row></tr>
    <tr *matRowDef="let row; columns: displayedColumns" mat-row></tr>
  </table>
</div>
<input
  #documentUpload
  accept="{{ defaultFileTypes }}"
  id="documentUpload"
  name="documentUpload"
  style="display: none"
  type="file" />
