import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Store } from '@ngrx/store';

import { GisService } from '../../../core/api/gis.service';
import * as fromApp from '../../../store';
import {
  removeRedFlagFailure,
  removeRedFlagSuccess,
} from '../../../store/parcel/parcel.actions';
import { Site } from '../../models';
import { SpinnerService } from '../../spinner/spinner.service';

@Component({
  selector: 'fc-dialog-overlapping-public',
  templateUrl: './dialog-overlapping-public.component.html',
  styleUrls: ['./dialog-overlapping-public.component.scss'],
})
export class DialogOverlappingPublicLandComponent {
  site: Site;

  constructor(
    public dialogRef: MatDialogRef<DialogOverlappingPublicLandComponent>,
    readonly gisService: GisService,
    readonly spinnerService: SpinnerService,
    readonly store: Store<fromApp.AppState>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.site = data.site;
  }

  buttonClick(adjust): void {
    if (adjust) {
      this.spinnerService.show('app-spinner');
      this.gisService.removePublicOverlap(this.site.id).subscribe(
        response => {
          this.spinnerService.hide('app-spinner');
          this.store.dispatch(
            removeRedFlagSuccess({ stands: response.results })
          );
          this.dialogRef.close(adjust);
        },
        error => {
          this.store.dispatch(removeRedFlagFailure({ error }));
          this.spinnerService.hide('app-spinner');
          this.dialogRef.close(adjust);
        }
      );
    } else {
      this.dialogRef.close(adjust);
    }
  }
}
