<div mat-dialog-title>Reason For Resubmission Request</div>
<mat-divider></mat-divider>
<div mat-dialog-content class="dialog-content">
  <p>This will send the site back to the landowner for revisions.</p>
  <p>Please enter a reason for this resubmission request</p>
  <textarea [(ngModel)]="why" class="reason" rows="7"></textarea>
</div>
<div mat-dialog-actions>
  <button mat-stroked-button data-cy="button-no" (click)="onNoClick()">
    Cancel
  </button>
  <button
    mat-raised-button
    color="primary"
    [disabled]="yesButtonDisabled"
    (click)="onYesClick()">
    Submit
  </button>
</div>
