<div mat-dialog-title>
  <div>{{ data.title }}</div>
  <div class="close-icon-container" (click)="onNoClick()">
    <fa-icon [icon]="['far', 'xmark']" class="gray"></fa-icon>
  </div>
</div>
<mat-divider></mat-divider>
<div mat-dialog-content data-cy="dialog-content">
  <ng-container *ngIf="data.line1">
    <p class="line-1" [ngStyle]="data.line1Color && { color: data.line1Color }">
      {{ data.line1 }}
    </p>
  </ng-container>
  <ng-container *ngIf="data.line2">
    <p class="line-2" [ngStyle]="data.line2Color && { color: data.line2Color }">
      {{ data.line2 }}
    </p>
  </ng-container>
  <ng-container *ngIf="data.line3">
    <p class="line-3" [ngStyle]="data.line3Color && { color: data.line3Color }">
      {{ data.line3 }}
    </p>
  </ng-container>
</div>
<div mat-dialog-actions data-cy="dialog-actions">
  <button
    *ngIf="data.buttonTextNo"
    color="primary"
    mat-stroked-button
    [mat-dialog-close]="false"
    cdkFocusInitial
    data-cy="button-no">
    {{ data.buttonTextNo }}
  </button>
  <div *ngIf="!data.buttonTextNo"></div>
  <button
    *ngIf="data.buttonTextYes"
    color="primary"
    mat-raised-button
    [mat-dialog-close]="true"
    data-cy="button-yes">
    {{ data.buttonTextYes }}
  </button>
</div>
