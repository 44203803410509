<div mat-dialog-title>
  <div>CHANGE Site Name</div>
  <div class="close-icon-container" (click)="onNoClick()">
    <fa-icon [icon]="['far', 'xmark']" class="gray"></fa-icon>
  </div>
</div>
<mat-divider></mat-divider>
<div mat-dialog-content>
  <p>
    Current Name:
    <span class="current-name">{{ site.name }}</span>
  </p>
  <form [formGroup]="changeSiteForm">
    <span class="change-to">Change to:</span>
    <mat-form-field class="change-site-name" subscriptSizing="fixed">
      <input
        type="text"
        matInput
        maxlength="50"
        required
        formControlName="newName"
        placeholder="New Site Name" />
      <mat-error *ngIf="changeSiteForm.controls.newName.errors?.pattern">
        Your site name can only contains letters, numbers, and these characters:
        - + ( ) . _
      </mat-error>
      <mat-error *ngIf="changeSiteForm.controls.newName.errors?.required">
        This field is required.
      </mat-error>
    </mat-form-field>
  </form>
</div>
<div mat-dialog-actions>
  <button
    data-cy="button-cancel"
    mat-stroked-button
    color="primary"
    [mat-dialog-close]="false">
    Cancel
  </button>
  <button
    data-cy="button-submit"
    mat-raised-button
    color="primary"
    [disabled]="changeSiteForm.invalid"
    (click)="onSubmit()">
    Submit
  </button>
</div>
