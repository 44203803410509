import { Component, Inject, OnInit } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { Store } from '@ngrx/store';

import { UserService } from 'src/app/core/api/user.service';

import * as fromApp from '../../../store';
import { User } from '../../models';

interface DialogData {
  currentUser: User;
}

@Component({
  selector: 'fc-dialog-change-acting-as-user',
  templateUrl: './dialog-change-acting-as-user.component.html',
  styleUrls: ['./dialog-change-acting-as-user.component.scss'],
})
export class DialogChangeActingAsUserComponent implements OnInit {
  currentUser: User;
  landowners: User[];

  landownerSelectionForm: UntypedFormGroup;

  constructor(
    readonly store: Store<fromApp.AppState>,
    public dialogRef: MatDialogRef<DialogChangeActingAsUserComponent>,
    readonly userService: UserService,
    private formBuilder: UntypedFormBuilder,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {
    this.currentUser = data.currentUser;
  }

  ngOnInit(): void {
    this.landownerSelectionForm = this.formBuilder.group({
      landowner: ['', Validators.required],
    });
    this.landowners = this.currentUser.landowners;
  }

  onNoClick(): void {
    this.dialogRef.close(false);
  }

  onSubmit() {
    this.dialogRef.close(this.landownerSelectionForm.value.landowner);
  }
}
