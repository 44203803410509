<div mat-dialog-title>
  <div>{{ data.title }}</div>
  <div (click)="onNoClick()" class="close-icon-container">
    <fa-icon [icon]="['far', 'xmark']" class="gray"></fa-icon>
  </div>
</div>
<mat-divider></mat-divider>
<div mat-dialog-content>
  <ng-container *ngIf="data.question">
    <p class="eligibility-question-text">
      <span [innerHTML]="data.question"></span>
      <fa-icon
        *ngIf="infoBoxPlacement === 1"
        [icon]="['far', 'circle-info']"
        class="blue"
        matTooltip="A cord is defined as 128 cubic feet of wood. This measures approximately 4' high x 8' long x 4' deep."
        matTooltipClass="fc-tooltip"
        matTooltipPosition="right"></fa-icon>
      <span *ngIf="data.redText" class="eligibility-question-text red">
        <span [innerHTML]="data.redText"></span>
        <fa-icon
          #tooltip="matTooltip"
          (click)="tooltip.toggle()"
          *ngIf="infoBoxPlacement === 2"
          [icon]="['far', 'circle-info']"
          class="blue"
          matTooltip="A cord is defined as 128 cubic feet of wood. This measures approximately 4' high x 8' long x 4' deep."
          matTooltipClass="fc-tooltip"
          matTooltipPosition="right"></fa-icon>
      </span>
    </p>
  </ng-container>
  <ng-container *ngIf="data.deductionPerYear">
    <div class="yearly-value first">
      Annual Revenue will be deducted:
      <span class="value">{{ data.deductionPerYear }}</span>
    </div>
  </ng-container>
</div>
<div class="popup-disclaimer">
  **Approximate reduction value, this could change based on the variable pricing
  terms and conditions for your agreement.
</div>
<div mat-dialog-actions>
  <button
    *ngIf="data.buttonTextNo"
    [mat-dialog-close]="false"
    cdkFocusInitial
    color="primary"
    data-cy="button-no"
    mat-stroked-button>
    {{ data.buttonTextNo }}
  </button>
  <div *ngIf="!data.buttonTextNo"></div>
  <button
    *ngIf="data.buttonTextYes"
    [mat-dialog-close]="true"
    color="primary"
    data-cy="button-yes"
    mat-raised-button>
    {{ data.buttonTextYes }}
  </button>
</div>
