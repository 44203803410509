import {
  Component,
  DestroyRef,
  ElementRef,
  inject,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import {
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormGroup,
} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { cloneDeep } from 'lodash';
import { combineLatest } from 'rxjs';
import { take } from 'rxjs/operators';

import { DocumentService } from '../../../core/api/document.service';
import { selectActAsUser } from '../../../store/auth/auth.selectors';
import {
  createDocument,
  deleteDocument,
  saveDocument,
} from '../../../store/document/document.actions';
import { selectDocumentTypes } from '../../../store/document/document.selectors';
import * as fromApp from '../../../store/index';
import { DialogUploadDocComponent } from '../../dialogs/dialog-upload-doc/dialog-upload-doc.component';
import { DialogYesNoComponent } from '../../dialogs/dialog-yes-no/dialog-yes-no.component';
import { Document, Site, User } from '../../models';
import { ToastLevel } from '../../models/toast-message.module';
import { ToastService } from '../../services/toast.service';

@Component({
  selector: 'fc-site-doc-list',
  templateUrl: './site-document-list.component.html',
  styleUrls: ['./site-document-list.component.scss'],
})
export class SiteDocumentListComponent implements OnInit {
  @ViewChild('documentUpload', { static: false }) documentUpload: ElementRef;
  @Input() activeSite: Site = null;
  @Input() showAllDocs: boolean = false;

  destroyRef = inject(DestroyRef);
  submitInfoForm: UntypedFormGroup;

  docTypes = [];
  existingDocs: Document[] = [];
  displayedColumns: string[] = ['added', 'title', 'type', 'actions'];

  defaultFileTypes: string = '.pdf,.doc,.docx,.xsl,.xslx,.ppt,.pptx,.png,.jpg';
  actAsUser: User = null;

  constructor(
    readonly store: Store<fromApp.AppState>,
    private formBuilder: UntypedFormBuilder,
    readonly documentService: DocumentService,
    public dialog: MatDialog,
    private toastService: ToastService
  ) {}

  ngOnInit() {
    combineLatest([
      this.store.select(selectDocumentTypes),
      this.store.select(selectActAsUser),
    ])
      .pipe(take(1), takeUntilDestroyed(this.destroyRef))
      .subscribe(storeData => {
        const docTypes = storeData[0];
        const actAsUser = storeData[1];
        this.docTypes = docTypes
          ? docTypes
              .filter(t => (this.showAllDocs ? true : !t.isPrivate))
              .map(t => ({ value: t.id, viewValue: t.name }))
          : null;
        this.actAsUser = actAsUser;
      });

    this.store
      .select('document', 'documents')
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(documents => {
        if (documents && this.activeSite && documents[this.activeSite.id]) {
          this.existingDocs = documents[this.activeSite.id]
            .filter(d =>
              this.docTypes.map(dt => dt.value).includes(d.documentTypeId)
            )
            .map(d => cloneDeep(d))
            .sort((a, b) => b.id - a.id);
        }
      });
  }

  createDocumentItem(docTypeId: number = null): UntypedFormGroup {
    return this.formBuilder.group({
      document_type_id: docTypeId,
      name: null,
      identifier: null,
    });
  }

  getDocTypeName(documentTypeId: number) {
    const docType = this.docTypes.find(dt => dt.value === documentTypeId);
    return docType?.viewValue;
  }

  downloadDocument(doc: Document, open: boolean): void {
    this.documentService
      .downloadDocument(
        this.activeSite.identifier,
        doc.identifier,
        doc.name,
        open
      )
      .then(uri => window.open(uri, '_blank'));
  }
  addDocument(): void {
    const documents = this.submitInfoForm.get('documents') as UntypedFormArray;
    documents.push(this.createDocumentItem());
  }

  deleteDocument(doc: Document): void {
    this.dialog
      .open(DialogYesNoComponent, {
        panelClass: 'fc-yes-no-dialog',
        data: {
          title: 'Delete Confirmation',
          line1: 'Are you sure you wish to permanently delete this document?',
          buttonTextYes: 'Delete',
          buttonTextNo: 'Cancel',
        },
      })
      .afterClosed()
      .subscribe(result => {
        if (result) {
          this.store.dispatch(
            deleteDocument({
              siteIdentifier: this.activeSite.identifier,
              identifier: doc.identifier,
            })
          );
        }
      });
  }

  editDocument(doc: Document) {
    this.dialog
      .open(DialogUploadDocComponent, {
        data: {
          filename: doc.name,
          description: doc.description,
          docTypeId: doc.documentTypeId,
          comments: doc.comments,
        },
      })
      .afterClosed()
      .subscribe(result => {
        if (result) {
          const newDoc = {
            document_type_id: result.docTypeId,
            name: doc.name,
            description: result.description,
            identifier: doc.identifier,
          };
          this.store.dispatch(
            saveDocument({
              document: newDoc,
            })
          );
        }
      });
  }

  uploadDocument(
    file: File,
    docTypeId: number,
    description: string,
    comments?: string
  ): void {
    const newDoc = {
      site_id: this.activeSite.id,
      document_type_id: docTypeId,
      name: file.name,
      description: description,
      comments: comments,
    };
    this.store.dispatch(
      createDocument({
        siteIdentifier: this.activeSite.identifier,
        document: newDoc,
        file: file,
      })
    );
  }

  selectDocument(): void {
    const documentUpload = this.documentUpload.nativeElement;
    documentUpload.onchange = () => {
      if (documentUpload.files.length === 1) {
        const file = documentUpload.files[0];
        if (!file.name.startsWith('.') && this.checkFileType(file.name)) {
          this.dialog
            .open(DialogUploadDocComponent, {
              data: {
                filename: file.name,
              },
            })
            .afterClosed()
            .subscribe(result => {
              if (result) {
                this.uploadDocument(
                  file,
                  result.docTypeId,
                  result.description,
                  result.comments
                );
              }
              this.documentUpload.nativeElement.value = '';
            });
        } else {
          this.documentUpload.nativeElement.value = '';
        }
      }
    };
    documentUpload.click();
  }

  checkFileType(
    fileName: string,
    fileTypes: string[] = this.defaultFileTypes.split(','),
    showToast: boolean = true
  ) {
    let fileType = fileName.split('.').pop();
    if (fileTypes.includes('.' + fileType.toLowerCase())) {
      return true;
    } else if (showToast) {
      this.toastService.sendMessage(
        `Unsupported file type: ${fileType}.`,
        ToastLevel.WARN
      );
    }
    return false;
  }
}
