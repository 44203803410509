<mat-form-field [ngClass]="formFieldClass" class="fc-datepicker">
  <mat-label>{{ label }}</mat-label>
  <input
    #dateInput
    (blur)="onBlur()"
    [(ngModel)]="formattedDate"
    [class]="inputClass"
    [errorStateMatcher]="errorMatcher"
    [id]="inputID"
    dateMask
    matInput
    [required]="required"
    placeholder="MM/DD/YYYY"
    type="text" />
  <input
    (dateChange)="onDateChange($event)"
    (ngModelChange)="onChange($event)"
    [(ngModel)]="value"
    [matDatepickerFilter]="datepickerFilter"
    [matDatepicker]="picker"
    [max]="maxDate"
    [min]="minDate"
    hidden
    class="hidden"
    matInput
    placeholder="MM/DD/YYYY" />
  <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
  <mat-datepicker #picker></mat-datepicker>
</mat-form-field>
<ng-container *ngIf="ngControl.touched">
  <mat-error
    *ngIf="invalid || invalidDate || ngControl.hasError('required')"
    [id]="inputID + 'ValidError'">
    A valid date is
    <b>required</b>
    .
  </mat-error>
  <mat-error
    *ngIf="minDate && value && value < minDate"
    [id]="inputID + 'MinError'">
    This date
    <b>precedes</b>
    the minimum allowed date:
    <b>{{ minDate | date: 'shortDate' }}</b>
  </mat-error>
  <mat-error
    *ngIf="maxDate && value && value > maxDate"
    [id]="inputID + 'MaxError'">
    This date
    <b>exceeds</b>
    the maximum allowed date:
    <b>{{ maxDate | date: 'shortDate' }}</b>
  </mat-error>
  <mat-error
    *ngIf="value && datepickerFilter && !datepickerFilter(value)"
    [id]="inputID + 'FilterError'"
    [innerHtml]="
      dateFilterErrorMessage
        ? dateFilterErrorMessage
        : 'This date is not permitted.'
    "></mat-error>
</ng-container>
