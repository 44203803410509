<div mat-dialog-title>
  <div>CHANGE OWNER</div>
  <div class="close-icon-container" (click)="onNoClick()">
    <fa-icon [icon]="['far', 'xmark']" class="gray"></fa-icon>
  </div>
</div>
<mat-divider></mat-divider>
<div mat-dialog-content>
  <p class="line-1">
    Are you sure you want to change ownership of {{ data.siteName }} from
    <span class="owner-name">{{ data.previousOwner }}</span>
    to
    <span class="owner-name">{{ data.newOwner }}</span>
    ?
  </p>
</div>
<div mat-dialog-actions>
  <button
    data-cy="button-no"
    mat-stroked-button
    color="primary"
    [mat-dialog-close]="false">
    No, Cancel
  </button>
  <button
    data-cy="button-yes"
    mat-raised-button
    color="primary"
    [mat-dialog-close]="true">
    Yes, Confirm
  </button>
</div>
