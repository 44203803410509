import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter } from 'rxjs/operators';

import { Spinner } from './spinner.enum';

@Injectable({
  providedIn: 'root',
})
export class SpinnerService {
  // @ts-ignore
  public spinnerObservable = new BehaviorSubject<Spinner>(null);
  private _loading = new BehaviorSubject<boolean>(false);
  public readonly loading$ = this._loading.asObservable();

  getSpinner(name: string): Observable<Spinner> {
    return this.spinnerObservable
      .asObservable()
      .pipe(filter((x: Spinner) => x && x.name === name));
  }

  show(
    name: string = 'primary',
    enhanced: boolean = false,
    text?: string,
    spinner?: Spinner
  ) {
    return new Promise((resolve, _reject) => {
      setTimeout(() => {
        if (spinner && Object.keys(spinner).length) {
          spinner.name = name;
          this.spinnerObservable.next(new Spinner({ ...spinner, show: true }));
          this._loading.next(true);
          resolve(true);
        } else {
          this.spinnerObservable.next(
            new Spinner({ name, enhanced, text, show: true })
          );
          this._loading.next(true);
          resolve(true);
        }
      }, 10);
    });
  }

  hide(name: string = 'primary', debounce: number = 10) {
    return new Promise((resolve, _reject) => {
      setTimeout(() => {
        this.spinnerObservable.next(new Spinner({ name, show: false }));
        this._loading.next(false);
        resolve(true);
      }, debounce);
    });
  }
}
