import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { DialogDownForMaintenanceComponent } from '../../shared/dialogs/dialog-down-for-maintenance/dialog-down-for-maintenance.component';

@Injectable({
  providedIn: 'root',
})
export class MaintenanceModeService {
  private isInMaintenanceMode = false;

  constructor(public dialog: MatDialog) {}

  enterMaintenanceMode() {
    if (!this.isInMaintenanceMode) {
      this.isInMaintenanceMode = true;
      this.dialog.open(DialogDownForMaintenanceComponent, {
        panelClass: 'fc-yes-no-dialog',
        data: {
          title: 'Site Down for Maintenance',
          line1:
            "We're making some updates to enhance your CORE Carbon experience.",
          line2: "We'll be back soon!",
        },
      });
    }
  }
}
