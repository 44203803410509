import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { PremiumAcreageUser } from 'src/app/shared/models/premium-acreage-user.model';

import { environment } from '../../../environments/environment';
import { deserialize } from '../../shared/models/base-helper';

export interface CSVResponse {
  csv_string: string;
}

@Injectable({
  providedIn: 'root',
})
export class PremiumAcreageUserService {
  apiUrl = environment.apiUrl + '/premium_acreage_users/';

  constructor(readonly http: HttpClient) {}

  createPremiumAcreageUser(newPAUser: any): Observable<PremiumAcreageUser> {
    return this.http
      .post(this.apiUrl, newPAUser)
      .pipe(
        map((resp: any) =>
          resp.user ? deserialize(resp.user, PremiumAcreageUser) : null
        )
      );
  }

  getPremiumAcreageUsersCSV(): Observable<string> {
    return this.http
      .get<CSVResponse>(`${this.apiUrl}csv`)
      .pipe(map((response: CSVResponse) => response.csv_string));
  }
}
