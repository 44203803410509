import { Component, DestroyRef, inject, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { CognitoUser } from 'amazon-cognito-identity-js';

import { User } from '../../shared/models';
import * as fromApp from '../../store';
import { selectCurrentUser } from '../../store/auth/auth.selectors';

export enum AuthMode {
  changePassword = 'changePassword',
  createAccount = 'createAccount',
  forgotPassword = 'forgotPassword',
  login = 'login',
  resetPassword = 'resetPassword',
}

const AuthModes: AuthMode[] = [
  AuthMode.changePassword,
  AuthMode.createAccount,
  AuthMode.forgotPassword,
  AuthMode.login,
  AuthMode.resetPassword,
];

@Component({
  selector: 'fc-auth',
  templateUrl: './auth.component.html',
  styleUrls: ['./auth.component.scss'],
})
export class AuthComponent implements OnInit {
  authModeEnum = AuthMode;
  cognitoUser?: CognitoUser;
  email?: string;
  isFormDirty?: boolean;
  password?: string;

  _authMode?: AuthMode;

  destroyRef = inject(DestroyRef);

  constructor(
    private router: Router,
    readonly route: ActivatedRoute,
    readonly store: Store<fromApp.AppState>
  ) {
    this.route.queryParamMap
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: queryParams => {
          const email = queryParams.get('email');
          const password = queryParams.get('password');
          const mode = queryParams.get('mode') as AuthMode | undefined;

          if (email) {
            this.email = email;
          }
          if (password) {
            this.password = password;
          }
          if (mode && AuthModes.includes(mode)) {
            this._authMode = mode;
          }
          if (!this._authMode) {
            this._authMode = AuthMode.login;
          }
          if (this._authMode === AuthMode.login) {
            this.isFormDirty = false;
          }
        },
      });
  }

  ngOnInit(): void {
    this.store
      .select(selectCurrentUser)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: (currentUser: User | null) => {
          if (currentUser && this._authMode === AuthMode.login) {
            this.router.navigateByUrl('/');
          }
        },
        error: (error: any) => {
          throw error;
        },
      });
  }

  get authMode(): AuthMode | undefined {
    return this._authMode;
  }

  set authMode(val: AuthMode | undefined) {
    val = val as AuthMode;
    if (this._authMode !== val) {
      this._authMode = val;
      let queryParams = {
        mode: this._authMode,
        email: this._authMode === AuthMode.resetPassword ? this.email : null,
      };
      this.router.navigate([], { queryParams });
    }
  }
}
