import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';
import { NgControl } from '@angular/forms';
import {
  DateFilterFn,
  MatDatepickerInputEvent,
} from '@angular/material/datepicker';

import { FCControlComponent } from './fc-control.component';

@Component({
  selector: 'fc-datepicker',
  templateUrl: './fc-datepicker.component.html',
  styleUrls: ['./fc-datepicker.component.scss'],
})
export class FCDatepickerComponent
  extends FCControlComponent<Date>
  implements OnInit
{
  formattedDate: string;
  invalidDate: boolean = false;

  @Input() formFieldClass: string;
  @Input() inputClass: string;
  @Input() inputID: string;
  @Input() placeHolder: string;
  @Input() dateFilterErrorMessage: string;
  @Input() required: boolean = true;
  @Input() label: string | HTMLElement;

  @Input() public datepickerFilter: DateFilterFn<Date>;
  @Input() public minDate: Date;
  @Input() public maxDate: Date;

  @ViewChild('dateInput') dateInput;
  @ViewChild('picker') datePicker;

  public dateChange: EventEmitter<Date> = new EventEmitter();

  constructor(control: NgControl) {
    super(control);
  }

  ngOnInit(): void {
    this.setDateValue(this.value);
  }

  setDateValue(dateValue): null | Date {
    if (dateValue) {
      this.formattedDate = dateValue;
      const newDate = new Date(dateValue);
      if (newDate.toString() === 'Invalid Date') {
        this.invalidDate = true;
        this.invalid = true;
        return null;
      } else {
        this.formattedDate = newDate.toLocaleDateString();
        this.invalidDate = false;
        this.invalid = false;
        return newDate;
      }
    }
  }

  onBlur() {
    this.onTouched();
    const date = this.setDateValue(this.dateInput.nativeElement.value);
    this.datePicker.select(date);
    this.onChange(date);
  }

  onDateChange(dateInput: MatDatepickerInputEvent<Date>) {
    this.onTouched();
    this.onChange(dateInput.value);
    if (this.value) {
      this.formattedDate = this.value.toLocaleDateString();
      this.invalidDate = false;
      this.invalid = false;
    }
    this.dateChange.next(this.value);
  }
}
