import { Component, DestroyRef, inject, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { isEmpty } from 'lodash';
import { distinctUntilChanged, filter } from 'rxjs/operators';

import { selectPreferredBuyer } from 'src/app/store/buyer/buyer.selectors';
import { getAdminNotifications } from 'src/app/store/notification/notification.actions';
import { selectActiveSite } from 'src/app/store/site/site.selectors';

import { environment } from '../../../environments/environment';
import { BuyerService } from '../../core/api/buyer.service';
import { EditMapDeactivateModalService } from '../../core/auth/edit-map-deactivate-modal.service';
import { RedirectService } from '../../core/helper/redirect.service';
import { AuthMode } from '../../feature/auth/auth.component';
import * as fromApp from '../../store';
import {
  selectAppConfigState,
  selectIsMobileView,
} from '../../store/app-config/app-config.selectors';
import { logout } from '../../store/auth/auth.actions';
import { AuthState } from '../../store/auth/auth.reducer';
import {
  selectActAsUser,
  selectAuthState,
  selectCurrentUser,
} from '../../store/auth/auth.selectors';
import { selectNotificationState } from '../../store/notification/notification.selectors';
import { setUnsavedChanges } from '../../store/parcel/parcel.actions';
import { RoleEnum } from '../constants/role.enums';
import { Buyer, Site, User } from '../models';

@Component({
  selector: 'fc-app-header',
  templateUrl: './app-header.component.html',
  styleUrls: ['./app-header.component.scss'],
})
export class AppHeaderComponent implements OnInit {
  accountMenuOpen = false;
  isMenuOpen: any = {};
  actAsUser: User = null;
  activeSite: Site;
  adminFailedVerificationUsersCount: number = 0;
  authModeEnum = AuthMode;
  authState: AuthState = null;
  currentUser: User = null;
  env = environment.env;
  infoMessage = '';
  isAdmin: boolean = false;
  isLimitedAdmin: boolean = false;
  isMobile: boolean = false;
  isSuperAdmin: boolean = false;
  landOwnerAccountNotificationBadgeCount = 0;
  loggedIn = false;
  roles = RoleEnum;
  showInfoMessage = false;
  statePreferredBuyer: Buyer = null;

  destroyRef = inject(DestroyRef);

  getOpenAssetURL = BuyerService.getOpenAssetURL;

  constructor(
    readonly store: Store<fromApp.AppState>,
    readonly router: Router,
    readonly editMapDeactivateModalService: EditMapDeactivateModalService,
    readonly redirectService: RedirectService
  ) {}

  ngOnInit() {
    this.store
      .select(selectAppConfigState)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(appConfig => {
        if (typeof appConfig.config['banner_message'] !== 'undefined') {
          this.infoMessage = appConfig.config['banner_message'];
          this.showInfoMessage = this.infoMessage.trim() !== '';
        }
      });
    this.store
      .select(selectAuthState)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(authState => {
        this.loggedIn = !isEmpty(authState.token);
        this.authState = authState;
      });

    this.store
      .select(selectNotificationState)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(notificationState => {
        this.landOwnerAccountNotificationBadgeCount = 0;
        if (
          this.currentUser &&
          notificationState.landOwner.userId &&
          this.currentUser.id === notificationState.landOwner.userId
        ) {
          this.landOwnerAccountNotificationBadgeCount +=
            notificationState.landOwner.paymentsAccountRequiresSetup;
          if (notificationState.landOwner.stripeAccountRequiresAction) {
            this.landOwnerAccountNotificationBadgeCount++;
          }
        }

        this.adminFailedVerificationUsersCount =
          notificationState.admin.failedVerificationUsersCount;
      });

    this.store
      .select(selectCurrentUser)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(currentUser => {
        this.currentUser = currentUser;
        this.isSuperAdmin = currentUser && currentUser.isSuperAdmin;
        this.isAdmin = currentUser && currentUser.isAdmin;
        this.isLimitedAdmin = currentUser && currentUser.isLimitedAdmin;
        if (this.isAdmin) {
          this.store.dispatch(getAdminNotifications());
        }
      });

    this.store
      .select(selectActAsUser)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(actAsUser => {
        if (actAsUser) {
          this.actAsUser = new User(actAsUser);
        } else {
          this.actAsUser = null;
        }
      });

    this.store
      .select(selectIsMobileView)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(mobileViewMode => {
        this.isMobile = mobileViewMode;
      });

    this.store
      .select(selectActiveSite)
      .pipe(
        filter(site => !isEmpty(site)),
        distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b)),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe(activeSite => {
        this.activeSite = activeSite;
      });

    this.store
      .select(selectPreferredBuyer)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(preferredBuyer => {
        this.statePreferredBuyer = preferredBuyer ? preferredBuyer : null;
      });
  }

  logout(): void {
    if (EditMapDeactivateModalService.getUnsavedChangesStatus()) {
      this.editMapDeactivateModalService.openForLogout();
    } else {
      this.store.dispatch(logout());
      this.store.dispatch(
        setUnsavedChanges({ data: { unsavedChanges: false } })
      );
    }
  }

  menuOpened() {
    this.accountMenuOpen = true;
  }

  menuClosed() {
    this.accountMenuOpen = false;
  }

  hasRoute(routeString: string): boolean {
    return this.router.url.includes(routeString);
  }

  isHomeRoute(): boolean {
    return (
      this.router.url === '/' ||
      this.router.url[1] === '#' ||
      this.router.url[1] === '?'
    );
  }

  navigate(): void {
    this.redirectService.redirectToDashboard();
  }

  get accountName(): string | null {
    if (this.currentUser) {
      return this.currentUser.firstName || this.currentUser.email;
    }
    return null;
  }

  get isVerified(): boolean {
    return !this.currentUser || this.currentUser.isVerified;
  }

  get preferredBuyer(): Buyer {
    return this.currentUser && this.currentUser.preferredBuyer
      ? this.currentUser.preferredBuyer
      : this.statePreferredBuyer;
  }

  closeInfoMessage() {
    this.showInfoMessage = false;
  }
}
