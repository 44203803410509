import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { SiteVerificationVisit } from 'src/app/shared/models/site-verification-visit.model';
import { environment } from 'src/environments/environment';

import { deserialize } from '../../shared/models/base-helper';

export interface SuccessResponse {
  success: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class SiteVerificationVisitsService {
  apiUrl = environment.apiUrl + '/site_verification_visits/';

  constructor(readonly http: HttpClient) {}

  getSiteVerificationVisits(id: number): Observable<SiteVerificationVisit[]> {
    return this.http
      .get<any[]>(`${this.apiUrl}${id}`)
      .pipe(
        map(visits =>
          visits.map((serializedSiteVerificationVisit: any) =>
            deserialize(serializedSiteVerificationVisit, SiteVerificationVisit)
          )
        )
      );
  }

  postSiteVerificationVisit(
    id: number,
    visitData: SiteVerificationVisit
  ): Observable<SiteVerificationVisit> {
    const visit = {
      date: visitData.date,
      verification_type: visitData.verificationType,
    };

    return this.http
      .post<any>(`${this.apiUrl}${id}`, visit)
      .pipe(
        map((serializedSiteVerificationVisit: any) =>
          deserialize(serializedSiteVerificationVisit, SiteVerificationVisit)
        )
      );
  }

  patchSiteVerificationVisit(
    id: number,
    visitData: SiteVerificationVisit
  ): Observable<SiteVerificationVisit> {
    const visit = {
      date: visitData.date,
      verification_type: visitData.verificationType,
    };

    return this.http
      .patch<any>(`${this.apiUrl}${id}`, visit)
      .pipe(
        map((serializedSiteVerificationVisit: any) =>
          deserialize(serializedSiteVerificationVisit, SiteVerificationVisit)
        )
      );
  }

  deleteSiteVerificationVisit(id: number): Observable<SuccessResponse> {
    return this.http.delete<SuccessResponse>(`${this.apiUrl}${id}`);
  }
}
