<div *ngIf="goodNews; else showBadNews">
  <div *ngIf="!returnEmail; else showConfirmationDialog">
    <div mat-dialog-title>
      <div>Good News!</div>
      <div class="close-icon-container" (click)="onNoClick()">
        <fa-icon [icon]="['far', 'xmark']" class="gray"></fa-icon>
      </div>
    </div>
    <mat-divider></mat-divider>
    <div mat-dialog-content>
      <form (keydown.enter)="$event.preventDefault()" [formGroup]="signupForm">
        <p>Your selected county is available in our CORE program.</p>
        <p>
          If you would like to sign up for an account and continue setting up
          your site, please confirm that you would like to receive an email
          invite by clicking 'Request Access'.
        </p>
        <div class="email-label">Email</div>
        <mat-form-field class="full-width">
          <input matInput type="email" id="email" formControlName="email" />
        </mat-form-field>
        <fc-email-form-errors
          [emailFormControl]="signupForm.controls.email"></fc-email-form-errors>
        <mat-error *ngIf="errorMessage">{{ errorMessage }}</mat-error>
      </form>
    </div>
    <div mat-dialog-actions>
      <button mat-stroked-button color="primary" (click)="onNoClick()">
        Cancel
      </button>
      <button
        mat-raised-button
        color="primary"
        data-cy="button-request-access"
        [disabled]="signupForm.invalid"
        (click)="onYesClick()">
        Request Access
      </button>
    </div>
  </div>

  <ng-template #showConfirmationDialog>
    <div mat-dialog-title>
      <div>Confirmation Email Sent</div>
      <div class="close-icon-container" (click)="onNoClick()">
        <fa-icon [icon]="['far', 'xmark']" class="gray"></fa-icon>
      </div>
    </div>
    <mat-divider></mat-divider>
    <div mat-dialog-content>
      <p>Please check your email for a link to sign in.</p>
    </div>
    <div mat-dialog-actions>
      <button mat-raised-button color="primary" (click)="onNoClick()">
        Close
      </button>
    </div>
  </ng-template>
</div>

<ng-template #showBadNews>
  <div mat-dialog-title>
    <div>We’re Sorry</div>
    <div class="close-icon-container" (click)="onNoClick()">
      <fa-icon [icon]="['far', 'xmark']" class="gray"></fa-icon>
    </div>
  </div>
  <mat-divider></mat-divider>
  <div mat-dialog-content>
    <form
      *ngIf="!ineligibleAreaUserCreate; else showConfirmation"
      (keydown.enter)="$event.preventDefault()"
      [formGroup]="signupForm">
      <p>
        Your selected state and county are not currently eligible for our
        program.
      </p>
      <p>
        Would you like us to contact you if our program becomes available in
        your selected area?
      </p>
      <div class="email-label">Email</div>
      <mat-form-field class="full-width">
        <input matInput type="email" id="email" formControlName="email" />
      </mat-form-field>
      <fc-email-form-errors
        [emailFormControl]="signupForm.controls.email"></fc-email-form-errors>
    </form>
    <ng-template #showConfirmation>
      <p>
        Thank you for your information, our team will be in touch when CORE is
        available in your area.
      </p>
    </ng-template>
  </div>
  <div mat-dialog-actions>
    <button
      *ngIf="!ineligibleAreaUserCreate"
      (click)="onNoClick()"
      color="primary"
      data-cy="button-no"
      mat-stroked-button>
      No, Thank You
    </button>
    <button
      (click)="onYesClick()"
      [disabled]="!signupForm.get('email').value"
      color="primary"
      data-cy="button-yes"
      mat-raised-button>
      {{ ineligibleAreaUserCreate ? 'Close' : 'Yes, Contact Me' }}
    </button>
  </div>
</ng-template>
