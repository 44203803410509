<mat-accordion
  *ngFor="let faqItem of faqData"
  class="faq-heading-accordion"
  hideToggle="true"
  multi>
  <mat-expansion-panel #faqExpansionPanel>
    <mat-expansion-panel-header>
      <mat-panel-title>
        <fa-icon
          *ngIf="faqExpansionPanel.expanded"
          [icon]="['fas', 'circle-minus']"></fa-icon>
        <fa-icon
          *ngIf="!faqExpansionPanel.expanded"
          [icon]="['fas', 'circle-plus']"></fa-icon>
        {{ faqItem.heading }}
      </mat-panel-title>
    </mat-expansion-panel-header>

    <mat-accordion class="faq-question-accordion" hideToggle="true" multi>
      <mat-expansion-panel
        #questionExpansionPanel
        *ngFor="let faqQA of faqItem.questions">
        <mat-expansion-panel-header>
          <mat-panel-title>
            <fa-icon
              *ngIf="!questionExpansionPanel.expanded"
              [icon]="['fas', 'plus']"
              class="green"></fa-icon>
            <fa-icon
              *ngIf="questionExpansionPanel.expanded"
              [icon]="['fas', 'minus']"
              class="green"></fa-icon>

            {{ faqQA.question }}
          </mat-panel-title>
        </mat-expansion-panel-header>
        <div class="faq-question-container">
          <p [innerHTML]="faqQA.answer1"></p>
          <p *ngIf="faqQA.answer2" [innerHTML]="faqQA.answer2"></p>
        </div>
      </mat-expansion-panel>
    </mat-accordion>
  </mat-expansion-panel>
</mat-accordion>
<div class="bottom-border"></div>

<div class="legal-disclaimer">{{ stripeDisclaimer }}</div>
