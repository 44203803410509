import { Component, Inject, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CognitoUser } from 'amazon-cognito-identity-js';
import { Auth } from 'aws-amplify';

export interface OTPDialogData {
  email?: string;
  mode: 'verify-email' | 'verify-phone' | 'authenticate';
  password?: string;
  user?: CognitoUser;
  verificationCodeSent?: boolean;
}

@Component({
  selector: 'fc-otp-dialog',
  styleUrls: ['./otp-dialog.component.scss'],
  templateUrl: 'otp-dialog.component.html',
})
export class OtpDialogComponent implements OnInit {
  email?: string;
  errorMessage?: string;
  mode: 'verify-email' | 'verify-phone' | 'authenticate';
  otpForm: FormGroup = this.formBuilder.group({
    otp: new FormControl(null, [
      Validators.required,
      Validators.minLength(6),
      Validators.maxLength(6),
    ]),
  });
  password?: string;
  user?: CognitoUser;
  verificationCodeSent: boolean;

  constructor(
    private dialogRef: MatDialogRef<OtpDialogComponent>,
    private formBuilder: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: OTPDialogData
  ) {
    this.email = data.email;
    this.mode = data.mode;
    this.password = data.password;
    this.user = data.user;
    this.verificationCodeSent = data.verificationCodeSent
      ? data.verificationCodeSent
      : false;
  }

  ngOnInit() {
    if (this.mode === 'verify-phone' || this.mode === 'verify-email') {
      this.sendOtp();
    }
  }

  sendOtp(): void {
    if (this.mode === 'verify-phone' || this.mode === 'verify-email') {
      const attr = this.mode === 'verify-phone' ? 'phone_number' : 'email';
      Auth.verifyCurrentUserAttribute(attr).then(
        () => {
          this.verificationCodeSent = true;
        },
        error => {
          this.errorMessage = error.message;
          console.log(error); // TODO review-error-handling
        }
      );
    } else if (this.mode === 'authenticate' && this.email && this.password) {
      Auth.signIn(this.email, this.password).then(response => {
        this.user = response;
      });
    }
  }

  closeDialog(): void {
    this.dialogRef.close(false);
  }

  submitOtp(): void {
    if (this.mode === 'verify-phone' || this.mode === 'verify-email') {
      const attr = this.mode === 'verify-phone' ? 'phone_number' : 'email';
      Auth.verifyCurrentUserAttributeSubmit(attr, this.otpForm.value.otp).then(
        response => {
          this.errorMessage = undefined;
          this.dialogRef.close(true);
        },
        error => {
          this.errorMessage = error.message;
          console.log(error); // TODO review-error-handling
        }
      );
    } else {
      Auth.confirmSignIn(this.user, this.otpForm.value.otp, 'SMS_MFA')
        .then(response => {
          this.errorMessage = undefined;
          this.dialogRef.close(response);
        })
        .catch(error => {
          this.errorMessage = error.message;
          console.log(error); // TODO review-error-handling
        });
    }
  }
}
