import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, concatMap, map } from 'rxjs/operators';

import * as CohortActions from './cohort.actions';

import { CohortService } from '../../core/api/cohort.service';
import { AppState } from '../index';

@Injectable()
export class CohortEffects {
  getCohorts$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CohortActions.getCohorts),
      concatMap(action => {
        return this.cohortService.getFilteredCohorts(action.filter).pipe(
          map(data => CohortActions.getCohortsSuccess({ data })),
          catchError(error => of(CohortActions.getCohortsFailure({ error })))
        );
      })
    );
  });

  constructor(
    readonly actions$: Actions,
    readonly cohortService: CohortService,
    readonly store: Store<AppState>
  ) {}
}
