import { createAction, props } from '@ngrx/store';

export const getAllBuyers = createAction('[Buyer] Get All Buyers');

export const getAllBuyersSuccess = createAction(
  '[Buyer] Get All Buyers Success',
  props<{ data: any }>()
);

export const getAllBuyersFailure = createAction(
  '[Buyer] Get All Buyers Failure',
  props<{ error: any }>()
);

export const getBuyer = createAction(
  '[Buyer] Get Buyer',
  props<{ referrerCode: number }>()
);

export const getBuyerSuccess = createAction(
  '[Buyer] Get Buyer Success',
  props<{ data: any }>()
);

export const getBuyerFailure = createAction(
  '[Buyer] Get Buyer Failure',
  props<{ error: any }>()
);

export const clearPreferredBuyer = createAction('[Buyer] Clear Active Buyer');

export const setPreferredBuyer = createAction(
  '[Buyer] Set Preferred Buyer',
  props<{ referrerCode: number }>()
);

export const setPreferredBuyerSuccess = createAction(
  '[Buyer] Set Preferred Buyer Success',
  props<{ data: any }>()
);

export const setPreferredBuyerFailure = createAction(
  '[Buyer] Set Preferred Buyer Failure',
  props<{ error: any }>()
);
