<div mat-dialog-title>
  <div>Parcels Not Available</div>
  <div class="close-icon-container" (click)="onNoClick()">
    <fa-icon [icon]="['far', 'xmark']" class="gray"></fa-icon>
  </div>
</div>
<mat-divider></mat-divider>
<div mat-dialog-content>
  <p>
    We’re sorry, one or more of the parcels you have selected are not yet
    available in your area.
  </p>
  <ul>
    <li *ngFor="let parcel of parcels">
      {{ parcel }}
    </li>
  </ul>
  <p>
    Please remove these parcels to begin your site. If you have any questions
    please contact us.
  </p>
</div>
<div mat-dialog-actions>
  <button mat-raised-button color="primary" [mat-dialog-close]="true">
    Ok, Got It
  </button>
</div>
