import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, concatMap, map } from 'rxjs/operators';

import * as SiteNoteActions from './site-note.actions';

import { SiteNoteService } from '../../core/api/site-note.service';
import { AppState } from '../index';

@Injectable()
export class SiteNoteEffects {
  getSiteNotes$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(SiteNoteActions.getSiteNotes),
      concatMap(action => {
        return this.siteNoteService.getSiteNotes(action.siteId).pipe(
          map(data => SiteNoteActions.getSiteNotesSuccess({ data })),
          catchError(error =>
            of(SiteNoteActions.getSiteNotesFailure({ error }))
          )
        );
      })
    );
  });

  createSiteNote$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(SiteNoteActions.createSiteNote),
      concatMap(action => {
        return this.siteNoteService
          .createSiteNote(
            action.siteId,
            action.note,
            action.hideFromLimitedAdmins
          )
          .pipe(
            map(data => SiteNoteActions.createSiteNoteSuccess({ data })),
            catchError(error =>
              of(SiteNoteActions.createSiteNoteFailure({ error }))
            )
          );
      })
    );
  });

  constructor(
    readonly actions$: Actions,
    readonly siteNoteService: SiteNoteService,
    readonly store: Store<AppState>
  ) {}
}
