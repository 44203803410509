<div mat-dialog-title class="change-site-state">
  CHANGE SITE STATUS
  <div class="close-icon-container" (click)="onNoClick()">
    <fa-icon [icon]="['far', 'xmark']" class="gray"></fa-icon>
  </div>
</div>
<mat-divider></mat-divider>
<div mat-dialog-content>
  <div class="status-wrapper">
    <span class="status-label">Status:</span>
    <span class="status">
      {{ changeSiteStateForm.get('currentState').value }}
    </span>
  </div>

  <form [formGroup]="changeSiteStateForm" class="site-form">
    <span class="change-to-label">Change to:</span>
    <mat-form-field class="status-select">
      <mat-select
        formControlName="transitionToState"
        placeholder="New Site State">
        <mat-option *ngFor="let status of data.statuses" [value]="status">
          {{ status }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </form>
  <ng-container *ngIf="changeSiteStateForm.get('transitionToState').value">
    <p class="line-1">
      You are about to change {{ data.sites.length }} site(s) from
      <strong>{{ changeSiteStateForm.get('currentState').value }}</strong>
      to
      <strong>
        {{
          changeSiteStateForm.get('transitionToState').value
            ? changeSiteStateForm.get('transitionToState').value
            : 'No Role'
        }}
      </strong>
    </p>
  </ng-container>
  <ng-container *ngIf="errorMessage">
    <div class="message-error">ERROR: {{ errorMessage }}</div>
  </ng-container>
</div>
<div mat-dialog-actions>
  <button
    mat-stroked-button
    color="primary"
    [mat-dialog-close]="false"
    cdkFocusInitial>
    {{ data.buttonTextNo }}
  </button>
  <button
    data-cy="button-yes"
    mat-raised-button
    color="primary"
    class="green-shadow-button"
    [disabled]="!changeSiteStateForm.valid"
    (click)="doChangeSitesState()">
    {{ data.buttonTextYes }}
  </button>
</div>
