import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

import { AuthGuard } from './core/auth/auth.guard';
import { NotInviteOnlyGuard } from './core/auth/not-invite-only-guard.service';
import { VerifiedGuard } from './core/auth/verified.guard';
import { CreateAccountComponent } from './feature/create-account/create-account-page/create-account.component';
import { ConnectivityComponent } from './feature/diagnostics/connectivity/connectivity.component';
import { Error404Component } from './feature/home/error404/error404.component';
import { HelpPageComponent } from './feature/home/help-page/help-page.component';
import { HomeComponent } from './feature/home/home/home.component';
import { NewSitePageComponent } from './feature/site/new-site-page/new-site-page.component';
import { NewSiteStepOneComponent } from './shared/components/new-site-step-one/new-site-step-one.component';
import { RoleEnum } from './shared/constants/role.enums';

const routes: Routes = [
  {
    path: 'auth',
    loadChildren: () =>
      import('./feature/auth/auth.module').then(m => m.AuthModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'admin',
    canActivate: [AuthGuard],
    data: {
      roles: [
        RoleEnum.Admin,
        RoleEnum.SuperAdmin,
        RoleEnum.LimitedAdmin,
        RoleEnum.FinanceAdmin,
      ],
    },
    loadChildren: () =>
      import('./feature/admin/admin.module').then(m => m.AdminModule),
  },
  {
    path: 'account',
    canActivate: [AuthGuard],
    data: { roles: [RoleEnum.Landowner] },
    loadChildren: async () =>
      (await import('./feature/account/account.module')).AccountModule,
  },
  {
    path: 'site',
    canActivate: [AuthGuard],
    data: { roles: [RoleEnum.Landowner] },
    loadChildren: () =>
      import('./feature/site/site.module').then(m => m.SiteModule),
  },
  {
    path: 'legal',
    loadChildren: () =>
      import('./feature/legal/legal.module').then(m => m.LegalModule),
  },
  {
    path: 'connectivity',
    component: ConnectivityComponent,
  },
  {
    path: 'create-account',
    component: CreateAccountComponent,
  },
  {
    path: 'start-site',
    canActivate: [VerifiedGuard, NotInviteOnlyGuard],
    component: NewSitePageComponent,
    children: [
      {
        path: '',
        component: NewSiteStepOneComponent,
        pathMatch: 'full',
      },
    ],
  },
  {
    path: 'help',
    component: HelpPageComponent,
  },
  {
    path: '',
    component: HomeComponent,
    pathMatch: 'full',
  },
  { path: '**', component: Error404Component },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      preloadingStrategy: PreloadAllModules,
      // This instructs the Angular router to compute (re-compute) the URL after a canceled navigation, ensuring that the browser's URL bar reflects the correct state of your application.
      // This might become the default behavior in the future
      // See: https://github.com/angular/angular/issues/13586#issuecomment-898704702
      canceledNavigationResolution: 'computed',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
