import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { get } from 'lodash';

@Injectable({
  providedIn: 'root',
})
export class ErrorService {
  // parses the http error response to get the first message
  getDisplayMessage(httpError: HttpErrorResponse): string {
    const detail =
      get(httpError, 'error.detail') || get(httpError, 'error[0].detail');
    if (detail) {
      const firstMessage = this.getDetailMessage(detail);
      return firstMessage || detail;
    } else {
      return (
        httpError.message || httpError.statusText || 'Failed to upload file'
      );
    }
  }

  getDetailMessage(detail: object | string): string {
    if (typeof detail === 'string') {
      return detail;
    }

    const loc = get(detail, '[0].loc');
    const msg = get(detail, '[0].msg');
    if (loc && msg) {
      return `${loc} ${msg}`;
    } else if (msg) {
      return msg;
    }

    return detail.toString();
  }
}
