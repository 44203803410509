import {
  Directive,
  ElementRef,
  HostListener,
  Input,
  Optional,
  Renderer2,
} from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[regexFilter]',
})
export class RegexFilterDirective {
  @Input() regexFilter?: string;

  constructor(
    private el: ElementRef,
    private renderer: Renderer2,
    @Optional() private formControl: NgControl
  ) {}

  updateValue(value: string) {
    this.renderer.setProperty(this.el.nativeElement, 'value', value);

    if (this.formControl && this.formControl.control) {
      this.formControl.control.setValue(value);
    }
  }

  @HostListener('input', ['$event']) onInput(event: Event) {
    const inputElement = event.target as HTMLInputElement;
    const value = inputElement.value;

    if (this.regexFilter) {
      const regex = new RegExp(this.regexFilter, 'g');
      const filteredValue = value.match(regex)?.join('') || '';
      this.updateValue(filteredValue);
    }
  }

  @HostListener('paste', ['$event']) onPaste(event: ClipboardEvent) {
    event.preventDefault();
    const clipboardData = event.clipboardData || (window as any).clipboardData;
    let pastedText = clipboardData.getData('text');

    if (this.regexFilter) {
      const regex = new RegExp(this.regexFilter, 'g');
      const filteredValue = pastedText.match(regex)?.join('') || '';
      this.updateValue(filteredValue);
    }
  }
}
