import { DestroyRef, inject, Injectable } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatDialog } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { isEmpty } from 'lodash';
import { Subject } from 'rxjs';
import { distinctUntilChanged, filter } from 'rxjs/operators';

import { resetStands } from 'src/app/store/parcel/parcel.actions';

import { DialogYesNoComponent } from '../../shared/dialogs/dialog-yes-no/dialog-yes-no.component';
import { Site } from '../../shared/models';
import * as fromApp from '../../store';
import { logout } from '../../store/auth/auth.actions';
import { hasUnsavedChanges } from '../../store/parcel/parcel.selectors';
import { selectActiveSite } from '../../store/site/site.selectors';

@Injectable({
  providedIn: 'root',
})
export class EditMapDeactivateModalService {
  destroyRef = inject(DestroyRef);

  static isLoggingOut = false;
  private static mapHasUnsavedChanges = false;

  navigateAwaySelection$: Subject<boolean> = new Subject<boolean>();

  activeSite: Site;

  constructor(
    public dialog: MatDialog,
    readonly store: Store<fromApp.AppState>
  ) {
    this.store
      .select(hasUnsavedChanges)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(
        res => (EditMapDeactivateModalService.mapHasUnsavedChanges = res)
      );

    this.store
      .select(selectActiveSite)
      .pipe(
        filter(site => !isEmpty(site)),
        distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b)),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe(activeSite => {
        this.activeSite = activeSite;
      });
  }

  static getUnsavedChangesStatus() {
    return this.mapHasUnsavedChanges;
  }

  open() {
    const dialogRef = this.dialog.open(DialogYesNoComponent, {
      panelClass: 'fc-yes-no-dialog',
      data: {
        title: 'CAUTION - If you navigate away, your map will be lost!',
        line1:
          'Leaving this screen without saving your map will cause all of your unsaved progress on your Carbon Site to be lost.  We suggest saving your site before leaving the map tool',
        line2:
          'There are two ways to save your site; first you must return to your map and click save at the bottom of your stand selection panel, or hit the save icon in the map toolbar.',
        buttonTextYes: "Don't Leave Map",
        buttonTextNo: 'Continue Without Saving',
      },
      autoFocus: false,
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result !== null && result === false) {
        this.navigateAwaySelection$.next(true);
        let stands = [];

        this.activeSite?.versions[0].stands?.forEach(stand => {
          stands = stands.concat(stand);
        });

        this.store.dispatch(resetStands({ data: { stands } }));
      } else {
        this.navigateAwaySelection$.next(false);
      }
    });
  }

  openForLogout() {
    const dialogRef = this.dialog.open(DialogYesNoComponent, {
      panelClass: 'fc-yes-no-dialog',
      data: {
        title: 'CAUTION - If you navigate away, your map will be lost!',
        line1:
          'Leaving this screen without saving your map will cause all of your unsaved progress on your Carbon Site to be lost.  We suggest saving your site before leaving the map tool',
        line2:
          'There are two ways to save your site; first you must return to your map and click save at the bottom of your stand selection panel, or hit the save icon in the map toolbar.',
        buttonTextYes: "Don't Leave Map",
        buttonTextNo: 'Continue Without Saving',
      },
      autoFocus: false,
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result !== null && result === false) {
        EditMapDeactivateModalService.isLoggingOut = true;
        this.store.dispatch(logout());
      }
    });
  }
}
