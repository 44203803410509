import { Component, Input } from '@angular/core';
import { Store } from '@ngrx/store';

import { SiteHelperService } from '../../../core/helper/site.service';
import * as fromApp from '../../../store';
import { User } from '../../models';

@Component({
  selector: 'fc-quick-links-panel',
  templateUrl: './quick-links-panel.component.html',
  styleUrls: ['./quick-links-panel.component.scss'],
})
export class QuickLinksPanelComponent {
  @Input() currentUser: User;
  @Input() allowAdd: boolean;
  @Input() allowTerminate: boolean;

  constructor(
    readonly store: Store<fromApp.AppState>,
    readonly siteHelper: SiteHelperService
  ) {}

  showTerminatePage() {
    this.siteHelper.showTerminateDialog();
  }
}
