<div mat-dialog-title>
  <div>REMOVE OVERLAP</div>
  <div class="close-icon-container" (click)="buttonClick(false)">
    <fa-icon [icon]="['far', 'xmark']" class="gray"></fa-icon>
  </div>
</div>
<mat-divider></mat-divider>
<div mat-dialog-conten data-cy="remove-overlap-content">
  <p>
    Are you sure you want to remove all overlap with red flag layer? This will
    permanently change the site map and remove
    {{ site.publicLandOverlapAcres }} forested acres.
  </p>
</div>
<div mat-dialog-actions data-cy="remove-overlap-dialog-actions">
  <button mat-stroked-button color="primary" (click)="buttonClick(false)">
    No, Cancel
  </button>
  <button
    data-cy="remove-overlap-dialog-yes"
    mat-raised-button
    color="primary"
    (click)="buttonClick(true)">
    Yes, Confirm
  </button>
</div>
