<div mat-dialog-title>
  <div>{{ title }}</div>
  <div class="close-icon-container" (click)="onNoClick()">
    <fa-icon [icon]="['far', 'xmark']" class="gray"></fa-icon>
  </div>
</div>
<mat-divider></mat-divider>
<div mat-dialog-content>
  <p>{{ body1 }}</p>
  <p>{{ body2 }}</p>
</div>
<div mat-dialog-actions>
  <button
    *ngIf="!allowSubmit"
    data-cy="button-return-to-map"
    mat-raised-button
    color="primary"
    [mat-dialog-close]="false">
    Return to Map
  </button>
  <button
    *ngIf="allowSubmit"
    data-cy="button-return-to-map"
    mat-stroked-button
    color="primary"
    [mat-dialog-close]="false">
    Return to Map
  </button>
  <button
    data-cy="button-submit"
    *ngIf="allowSubmit"
    mat-raised-button
    color="primary"
    [mat-dialog-close]="true">
    Continue Submission
  </button>
</div>
