import { OverlayModule } from '@angular/cdk/overlay';
import { PortalModule } from '@angular/cdk/portal';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import {
  FormsModule,
  NG_VALIDATORS,
  ReactiveFormsModule,
} from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import {
  MAT_FORM_FIELD_DEFAULT_OPTIONS,
  MatFormFieldModule,
} from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSliderModule } from '@angular/material/slider';
import {
  MAT_SNACK_BAR_DEFAULT_OPTIONS,
  MatSnackBarModule,
} from '@angular/material/snack-bar';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTableModule } from '@angular/material/table';
import { MatTab, MatTabGroup, MatTabLabel } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import {
  FaConfig,
  FaIconLibrary,
  FontAwesomeModule,
} from '@fortawesome/angular-fontawesome';
import {
  faArrowPointer,
  faDrawPolygon,
  faDrawSquare,
  faFile,
  faFloppyDisk,
  faHandPointer,
  faLayerPlus,
  faMap,
  faPenNib,
  faSignsPost,
} from '@fortawesome/pro-light-svg-icons';
import {
  faArrowLeft,
  faBadgeDollar,
  faCircleCheck,
  faCircleInfo,
  faCircleQuestion,
  faDownload,
  faFlag,
  faLock,
  faPaperclip,
  faPencil,
  faArrowUpRightFromSquare as farArrowUpRightFromSquare,
  faEdit as farEdit,
  faMap as farMap,
  faTree as farTree,
  faTreeDeciduous as farTreeDeciduous,
  faSquareDashed,
  faSquareDashedCirclePlus,
  faTrash,
  faTrashUndo,
  faTriangleExclamation,
  faUpload,
  faUserShield,
  faXmark,
} from '@fortawesome/pro-regular-svg-icons';
import {
  faArrowUpRightFromSquare,
  faBars,
  faBorderAll,
  faCaretDown,
  faCaretUp,
  faChartSimple,
  faCheck,
  faCheckCircle,
  faChevronLeft,
  faChevronRight,
  faChevronsLeft,
  faChevronsRight,
  faCircle,
  faCircleExclamation,
  faCircleMinus,
  faCirclePlus,
  faCircleUser,
  faCircleXmark,
  faEdit,
  faExpandWide,
  faEye,
  faEyeSlash,
  faGauge,
  faMinus,
  faMoneyCheckDollar,
  faOctagon,
  faPaperPlane,
  faPenToSquare,
  faPlus,
  faQuestion,
  faQuestionCircle,
  faRoad,
  faRotateLeft,
  faCircleCheck as fasCircleCheck,
  faShieldHalved,
  faMap as faSolidMap,
  faStar,
  faTableColumns,
  faTree,
  faTreeDeciduous,
  faTriangle,
  faWater,
} from '@fortawesome/pro-solid-svg-icons';
import { NgxChartsModule } from '@swimlane/ngx-charts';

import { AppHeaderComponent } from './app-header/app-header.component';
import { UserActivityDashboardComponent } from './components/activity-dashboard/user-activity-dashboard.component';
import { DashboardBannerComponent } from './components/dashboard-banner/dashboard-banner.component';
import { DialogChangeSiteStateComponent } from './components/dialog-change-site-state/dialog-change-site-state.component';
import { DisturbanceReportComponent } from './components/disturbance-report/disturbance-report.component';
import { EnvRibbonComponent } from './components/env-ribbon/env-ribbon.component';
import { FaqComponent } from './components/faq/faq.component';
import { EmailFormErrorComponent } from './components/form-errors/email-form-error.component';
import { FCControlComponent } from './components/forms/fc-control.component';
import { FCDatepickerComponent } from './components/forms/fc-datepicker.component';
import { FCSortIconComponent } from './components/icons/sort-icon.component';
import { NewSiteStepOneComponent } from './components/new-site-step-one/new-site-step-one.component';
import { NewSiteStepOneAndAHalfComponent } from './components/new-site-step-one-and-a-half/new-site-step-one-and-a-half.component';
import { PeriodicReportComponent } from './components/periodic-report/periodic-report.component';
import { ProfilePageComponent } from './components/profile-page/profile-page.component';
import { ProgressBarComponent } from './components/progress-bar/progress-bar.component';
import { QuickLinksPanelComponent } from './components/quick-links-panel/quick-links-panel.component';
import { SiteCardComponent } from './components/site-card/site-card.component';
import { SiteDetailInformationComponent } from './components/site-detail-information/site-detail-information.component';
import { SiteDetailNotesComponent } from './components/site-detail-notes/site-detail-notes.component';
import { SiteDetailOverviewComponent } from './components/site-detail-overview/site-detail-overview.component';
import { SiteDetailPaymentsComponent } from './components/site-detail-payments/site-detail-payments.component';
import { SiteDetailReportsComponent } from './components/site-detail-reports/site-detail-reports.component';
import { SiteDocumentListComponent } from './components/site-document-list/site-document-list.component';
import { SiteStandItemComponent } from './components/site-stand-item/site-stand-item.component';
import { SiteVerificationVisitsComponent } from './components/site-verification-visits/site-verification-visits.component';
import { TablePaginationComponent } from './components/table-pagination/table-pagination.component';
import { ToastComponent } from './components/toast/toast.component';
import { VerifyAccountComponent } from './components/verify-account-page/verify-account.component';
import { AddUserDialogComponent } from './dialogs/add-user-dialog/add-user-dialog.component';
import { AdjustValueDialogComponent } from './dialogs/adjust-value-dialog/adjust-value-dialog.component';
import { ConfirmOwnerDialogComponent } from './dialogs/confirm-owner-dialog/confirm-owner-dialog.component';
import { DialogChangeActingAsUserComponent } from './dialogs/dialog-change-acting-as-user/dialog-change-acting-as-user.component';
import { ChangeEmailDialogComponent } from './dialogs/dialog-change-email/dialog-change-email.component';
import { DialogChangeOwnerComponent } from './dialogs/dialog-change-owner/dialog-change-owner.component';
import { DialogChangeSiteNameComponent } from './dialogs/dialog-change-site-name/dialog-change-site-name.component';
import { DialogComingSoonComponent } from './dialogs/dialog-coming-soon/dialog-coming-soon.component';
import { DialogConfirmPaymentComponent } from './dialogs/dialog-confirm-payment/dialog-confirm-payment.component';
import { DialogConfirmSubmitComponent } from './dialogs/dialog-confirm-submit/dialog-confirm-submit.component';
import { DialogContactInfoComponent } from './dialogs/dialog-contact-info/dialog-contact-info.component';
import { DialogCountyAvailabilityComponent } from './dialogs/dialog-county-availability/dialog-county-availability.component';
import { DialogDownForMaintenanceComponent } from './dialogs/dialog-down-for-maintenance/dialog-down-for-maintenance.component';
import { DialogEditParcelDataComponent } from './dialogs/dialog-edit-parcel-data/dialog-edit-parcel-data.component';
import { DialogEditPaymentPeriodComponent } from './dialogs/dialog-edit-payment-period/dialog-edit-payment-period.component';
import { DialogEligibilityQuestionComponent } from './dialogs/dialog-eligibility-question/dialog-eligibility-question.component';
import { DialogImportKmlComponent } from './dialogs/dialog-import-kml/dialog-import-kml.component';
import { DialogIneligibleParcelComponent } from './dialogs/dialog-ineligible-parcel/dialog-ineligible-parcel.component';
import { DialogManualIDComponent } from './dialogs/dialog-manual-id/dialog-manual-id.component';
import { DialogOutOfRegionComponent } from './dialogs/dialog-out-of-region/dialog-out-of-region.component';
import { DialogOverlappingPublicLandComponent } from './dialogs/dialog-overlapping-public/dialog-overlapping-public.component';
import { DialogOverlappingStandsComponent } from './dialogs/dialog-overlapping-stands/dialog-overlapping-stands.component';
import { DialogPremiumAcreageComponent } from './dialogs/dialog-premium-acreage/dialog-premium-acreage.component';
import { RecalculateSiteDialogComponent } from './dialogs/dialog-recalculate-site/dialog-recalculate-site.component';
import { DialogResubmitNoteComponent } from './dialogs/dialog-resubmit-note/dialog-resubmit-note.component';
import { DialogSiteApprovedComponent } from './dialogs/dialog-site-approved/dialog-site-approved.component';
import { SiteSizeDialogComponent } from './dialogs/dialog-site-size/dialog-site-size.component';
import { DialogSplitSiteComponent } from './dialogs/dialog-split-site/dialog-split-site.component';
import { DialogTerminateSiteComponent } from './dialogs/dialog-terminate-site/dialog-terminate-site.component';
import { DialogUploadDocComponent } from './dialogs/dialog-upload-doc/dialog-upload-doc.component';
import { DialogUseDesktopComponent } from './dialogs/dialog-use-desktop/dialog-use-desktop.component';
import { DialogYesNoComponent } from './dialogs/dialog-yes-no/dialog-yes-no.component';
import { DialogHowDidYouHearComponent } from './dialogs/how-did-you-hear-dialog/how-did-you-hear-dialog.component';
import { NavigationConfirmationDialogComponent } from './dialogs/navigation-confirmation-dialog/navigation-confirmation-dialog.component';
import { OtpDialogComponent } from './dialogs/otp-dialog/otp-dialog.component';
import { ClickDirective } from './directives/click.directive';
import { CurrencyMaskDirective } from './directives/currency-mask-directive';
import { DateMaskDirective } from './directives/date-mask.directive';
import { DragDropUploadDirective } from './directives/drag-drop-upload-directive.directive';
import { FormUnloadWarningDirective } from './directives/form-unload-warning.directive';
import { RegexFilterDirective } from './directives/regex-filter.directive';
import { RemoveHostDirective } from './directives/remove-host.directive';
import { SpinnerComponent } from './spinner/spinner.component';

import { ParcelItemComponent } from '../shared-map/components/map-control/parcel-item/parcel-item.component';

// Material Modules
const materialModules = [
  MatAutocompleteModule,
  MatBadgeModule,
  MatButtonModule,
  MatButtonToggleModule,
  MatCardModule,
  MatCheckboxModule,
  MatChipsModule,
  MatDatepickerModule,
  MatDialogModule,
  MatDividerModule,
  MatExpansionModule,
  MatTabGroup,
  MatTabLabel,
  MatTab,
  MatFormFieldModule,
  MatIconModule,
  MatInputModule,
  MatMenuModule,
  MatNativeDateModule,
  MatProgressSpinnerModule,
  MatRadioModule,
  MatSelectModule,
  MatSidenavModule,
  MatSliderModule,
  MatSlideToggleModule,
  MatSnackBarModule,
  MatStepperModule,
  MatTableModule,
  MatToolbarModule,
  MatTooltipModule,
];

const sharedComponents = [
  AppHeaderComponent,
  ClickDirective,
  ConfirmOwnerDialogComponent,
  CurrencyMaskDirective,
  CurrencyMaskDirective,
  DashboardBannerComponent,
  DateMaskDirective,
  DateMaskDirective,
  DisturbanceReportComponent,
  DragDropUploadDirective,
  EmailFormErrorComponent,
  EnvRibbonComponent,
  FCSortIconComponent,
  FaqComponent,
  FaqComponent,
  FormUnloadWarningDirective,
  NewSiteStepOneAndAHalfComponent,
  NewSiteStepOneComponent,
  ParcelItemComponent,
  ParcelItemComponent,
  PeriodicReportComponent,
  ProfilePageComponent,
  ProgressBarComponent,
  QuickLinksPanelComponent,
  RegexFilterDirective,
  RemoveHostDirective,
  SiteCardComponent,
  SiteDetailInformationComponent,
  SiteDetailOverviewComponent,
  SiteDetailNotesComponent,
  SiteDetailPaymentsComponent,
  SiteDetailReportsComponent,
  SiteDocumentListComponent,
  SiteStandItemComponent,
  SiteVerificationVisitsComponent,
  SpinnerComponent,
  SpinnerComponent,
  ToastComponent,
  TablePaginationComponent,
  UserActivityDashboardComponent,
  VerifyAccountComponent,
  VerifyAccountComponent,
];

const formComponents = [FCControlComponent, FCDatepickerComponent];

const sharedDialogComponents = [
  AddUserDialogComponent,
  AdjustValueDialogComponent,
  ChangeEmailDialogComponent,
  ConfirmOwnerDialogComponent,
  DialogChangeActingAsUserComponent,
  DialogChangeActingAsUserComponent,
  DialogChangeOwnerComponent,
  DialogChangeSiteNameComponent,
  DialogEditParcelDataComponent,
  DialogEditPaymentPeriodComponent,
  DialogChangeSiteStateComponent,
  DialogComingSoonComponent,
  DialogConfirmPaymentComponent,
  DialogConfirmSubmitComponent,
  DialogCountyAvailabilityComponent,
  DialogDownForMaintenanceComponent,
  DialogEligibilityQuestionComponent,
  DialogHowDidYouHearComponent,
  DialogImportKmlComponent,
  DialogImportKmlComponent,
  DialogManualIDComponent,
  DialogOutOfRegionComponent,
  DialogOverlappingPublicLandComponent,
  DialogOverlappingStandsComponent,
  DialogPremiumAcreageComponent,
  DialogResubmitNoteComponent,
  DialogSiteApprovedComponent,
  DialogTerminateSiteComponent,
  DialogUploadDocComponent,
  DialogUseDesktopComponent,
  DialogYesNoComponent,
  OtpDialogComponent,
  RecalculateSiteDialogComponent,
  SiteSizeDialogComponent,
  DialogSplitSiteComponent,
];

const sharedModules = [
  CKEditorModule,
  CommonModule,
  NgxChartsModule,
  FontAwesomeModule,
  FormsModule,
  OverlayModule,
  PortalModule,
  ReactiveFormsModule,
  RouterModule,
];

@NgModule({
  imports: [...materialModules, ...sharedModules],
  declarations: [
    ...formComponents,
    ...sharedComponents,
    ...sharedDialogComponents,
    DialogContactInfoComponent,
    DialogIneligibleParcelComponent,
    NavigationConfirmationDialogComponent,
  ],
  exports: [
    ...formComponents,
    ...materialModules,
    ...sharedComponents,
    ...sharedDialogComponents,
    ...sharedModules,
  ],
  providers: [
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: {
        appearance: 'outline',
        floatLabel: 'never',
        subscriptSizing: 'dynamic',
      },
    },
    {
      provide: MAT_SNACK_BAR_DEFAULT_OPTIONS,
      useValue: {
        duration: 4000,
      },
    },
    {
      multi: true,
      provide: NG_VALIDATORS,
      useExisting: FCControlComponent,
    },
  ],
})
export class SharedModule {
  constructor(library: FaIconLibrary, faConfig: FaConfig) {
    faConfig.fixedWidth = true;

    /* Add all font awesome icons here, and you won't need to import them in individual components */
    /* NOTE: Alphabetize items within their sections if you can, please! */

    /* Regular Icons */
    library.addIcons(faArrowLeft);
    library.addIcons(faBadgeDollar);
    library.addIcons(faCircleCheck);
    library.addIcons(faCircleInfo);
    library.addIcons(faCircleQuestion);
    library.addIcons(faDownload);
    library.addIcons(faFlag);
    library.addIcons(faLock);
    library.addIcons(faPaperclip);
    library.addIcons(faPencil);
    library.addIcons(faRoad);
    library.addIcons(faSignsPost);
    library.addIcons(faSquareDashed);
    library.addIcons(faSquareDashedCirclePlus);
    library.addIcons(faTrash);
    library.addIcons(faTrashUndo);
    library.addIcons(faTriangleExclamation);
    library.addIcons(faUpload);
    library.addIcons(faUserShield);
    library.addIcons(faWater);
    library.addIcons(faXmark);
    library.addIcons(farArrowUpRightFromSquare);
    library.addIcons(farEdit);
    library.addIcons(farMap);
    library.addIcons(farTree);
    library.addIcons(farTreeDeciduous);

    /* Solid Icons */
    library.addIcons(faArrowUpRightFromSquare);
    library.addIcons(faBars);
    library.addIcons(faBorderAll);
    library.addIcons(faCaretDown);
    library.addIcons(faCaretUp);
    library.addIcons(faCaretUp);
    library.addIcons(faChartSimple);
    library.addIcons(faCheck);
    library.addIcons(faCheckCircle);
    library.addIcons(faChevronLeft);
    library.addIcons(faChevronRight);
    library.addIcons(faChevronsLeft);
    library.addIcons(faChevronsRight);
    library.addIcons(faCircle);
    library.addIcons(faCircleExclamation);
    library.addIcons(faCircleMinus);
    library.addIcons(faCirclePlus);
    library.addIcons(faCircleUser);
    library.addIcons(faCircleXmark);
    library.addIcons(faEdit);
    library.addIcons(faExpandWide);
    library.addIcons(faEye);
    library.addIcons(faEyeSlash);
    library.addIcons(faMinus);
    library.addIcons(faMoneyCheckDollar);
    library.addIcons(faOctagon);
    library.addIcons(faPaperPlane);
    library.addIcons(faPenToSquare);
    library.addIcons(faPlus);
    library.addIcons(faQuestion);
    library.addIcons(faQuestionCircle);
    library.addIcons(faRoad);
    library.addIcons(faRotateLeft);
    library.addIcons(faShieldHalved);
    library.addIcons(faSolidMap);
    library.addIcons(faStar);
    library.addIcons(faTableColumns);
    library.addIcons(faGauge);
    library.addIcons(faTree);
    library.addIcons(faTreeDeciduous);
    library.addIcons(faTriangle);
    library.addIcons(faWater);
    library.addIcons(fasCircleCheck);

    /* Light Icons */
    library.addIcons(faArrowPointer);
    library.addIcons(faDrawPolygon);
    library.addIcons(faDrawSquare);
    library.addIcons(faFile);
    library.addIcons(faFloppyDisk);
    library.addIcons(faLayerPlus);
    library.addIcons(faMap);
    library.addIcons(faHandPointer);
    library.addIcons(faMap);
    library.addIcons(faPenNib);
  }
}
