<div mat-dialog-title>
  <div>User Not Verified By Trulioo</div>
  <div class="close-icon-container" (click)="onButtonClick('close')">
    <fa-icon [icon]="['far', 'xmark']" class="gray"></fa-icon>
  </div>
</div>
<mat-divider></mat-divider>
<div mat-dialog-content>
  <div class="line">
    You can clear Trulioo to allow for resubmission and the user will be
    prompted to try again.
  </div>
  <div *ngIf="submitTimes > 1" class="line">
    This user has previously submitted Trulioo {{ submitTimes }} times.
  </div>
  <div class="line">Or, you can manually verify or deny this users ID.</div>
</div>
<div mat-dialog-actions>
  <div class="button-container">
    <div class="verify-deny-buttons">
      <button
        color="warn"
        mat-stroked-button
        cdkFocusInitial
        data-cy="button-no"
        (click)="onButtonClick('deny')">
        Deny
      </button>
      <button
        color="primary"
        mat-raised-button
        data-cy="button-yes"
        (click)="onButtonClick('verify')">
        Verify
      </button>
    </div>
    <button
      color="primary"
      mat-raised-button
      data-cy="button-clear"
      (click)="onButtonClick('clear Trulioo for')">
      Clear Trulioo
    </button>
  </div>
</div>
