import { ActionReducerMap, MetaReducer } from '@ngrx/store';

import * as fromAdmin from './admin/admin.reducer';
import * as fromAppConfig from './app-config/app-config.reducer';
import * as fromAuth from './auth/auth.reducer';
import * as fromBuyer from './buyer/buyer.reducer';
import * as fromCohort from './cohort/cohort.reducer';
import * as fromDocument from './document/document.reducer';
import * as fromMapControl from './map-control/map-control.reducer';
import * as fromNotification from './notification/notification.reducer';
import * as fromParcel from './parcel/parcel.reducer';
import * as fromReport from './report/report.reducer';
import * as fromSite from './site/site.reducer';
import * as fromSiteNote from './site-note/site-note.reducer';
import { storageMetaReducer } from './storage.metareducer';
import * as fromUser from './user/user.reducer';

export interface AppState {
  [fromParcel.parcelFeatureKey]: fromParcel.ParcelState;
  [fromMapControl.mapControlFeatureKey]: fromMapControl.MapControlState;
  [fromAuth.authFeatureKey]: fromAuth.AuthState;
  [fromAdmin.adminFeatureKey]: fromAdmin.AdminState;
  [fromUser.userFeatureKey]: fromUser.UserState;
  [fromSite.siteFeatureKey]: fromSite.SiteState;
  [fromSiteNote.siteNoteFeatureKey]: fromSiteNote.SiteNoteState;
  [fromReport.reportFeatureKey]: fromReport.ReportState;
  [fromCohort.cohortFeatureKey]: fromCohort.CohortState;
  [fromDocument.documentFeatureKey]: fromDocument.DocumentState;
  [fromNotification.notificationFeatureKey]: fromNotification.NotificationState;
  [fromAppConfig.appConfigFeatureKey]: fromAppConfig.AppConfigState;
  [fromBuyer.buyerFeatureKey]: fromBuyer.BuyerState;
}

export const reducers: ActionReducerMap<AppState> = {
  parcel: fromParcel.reducer,
  mapControl: fromMapControl.reducer,
  auth: fromAuth.reducer,
  admin: fromAdmin.reducer,
  user: fromUser.reducer,
  site: fromSite.reducer,
  siteNote: fromSiteNote.reducer,
  report: fromReport.reducer,
  cohort: fromCohort.reducer,
  document: fromDocument.reducer,
  notification: fromNotification.reducer,
  appConfig: fromAppConfig.reducer,
  buyer: fromBuyer.reducer,
};

export const metaReducers: MetaReducer<AppState>[] = [storageMetaReducer];
