import {
  Component,
  DestroyRef,
  ElementRef,
  inject,
  OnInit,
  ViewChild,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatDialog } from '@angular/material/dialog';
import { MatDrawer } from '@angular/material/sidenav';
import { Store } from '@ngrx/store';

import { DialogContactInfoComponent } from 'src/app/shared/dialogs/dialog-contact-info/dialog-contact-info.component';
import { logout } from 'src/app/store/auth/auth.actions';
import { selectCurrentUser } from 'src/app/store/auth/auth.selectors';

import { BuyerService } from '../../../core/api/buyer.service';
import * as fromApp from '../../../store';
import {
  selectAppConfigState,
  selectIsMobileView,
} from '../../../store/app-config/app-config.selectors';

@Component({
  selector: 'fc-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit {
  @ViewChild('introducingHeading') divView: ElementRef;
  @ViewChild('sideNav') sideNav?: MatDrawer;
  destroyRef = inject(DestroyRef);
  isMobile = false;
  isTestDivScrolledIntoView = true;

  contactEmail: string = '';
  contactPhone: string = '';

  contactAddressLine1: string = '';
  contactAddressLine2: string = '';
  contactCity: string = '';
  contactState: string = '';
  contactZip: string = '';

  isLoggedIn: boolean = false;
  inviteOnly: boolean = false;

  getOpenAssetURL = BuyerService.getOpenAssetURL;

  constructor(
    readonly store: Store<fromApp.AppState>,
    public dialog: MatDialog
  ) {}

  scrollHandler() {
    this.isTestDivScrolledIntoView =
      this.divView.nativeElement.getBoundingClientRect().top >= 0;
  }

  ngOnInit() {
    this.store
      .select(selectAppConfigState)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(appConfig => {
        this.inviteOnly =
          typeof appConfig.config['invite_only'] !== 'undefined'
            ? appConfig.config['invite_only'].toLowerCase() === 'true'
            : false;
        this.contactEmail = appConfig.config['fc_contact_email'];
        this.contactPhone = appConfig.config['fc_contact_phone'];

        this.contactAddressLine1 =
          appConfig.config['fc_contact_address_line_1'];
        this.contactAddressLine2 =
          appConfig.config['fc_contact_address_line_2'];
        this.contactCity = appConfig.config['fc_contact_city'];
        this.contactState = appConfig.config['fc_contact_state'];
        this.contactZip = appConfig.config['fc_contact_zip'];
      });

    this.store
      .select(selectIsMobileView)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(mobileViewMode => (this.isMobile = mobileViewMode));

    this.store
      .select(selectCurrentUser)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(currentUser => {
        this.isLoggedIn = currentUser !== null;
      });
  }

  onClickPartnerLogo(partner: string): void {
    switch (partner) {
      case 'LandYield':
        this.dialog.open(DialogContactInfoComponent, {
          panelClass: 'fc-yes-no-dialog',
          data: {
            programName: 'LandYield',
            aboutText:
              'Through LandYield, you can earn revenue from your forest without harvesting trees.  Participating landowners defer commercial harvests on enrolled sites for the first 20-year period of a carbon offset site.  During the second 20-year period, landowners can then elect to either implement sustainable harvesting (i.e. cutting an amount equal to growth above stock levels at Year 20) or may be able to commit to another 20-year harvest deferral and generate additional offset revenue. While you are enrolled in LandYield, you’ll continue to generate income from other non-timber sources - such as hunt leases and collection of non-timber forest products.',
            website: 'https://www.landyield.com',
          },
        });
        break;
    }
  }

  onLogOut(): void {
    this.store.dispatch(logout());
  }
}
